import React, { useState } from 'react';
import IntegrationCard from '../IntegrationCard/IntegrationCard';
import GoogleSheetIntegrationPage from '../IntegrationPages/GoogleSheetIntegration/GoogleSheetIntegrationPage';
// import GoogleSheetIntegration from '../IntegrationPages/GoogleSheetIntegration/GoogleSheetIntegrationPage';
import './crmintegration.scss';
import { useTranslation } from 'react-i18next';
/**
 * @module CRMIntegration
 */

/**
 * @param {crmIntegrationData} - Data to be rendered such as title, details, color etc of the card
 * @param {setMainActiveSection} - Function to change current active section
 * @returns CRM Integration component which consists of Title and Integration Cards
 */

const CRMIntegration = ({ crmIntegrationData, setMainActiveSection }) => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(false);
  console.log(activeSection);

  const onClickHandler = (section) => {


    setMainActiveSection(section);
    console.log("button clicked")
  }

  return (
    <div className="leads-integration-container">

      {!activeSection ? (
        <>
          <h5 className="header">
            {t('CRM_Integrations.message')}
          </h5>
          <div className="integration-cards-container">
            {crmIntegrationData.map((item, indexOfItem) => (
              <IntegrationCard
                onClickHandler={onClickHandler}
                title={item.title}
                details={item.details}
                bgColor={item.bgColor}
                logo={item.logo}
                type={item.type}
                section={item.activeSection}
                isConnected={item.isConnected}
                accessLevel={item.accessLevel}
                setMainActiveSection={setMainActiveSection}
              />
            ))}
          </div></>
      ) : (
        <GoogleSheetIntegrationPage setActiveSectionLeadPage={setActiveSection} />
      )}
    </div>
  )
}

export default CRMIntegration