import React from 'react'
import { useState } from 'react';
import { Input, InputGroup } from "rsuite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Container,
  Row,
} from "reactstrap";
import { useEffect } from 'react';

/**
 * Style for our popup modal
 */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "18rem",
  // height: "50vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

const styles = {
  marginBottom: 10,
};


  /**
   * 
   * @returns Google Sheet Modal where we select the spreadsheet and subsheet 
   */

  const SalesforceModal = ({open, handleClose}) => {
    
  const [openPopup, setOpenPopup] = React.useState(open);

  useEffect(()=> {
    setOpenPopup(open)
  }, [open])

    const [domainName, setDomainName] = useState("");
    const onChangeDomainName=(e)=>setDomainName(e.target.value)
    const handleOpenPopup = () => {
      setOpenPopup(true);
    };
    const handleClosePopup = () => {
      setOpenPopup(false);
    };

    

    const CustomInputGroup = ({ onChange, placeholder, ...props }) => (
      <InputGroup {...props} onChange={onChange} style={styles}>
        <Input placeholder={placeholder} />
        {/* <InputGroup.Addon>
          <SearchIcon />
        </InputGroup.Addon> */}
      </InputGroup>
    );

    const onClickContinueBtn =()=> {
      
      window.history.pushState({}, '', `/?domain=${domainName}`);

    }
  

    return (
      <Modal
        // hideBackdrop
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="google-sheet-modal"
      >
        <Box className="modal-container" sx={{ ...style }}>
          {/* <Row className="top">
            <input className="search-files-input" placeholder="Search..." />
          </Row> */}

          <Container className="files-container d-flex-col">
          <p className="mt-1 select-txt mb-1">Bitrix Domain Name</p>
            <CustomInputGroup
              value={domainName}
              onChange={onChangeDomainName}
              size="sm"
              placeholder="Domain.com"
            />
          </Container>

          <Row className="btns-container d-flex">
            <button className="cancel-btn" onClick={handleClosePopup}>Cancel</button>
            <button className="sync-btn" onClick={onClickContinueBtn}>Continue</button>
          </Row>

        </Box>
      </Modal>
      // </Backdrop>
    );
  };

export default SalesforceModal