/*global chrome*/
import React, { useEffect, useState } from "react";

import "./salesforceintegrationpage.scss";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SalesforceNotConnected from "./SalesforceNotConnected/SalesforceNotConnected";
import SalesforceModal from "./SalesforceModal/SalesforceModal";
import showToast from "../../../../components/Toasts/ShowToast";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import {
  checkForCrmiKeyInAuth,
  getLoggedInUserWorkspaceId,
} from "../../../../util/helper";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";
import {
  createAuthSalesforce,
  getAuthSalesforce,
} from "../../../../redux/integrations/integrations.action";
import { Loader } from "rsuite";
import EazybeLoader from "../../../../components/loader";

//Eazybe Akshay
const CLIENT_ID =
  "3MVG9Kr5_mB04D17phMhLZXqXQ8jQnGDJCGPfV3M5yXC_LoGr1QkZc9sKJ1CSnmvaL5fKkolF5eYh3CU4MrGc";
const CLIENT_SECRET =
  "FD42A9FD96762D1CA6A413A1FDD5B53BF8A7B5BFBB948EDF27C5D8F560B5D307";
//Eazybe2
// const CLIENT_ID = "3MVG9fe4g9fhX0E5VgCaYVOWi9Y7CyxMkGp0N5TtJ0Ns4vKK8pysnIXj79eeQ1JdqyKxKoFqu7i835SAZOpVz";
// const CLIENT_SECRET = "7E9326BE027BCFCA642900DD89E3A62ACEFA697C891E17EA680ECF989BFC547D";

// const CLIENT_ID = "3MVG9fe4g9fhX0E5VgCaYVOWi9QVVWquuLM1EZ.Ph2yNwn9M0iQPpq1KM4fDRfeO4bzckg_vhVMD2ODvY3r3q";
// const CLIENT_SECRET = "834B161236ED19A133C964AEDE9650DC040CDA18B4A63868BE11F52832DAF535";
// const CLIENT_ID = "3MVG9fe4g9fhX0E5VgCaYVOWi9aXzMj1o4gXUkMDy8vvhTCGus2D3dskFoTovVOreq3PW0vJf6j2QyeKdZhiY";
// const CLIENT_SECRET = "D267BA03A06F5E1F69902366EABA383894824F226D85F8BCEE5521CB113FB2E9";
const REDIRECT_URI =
  "https://workspace.eazybe.com/integrations?section=salesforce";
// const REDIRECT_URI = "https://localhost:4000/integrations?section=salesforce";
// const REDIRECT_URI = "https://extraordinary-souffle-3ca771.netlify.app/integrations?section=salesforce";
// const REDIRECT_URI = "https://localhost:4000/integrations?section=salesforce";
const SALESFORCE_DOMAIN = "login.salesforce";
const RESPONSE_TYPE = "code";
const SalesforceIntegrationPage = ({
  setMainSectionIntegrations,
  connectIntegration,
  extensionIdToSendMessageTo,
  saveDontShowUpgradeStatus,
  userRoleId,
}) => {
  let SALESFORCE_SECTION_IDS = {
    ALREADY_CONNECTED: "ALREADY_CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    GO_BACK_TO_EXTENSION: "GO_BACK_TO_EXTENSION",
    HUBSPOT_PAGE1: "HUBSPOT_PAGE1",
  };

  const [activeSectionHubspot, setActiveSectionHubspot] = useState(
    SALESFORCE_SECTION_IDS.NOT_CONNECTED
  );
  const [salesforceConnected, setSalesforceConnected] = useState(false);
  const [salesforceModalActive, setBitrixModalActive] = useState(false);
  const [salesforceSectionLoader, setSaleforceSectionLoader] = useState(false);

  const enableBitrixModal = () => setBitrixModalActive(true);
  const disableSalesforceModal = () => setBitrixModalActive(false);
  // const [activeSectionHubspot, setActiveSectionHubspot] = useState(SALESFORCE_SECTION_IDS.ALREADY_CONNECTED);
  // const SALESFORCE_DOMAIN = "eazybe-dev-ed.develop.my.salesforce";

  // const RESPONSE_TYPE = "token"
  const [showLoader, setShowLoader] = useState(true);
  const clientRedirectURI = `https://${SALESFORCE_DOMAIN}.com/services/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in hubspot section", queryParams);
    let tempCode = queryParams?.get("code");
    console.log("tempCode : ", tempCode);
    if (tempCode) {
      getBearerToken(tempCode);
    } else {
      checkSalesforceAlreadyConnected();
    }

    /// Redirect to redirect_uri
    if (connectIntegration) {
      window.open(clientRedirectURI);
    }
  }, []);

  const onClickConnectBitrix = () => {
    window.open(clientRedirectURI);
  };

  const getBearerToken = async (authCode) => {
    console.log("getBearerToken called : ", authCode);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const url = "https://eazybe.com/api/v1/whatzapp/salesforce/auth";

    const data = {
      code: authCode,
      redirect_uri: REDIRECT_URI,
      workspace_id: getLoggedInUserWorkspaceId(),
    };

    const resGetBearerToken = await createAuthSalesforce(data);
    const message =
      resGetBearerToken?.message ?? "Failed to authenticate, please try again";
    if (resGetBearerToken?.status) {
      window.gtag("event", "Salesforceintegrated");
      window.gtagAW("event", "Salesforceintegrated");
      showToast("Salesforce Integrated Succesfuuly");
      setSalesforceConnected(true);
      sendMessageToChromeExtension();
      checkForCrmiKeyInAuth();
    } else {
      showToast(message);

    }
  };

  const checkSalesforceAlreadyConnected = async () => {
    const response = await getAuthSalesforce();

    if (response?.status) {
      setSalesforceConnected(true);
    } else {
      setSalesforceConnected(false);
    }
  };

  const sendMessageToChromeExtension = (
    status,
    time = 10,
    id = "clgficggccelgifppbcaepjdkklfcefd",
    key
  ) => {
    console.log("sendMessageToChromeExtension : ", { EXTENSION_ID_PRODUCTION });
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
          EXTENSION_ID_PRODUCTION,
          // EXTENSION_ID_TEST,
          { key: key ?? "SALESFORCE_CONNECTED" },
          (response) => {
            console.log("sending msg to extension : ", {
              EXTENSION_ID_PRODUCTION,
              response,
            });
            if (!response?.success) {
            }
          }
        );
        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo,
          // EXTENSION_ID_TEST,
          { key: key ?? "SALESFORCE_CONNECTED" },
          (response) => {
            console.log("sending msg to extension : ", {
              EXTENSION_ID_PRODUCTION,
              response,
            });
            if (!response?.success) {
            }
          }
        );
      }
    }, time);
  };

  const checkIfSalesforceConnected = () => {};

  return (
    <div>
    
     
          <ArrowBackIcon
            onClick={setMainSectionIntegrations}
            className="mt-1 cursor-pointer"
          />
          <SalesforceNotConnected
            salesforceConnected={salesforceConnected}
            setSalesforceConnected={setSalesforceConnected}
            onClickConnect={onClickConnectBitrix}
            checkIfSalesforceConnected={checkIfSalesforceConnected}
            sendMessageToChromeExtension={sendMessageToChromeExtension}
            userRoleId={userRoleId}
            setSaleforceSectionLoader={setSaleforceSectionLoader}
            salesforceSectionLoader={salesforceSectionLoader}
          />

          <SalesforceModal
            open={salesforceModalActive}
            handleClose={disableSalesforceModal}
          />
     
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesforceIntegrationPage);
