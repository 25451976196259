import React, { useEffect } from "react";
import "./googlesheetnotconnected.scss";
import googleSheetIcon from "../../../../../assets/icons/googlesheet.png";
import popupIconSvg from "../../../../../assets/icons/popup-window.svg";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import ConfirmationModal from "../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal";
import showToast from "../../../../../components/Toasts/ShowToast";
import { useTranslation } from "react-i18next";

const GoogleSheetNotConnected = ({
  onClickConnect,
  googleSheetConnected,
  setGoogleSheetConnected,
  sendMessageToChromeExtension,
  checkIfGoogleSheetIntegrated,
}) => {
  const { t } = useTranslation()
  const [unlinkModalActive, setUnlinkModalActive] = useState(false);
  const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
  const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);
  const [editLabel, setEditLabel] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [sheetId, setSheetId] = useState("");

  const onChangeLabel = (e) => {
    setLabelName(e.target.value);
  };

  useEffect(() => {
    getGoogleSheetLabelName();
    getGoogleSheetDetails();
  }, [googleSheetConnected]);

  let featuresArr = [
    t("Create_New_Contacts.message"),
    t("Update_Contact_Information.message"),
    t("View_Contact_Information_on_Google_Spreadsheet.message"),
    t("Modify_Contact_Information_from_Google_Spreadsheet.message"),
    t("Download_All_Contacts_Information_at_once.message"),
  ];

  useEffect(() => {
    getGoogleSheetLabelName();
  }, []);

  const goBackToWhatsapp = () => {
    if (sendMessageToChromeExtension) {
      sendMessageToChromeExtension();
    } else {
      window.open("https://web.whatsapp.com/");
    }
  };

  const onClickUnlinkGoogleSheet = () => {
    setUnlinkModalActiveTrue();
  };

  const getGoogleSheetDetails = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/googlesheet?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("response : ", response);
        if (response.result) {
          // sendMessageToChromeExtension(true, 500);
          setSheetId(response?.data?.sheet_id);
        } else {
          // sendMessageToChromeExtension(false, 500);
          setSheetId("");
        }
      });
  };

  const unlinkGoogleSheet = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
    fetch(
      `https://eazybe.com/api/v1/whatzapp/deletegooglesheet?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((response) => {
        //   console.log("response : ", response);
        if (response?.result) {
          // sendMessageToChromeExtension(true, 500);
          showToast("Unlinked Google Sheet successfully");
          setGoogleSheetConnected(false);
          setUnlinkModalActiveFalse();
          checkIfGoogleSheetIntegrated();
          getGoogleSheetLabelName();
        } else {
        }
      });
  };

  const getGoogleSheetLabelName = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/getsheetfunnel?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("getGoogleSheetLabelName response : ", response);
        if (response?.result) {
          setLabelName(response.data.funnel_name);
        } else {
        }
      });
  };

  const onClickViewOnGoogleSheet = async (e) => {
    try {
      e.preventDefault();
      if (sheetId) {
        // console.log("onClickViewOnHubspot url", url)
        let url = `https://docs.google.com/spreadsheets/d/${sheetId}/`;
        window.open(url, "_blank");
      }
    } catch (err) {
      console.log("Error while viewing contact on hubspot", err);
    }
  };

  const onClickChangeLabel = () => {
    setEditLabel(true);
  };

  const onClickSaveLabel = () => {
    if (labelName) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({
          workspace_id: localStorage.getItem("workspaceId"),
          updated_name: labelName,
        }),
      };
      fetch(
        `https://eazybe.com/api/v1/whatzapp/updatesheetfunnel`,
        requestOptions
      )
        .then((res) => res.json())
        .then((response) => {
          //   console.log("response : ", response);
          if (response?.result) {
            // sendMessageToChromeExtension(true, 500);
            showToast("Label name updated successfully");
            setEditLabel(false);
          } else {
            showToast("Something went wrong");
            setEditLabel(false);
          }
        });
    } else {
      showToast("Label name can not be empty");
    }
  };

  return (
    <>
      <div className="google-sheet-not-connected-con d-flex d-flex-col">
        <div className="header-con d-flex">
          <img src={googleSheetIcon} className="hubspot-icon-big" />
          <div className="connection-status d-flex d-flex-col">
            <div className="name-con d-flex">
              <h3>Google Sheet</h3>

              {googleSheetConnected ? (
                <>
                  <div className="d-flex align-items-center">
                    <DoneIcon htmlColor="green" fontSize="24px" />
                    <p className="ml-0-5">
                      {t("Connected.message")}
                    </p>
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center">
                  <ClearIcon htmlColor="red" fontSize="24px" />
                  <p className="ml-0-5">
                    {t("Not_Connected.message")}
                  </p>
                </div>
              )}
            </div>
            <a href="https://eazybe.com/google" className="privacy" target="_blank">Google Privacy & Policy</a>
            <p>Create Contacts, Create User Properties</p>

            {googleSheetConnected && (
              <div className="label-name-con d-flex">
                <h6>Label Name :</h6>
                {editLabel ? (
                  <>
                    <input onChange={onChangeLabel} value={labelName} />
                    <button className="save-btn" onClick={onClickSaveLabel}>
                      {t("save.message")}
                    </button>
                  </>
                ) : (
                  <>
                    <p>{labelName}</p>
                    <button onClick={onClickChangeLabel}>Change</button>
                  </>
                )}
              </div>
            )}

            {googleSheetConnected && (
              <button
                className="view-sheet-btn"
                onClick={onClickViewOnGoogleSheet}
              >
                {t("View_Sheet.message")}
              </button>
            )}
          </div>

          {googleSheetConnected ? (
            <div>
              <button
                onClick={goBackToWhatsapp}
                className="connect-hubspot-btn"
              >
                Go back to WhatsApp
              </button>
              <button
                onClick={onClickUnlinkGoogleSheet}
                className="unlink-hubspot-btn"
              >
                {t("Unlink.message")}
              </button>
            </div>
          ) : (
            <div className="d-flex-col">
              <button onClick={onClickConnect} className="connect-hubspot-btn">
                Connect to Google Sheet
              </button>
              <div className="d-flex popup-text-con">
                <img src={popupIconSvg} />
                <p>Please allow popup window</p>
              </div>
            </div>
          )}

          {/* <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Hubspot</button> */}
        </div>
        <div className="info-con d-flex-col">
          <h5>
            {t("Features.message")}
          </h5>
          <div className="features">
            {featuresArr.map((feature) => (
              <div className="feature-item d-flex">
                <img className="feature-img" src={googleSheetIcon} />
                <p className="feature-text">{feature}</p>
              </div>
            ))}
          </div>
          { }
        </div>
      </div>
      <ConfirmationModal
        open={unlinkModalActive}
        handleClose={setUnlinkModalActiveFalse}
        onClickOk={unlinkGoogleSheet}
        onClickCancel={setUnlinkModalActiveFalse}
        title="Unlink Google Sheet"
        message={t("Are_you_sure_you_want_to_unlink.message") + " Google Sheet"}
        okText={t("Unlink.message")}
      />
    </>
  );
};

export default GoogleSheetNotConnected;
