import React, { useEffect, useState } from "react";
import "./leadsquarednotconnected.scss";
import leadsquaredIconImg from "../../../../../assets/icons/leadsquared-icon.png";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModal from "../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal";
import showToast from "../../../../../components/Toasts/ShowToast";
import leadsquaredStep1 from "../../../../../assets/images/leadsquared-step-1.png";
import leadsquaredStep2 from "../../../../../assets/images/leadsquared-step-2.png";
import leadsquaredStep3 from "../../../../../assets/images/leadsquared-step-3.png";
import leadsquaredStep4 from "../../../../../assets/images/leadsquared-step-4.png";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { deleteAuthLeadsquared } from "../../../../../redux/integrations/integrations.action";

/**
 * LeadsquaredNotConnected component - Handles the UI and logic for connecting and disconnecting Leadsquared.
 * @param {Object} props - Component props
 * @param {function} props.onClickConnect - Function to handle connection click
 * @param {boolean} props.leadsquaredConnected - Indicates if Leadsquared is connected
 * @param {function} props.setLeadsquaredConnected - Function to set Leadsquared connection state
 * @param {function} props.sendMessageToChromeExtension - Function to send messages to Chrome extension
 */
const LeadsquaredNotConnected = ({
  onClickConnect,
  leadsquaredConnected,
  setLeadsquaredConnected,
  sendMessageToChromeExtension,
}) => {
  const [unlinkModalActive, setUnlinkModalActive] = useState(false);
  const [setupGuideVisible, setSetupGuideVisible] = useState(true);
  const { t } = useTranslation();

  // Toggle functions for the unlink confirmation modal
  const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
  const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);

  // Toggle function for the setup guide visibility
  const toggleSetupGuideVisibility = () =>
    setSetupGuideVisible(!setupGuideVisible);

  // Setup guide steps
  const setUpGuideSteps = [
    {
      SrNo: "Step 1: ",
      title: "Click on setting option in Profile",
      isImg: true,
      img: leadsquaredStep1,
    },
    {
      SrNo: "Step 2: ",
      title: "Click on API and Webhook",
      isImg: true,
      img: leadsquaredStep2,
    },
    {
      SrNo: "Step 3: ",
      title: "You can copy API Host",
      isImg: true,
      img: leadsquaredStep3,
    },
    {
      SrNo: "Step 4: ",
      title: "Click on Secret Key and you will find Access id and secret key",
      isImg: true,
      img: leadsquaredStep4,
    },
  ];

  // Log connection status change
  useEffect(() => {
    console.log("leadsquaredConnected changed", leadsquaredConnected);
  }, [leadsquaredConnected]);

  // List of features to display
  let featuresArr = [
    t("Create_New_Leads.message"),
    t("Link_Existing_Leads.message"),
    t("Update_Lead_Information.message"),
    t("Create_Tasks.message"),
    t("Create_Notes.message"),
  ];

  // Navigate back to WhatsApp
  const goBackToWhatsapp = () => {
    if (sendMessageToChromeExtension) {
      sendMessageToChromeExtension();
    } else {
      window.open("https://web.whatsapp.com/");
    }
  };

  // Show unlink confirmation modal
  const onClickUnlinkLeadsquared = () => {
    setUnlinkModalActiveTrue();
  };

  // Unlink Leadsquared
  const unlinkLeadsquared = async () => {

    const deleteAuthRes = await deleteAuthLeadsquared();
    if(deleteAuthRes){
      showToast("Unlinked Leadsquared successfully");
      setLeadsquaredConnected(false);
      setUnlinkModalActiveFalse();
    }
    else {
      showToast("Something went wrong");
      setUnlinkModalActiveFalse();
    }
  };

  return (
    <>
      <div className="leadsquared-not-connected-con d-flex d-flex-col">
        <div className="header-con d-flex">
          <img src={leadsquaredIconImg} className="hubspot-icon-big" />
          <div className="connection-status d-flex d-flex-col">
            <div className="name-con d-flex">
              <h3>Leadsquared</h3>
              {leadsquaredConnected ? (
                <div className="d-flex align-items-center">
                  <DoneIcon htmlColor="green" fontSize="24px" />
                  <p className="ml-0-5">{t("Connected.message")}</p>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <ClearIcon htmlColor="red" fontSize="24px" />
                  <p className="ml-0-5">{t("Not_Connected.message")}</p>
                </div>
              )}
            </div>
            <p>{t("Link_Leads_Create_Leads_Create_Tasks.message")}</p>
          </div>

          {leadsquaredConnected ? (
            <div>
              <button
                onClick={goBackToWhatsapp}
                className="connect-hubspot-btn"
              >
                Go back to WhatsApp
              </button>
              <button
                onClick={onClickUnlinkLeadsquared}
                className="unlink-hubspot-btn"
              >
                {t("Unlink.message")}
              </button>
            </div>
          ) : (
            <button onClick={onClickConnect} className="connect-hubspot-btn">
              Connect to Leadsquared
            </button>
          )}
        </div>
        <hr />
        <div className="info-con">
          <div className="leadsquared-features">
            <h5>{t("Features.message")}</h5>
            <div className="features">
              {featuresArr.map((feature, index) => (
                <div key={index} className="feature-item d-flex">
                  <img className="feature-img" src={leadsquaredIconImg} />
                  <p className="feature-text">{feature}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="leadsquared-setup-guide-container">
            <button
              onClick={toggleSetupGuideVisibility}
              className="setup-guide-toggle-btn d-flex align-items-center mb-1"
            >
                Setup Guide
              {!setupGuideVisible
                ? <KeyboardArrowDown/>
                : <KeyboardArrowUp/>}
            </button>
            {setupGuideVisible && (
              <div className="leadsquared-setup-guide default-scroll-bar">
                
                {setUpGuideSteps.map((step, index) => (
                  <div key={index}>
                    <p className="leadsquared-setup-step mb-1">
                      <span className="leadsquared-setup-step-title">
                        {step.SrNo}
                      </span>
                      {step.title}
                    </p>
                    {step.isImg && (
                      <img
                        className="leadsquared-guide-img"
                        src={step.img}
                        alt="guide"
                        
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={unlinkModalActive}
        handleClose={setUnlinkModalActiveFalse}
        onClickOk={unlinkLeadsquared}
        onClickCancel={setUnlinkModalActiveFalse}
        title="Unlink Leadsquared"
        message={`${t("Are_you_sure_you_want_to_unlink.message")} Leadsquared`}
        okText={t("Unlink.message")}
      />
    </>
  );
};

export default LeadsquaredNotConnected;
