import React, { useState } from 'react';
import './myaccountdropdown.scss';
import { useHistory } from 'react-router-dom';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from 'react-redux';
import { selectUserExistenceData } from '../../redux/auth/auth.selector';
import { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';

import UserImgSvg from '../../assets/icons/no-user.svg'
import eazybeLogoSvg from '../../assets/icons/eazybe-logo-white.svg'
import feedbackIconSvg from '../../assets/icons/feedback.svg'
import contactUsIconSvg from '../../assets/icons/contact-us.svg'
import logoutIconSvg from '../../assets/icons/logout.svg'
import AccountItem from './AccountItem/AccountItem';
import { checkUserExistence, logoutUser } from '../../redux/auth/auth.action';
import { selectUserOrgList } from '../../redux/organization/organization.selector';
import { getUsersAllOrganization, setUserActiveOrganization } from '../../redux/organization/organization.action';
import { Link } from 'react-router-dom';
import { onClickContactUsHandler } from '../../util/helper';
import { useTranslation } from 'react-i18next';


const MyAccountDropdown = ({ fetchedUserExistenceData, logoutUser, userOrgList, setActiveOrganization, getAllOrganizationsList, fetchUserExistenceData, setIsOpen, className }) => {
  const { t } = useTranslation()
  const [menu, setMenu] = useState(false);

  const [currentUserPic, setCurrentUserPic] = useState(UserImgSvg);

  const history = useHistory();


  useEffect(() => {
    console.log("MyAccountDropdown", {userOrgList, fetchedUserExistenceData})
    if (!userOrgList) {
      getAllOrganizationsList();
    }
  }, [])


  useEffect(() => {
    if (fetchedUserExistenceData?.data) {
      if (fetchedUserExistenceData.data.profile_pic) {
        // Check if the profile_pic URL is valid
        const image = new Image();
        image.src = fetchedUserExistenceData.data.profile_pic;

        image.onload = () => {
          setCurrentUserPic(fetchedUserExistenceData.data.profile_pic);
        };

        image.onerror = () => {
          console.error('Invalid image URL:', fetchedUserExistenceData.data.profile_pic);
          // Set a default image or handle the error as needed
          // setCurrentUserPic(UserImgSvg);
        };
      }
    }
  }, [fetchedUserExistenceData]);

  const handleLogOut = () => {
    try {
      localStorage.removeItem("currentOrgCode")
      localStorage.removeItem("currentOrgId")
      localStorage.removeItem("userMobile")
      localStorage.removeItem("currentOrgName")
      localStorage.removeItem("workspaceId")
      logoutUser()
    }
    catch (err) {
      console.log("Error while logging out", err)
    }
  }

  const setActiveOrg = (e) => {
    setActiveOrganization(e.target.id)
    setIsOpen(true)
    setTimeout(() => {
      fetchUserExistenceData(fetchedUserExistenceData.data.email)
      setIsOpen(false)
      history.push("/organization")
    }, 3000)
  }

  const onClickContactUs = async () => {
    onClickContactUsHandler({ text: "Hi, I am using Eazybe and I had some queries." })
  }

  return (
    <>
      <Dropdown
        setActiveFromChild
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className={`${className ?? ""} d-inline-block my-account-dropdown`}
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img className='main-user-img' src={currentUserPic ?? UserImgSvg} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end elevated-card">

          <DropdownItem className='no-bg-on-hover'>
            <Link to="/settings">
              <AccountItem icon={UserImgSvg}
                text={t("My_Account.message")}
                subText={fetchedUserExistenceData?.data?.email }
                />
                
            </Link>
          </DropdownItem>

          {/* <DropdownItem className='header-item'>
            <p>Individual</p>
        </DropdownItem>

        <DropdownItem>
          <AccountItem 
            icon={eazybeLogoSvg} 
            logo={eazybeLogoSvg} 
            org_id={null} 
            text={fetchedUserExistenceData?.data?.name} 
            active={!fetchedUserExistenceData.data?.callyzer_user_mappings[0]?.is_default_org ? true : false} 
            setActive={setActiveOrg}
          />
        </DropdownItem>

        <DropdownItem className='header-item'>
            <p>Teams</p>
        </DropdownItem> */}
          {/* {
          userOrgList && userOrgList.length ? (
            userOrgList.map(item => (
                item.org_id ? (
                  <DropdownItem>
                    <AccountItem
                      icon={eazybeLogoSvg}
                      logo={item.callyzer_organization.org_logo}
                      org_id={item.org_id}
                      text={item.callyzer_organization.org_name}
                      // active={item.default_org === 1 ? true : false}
                      active={fetchedUserExistenceData.data?.callyzer_user_mappings[0].is_default_org ? true : false}
                      setActive={setActiveOrg}
                    />
                  </DropdownItem>
                ) : null
            ))
          ) : null
        } */}

          <DropdownItem className='header-item'>
            <p>SUPPORT</p>
          </DropdownItem>
          {/* <DropdownItem>
            <AccountItem icon={feedbackIconSvg} text="Feedback" />
        </DropdownItem> */}
          <DropdownItem onClick={onClickContactUs} className='mb-1'>
            <AccountItem icon={contactUsIconSvg} text={t("Contact_us.message")} />
          </DropdownItem>

          <DropdownItem onClick={handleLogOut} className='logout-item'>
            <AccountItem icon={logoutIconSvg} text={t("Logout.message")} />
          </DropdownItem>


        </DropdownMenu>
      </Dropdown>
    </>
  )
}



const mapStateToProps = createStructuredSelector({
  fetchedUserExistenceData: selectUserExistenceData,
  userOrgList: selectUserOrgList

})

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  setActiveOrganization: (orgId) => dispatch(setUserActiveOrganization(orgId)),
  getAllOrganizationsList: () => dispatch(getUsersAllOrganization()),
  fetchUserExistenceData: (userEmail) => dispatch(checkUserExistence(userEmail)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDropdown)