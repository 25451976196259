import React, { useEffect, useState } from "react";
import "./webhookintegrationheader.scss";
import whatsappIcon from "../../../../../assets/icons/whatsapp-icon.png";
import webhookIcon from "../../../../../assets/icons/webhook-icon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const WebhookIntegrationHeader = ({webhookIntegrationData, onClickSaveEndPoint, setMainSectionIntegrations}) => {

  const [currentStatus, setCurrentStatus] = useState(false);

  useEffect(()=> {
    console.log("webhookIntegrationData changed in header",webhookIntegrationData);
    if(webhookIntegrationData?.data){
      setCurrentStatus(webhookIntegrationData.data?.status == 1 ? true : false)
    } 
  }, [webhookIntegrationData])

  const onClickOff =()=> {
    onClickSaveEndPoint("status", 0)
  }

  const onClickOn =()=> {
    onClickSaveEndPoint("status", 1)
  }

  return (
    <div className="webhook-integration-header d-flex mt-1">
     
    <div className="left d-flex">
    <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mr-1 cursor-pointer"
      />
    <img src={webhookIcon} className="webhook-icon" />
    <div className="d-flex-col">
      <h5>Webhooks</h5>
      <p>Webhooks allows you to quickly connect WhatsApp to any destination.</p>
    </div>
    </div>
    {
      currentStatus ?
      <button onClick={onClickOff} className="toggle-btn active">Turn Off</button> :
      <button onClick={onClickOn} className="toggle-btn active">Turn On</button>
    }
    
    
    </div>
  );
};

export default WebhookIntegrationHeader;
