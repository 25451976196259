const CRMTypes = {
    GET_FOLLOWUPS_START: "get_followups_start",
    GET_FOLLOWUPS_SUCCESS: "get_followups_success",
    GET_FOLLOWUPS_FAILURE: "get_followups_failure",
    GET_ORG_TAGS_START: "get_org_tags_start",
    GET_ORG_TAGS_SUCCESS: "get_org_tags_success",
    GET_ORG_TAGS_FAILURE: "get_org_tags_failure",
    GET_FOLLOWUP_DETAIL_WITH_COMMENTS_START: "get_followup_detail_with_comments_start",
    GET_FOLLOWUP_DETAIL_WITH_COMMENTS_SUCCESS: "get_followup_detail_with_comments_success",
    GET_FOLLOWUP_DETAIL_WITH_COMMENTS_FAILURE: "get_followup_detail_with_comments_failure",


    GET_ZOHO_LEADS_FIELD_DATA_START: "GET_ZOHO_LEADS_FIELD_DATA_START",
    GET_ZOHO_LEADS_FIELD_DATA_SUCCESS: "GET_ZOHO_LEADS_FIELD_DATA_SUCCESS",
    GET_ZOHO_LEADS_FIELD_DATA_FAILURE: "GET_ZOHO_LEADS_FIELD_DATA_FAILURE",
    GET_ZOHO_LEADS_STATUS_DATA_START: "GET_ZOHO_LEADS_STATUS_DATA_START",
    GET_ZOHO_LEADS_STATUS_DATA_SUCCESS: "GET_ZOHO_LEADS_STATUS_DATA_SUCCESS",
    GET_ZOHO_LEADS_STATUS_DATA_FAILURE: "GET_ZOHO_LEADS_STATUS_DATA_FAILURE",

    GET_ZOHO_CONTACTS_FIELD_DATA_START: "GET_ZOHO_CONTACTS_FIELD_DATA_START",
    GET_ZOHO_CONTACTS_FIELD_DATA_SUCCESS: "GET_ZOHO_CONTACTS_FIELD_DATA_SUCCESS",
    GET_ZOHO_CONTACTS_FIELD_DATA_FAILURE: "GET_ZOHO_CONTACTS_FIELD_DATA_FAILURE",
    GET_ZOHO_CONTACTS_STATUS_DATA_START: "GET_ZOHO_CONTACTS_STATUS_DATA_START",
    GET_ZOHO_CONTACTS_STATUS_DATA_SUCCESS: "GET_ZOHO_CONTACTS_STATUS_DATA_SUCCESS",
    GET_ZOHO_CONTACTS_STATUS_DATA_FAILURE: "GET_ZOHO_CONTACTS_STATUS_DATA_FAILURE",

    GET_ZOHO_DEAL_FIELD_DATA_START: "GET_ZOHO_DEAL_FIELD_DATA_START",
    GET_ZOHO_DEAL_FIELD_DATA_SUCCESS: "GET_ZOHO_DEAL_FIELD_DATA_SUCCESS",
    GET_ZOHO_DEAL_FIELD_DATA_FAILURE: "GET_ZOHO_DEAL_FIELD_DATA_FAILURE",
    GET_ZOHO_DEAL_STATUS_DATA_START: "GET_ZOHO_DEAL_STATUS_DATA_START",
    GET_ZOHO_DEAL_STATUS_DATA_SUCCESS: "GET_ZOHO_DEAL_STATUS_DATA_SUCCESS",
    GET_ZOHO_DEAL_STATUS_DATA_FAILURE: "GET_ZOHO_DEAL_STATUS_DATA_FAILURE",


    GET_ZOHO_ORGANIZATION_INFO_START: "GET_ZOHO_ORGANIZATION_INFO_START",
    GET_ZOHO_ORGANIZATION_INFO_SUCCESS: "GET_ZOHO_ORGANIZATION_INFO_SUCCESS",
    GET_ZOHO_ORGANIZATION_INFO_FAILURE: "GET_ZOHO_ORGANIZATION_INFO_FAILURE",
    
    SAVE_ZOHO_CREATED_CONTACT_DATA_START: "SAVE_ZOHO_CREATED_CONTACT_DATA_START",
    SAVE_ZOHO_CREATED_CONTACT_DATA_SUCCESS: "SAVE_ZOHO_CREATED_CONTACT_DATA_SUCCESS",
    SAVE_ZOHO_CREATED_CONTACT_DATA_FAILURE: "SAVE_ZOHO_CREATED_CONTACT_DATA_FAILURE",

   
    
    SAVE_ZOHO_CREATED_LEAD_DATA_START: "SAVE_ZOHO_CREATED_LEAD_DATA_START",
    SAVE_ZOHO_CREATED_LEAD_DATA_SUCCESS: "SAVE_ZOHO_CREATED_LEAD_DATA_SUCCESS",
    SAVE_ZOHO_CREATED_LEAD_DATA_FAILURE: "SAVE_ZOHO_CREATED_LEAD_DATA_FAILURE",

    GET_HUBSPOT_CONTACTS_FIELD_DATA_START: "GET_HUBSPOT_CONTACTS_FIELD_DATA_START",
    GET_HUBSPOT_CONTACTS_FIELD_DATA_SUCCESS: "GET_HUBSPOT_CONTACTS_FIELD_DATA_SUCCESS",
    GET_HUBSPOT_CONTACTS_FIELD_DATA_FAILURE: "GET_HUBSPOT_CONTACTS_FIELD_DATA_FAILURE",

    GET_HUBSPOT_ACCOUNT_DETAILS_START: "GET_HUBSPOT_ACCOUNT_DETAILS_START",
    GET_HUBSPOT_ACCOUNT_DETAILS_SUCCESS: "GET_HUBSPOT_ACCOUNT_DETAILS_SUCCESS",
    GET_HUBSPOT_ACCOUNT_DETAILS_FAILURE: "GET_HUBSPOT_ACCOUNT_DETAILS_FAILURE",

    GET_HUBSPOT_OWNER_ID_START: "GET_HUBSPOT_OWNER_ID_START",
    GET_HUBSPOT_OWNER_ID_SUCCESS: "GET_HUBSPOT_OWNER_ID_SUCCESS",
    GET_HUBSPOT_OWNER_ID_FAILURE: "GET_HUBSPOT_OWNER_ID_FAILURE",

    
    GET_HUBSPOT_FOLLOWUP_START: "GET_HUBSPOT_FOLLOWUP_START",
    GET_HUBSPOT_FOLLOWUP_SUCCESS: "GET_HUBSPOT_FOLLOWUP_SUCCESS",
    GET_HUBSPOT_FOLLOWUP_FAILURE: "GET_HUBSPOT_FOLLOWUP_FAILURE",

       
    GET_HUBSPOT_OWNERS_START: "GET_HUBSPOT_OWNERS_START",
    GET_HUBSPOT_OWNERS_SUCCESS: "GET_HUBSPOT_OWNERS_SUCCESS",
    GET_HUBSPOT_OWNERS_FAILURE: "GET_HUBSPOT_OWNERS_FAILURE",

        
    GET_HUBSPOT_DEALS_PIPELINE_DATA_START: "GET_HUBSPOT_DEALS_PIPELINE_DATA_START",
    GET_HUBSPOT_DEALS_PIPELINE_DATA_SUCCESS: "GET_HUBSPOT_DEALS_PIPELINE_DATA_SUCCESS",
    GET_HUBSPOT_DEALS_PIPELINE_DATA_FAILURE: "GET_HUBSPOT_DEALS_PIPELINE_DATA_FAILURE",

        
    GET_HUBSPOT_TICKETS_PIPELINE_DATA_START: "GET_HUBSPOT_TICKETS_PIPELINE_DATA_START",
    GET_HUBSPOT_TICKETS_PIPELINE_DATA_SUCCESS: "GET_HUBSPOT_TICKETS_PIPELINE_DATA_SUCCESS",
    GET_HUBSPOT_TICKETS_PIPELINE_DATA_FAILURE: "GET_HUBSPOT_TICKETS_PIPELINE_DATA_FAILURE",


    SAVE_HUBSPOT_DELETED_CONTACT_DATA_START: "SAVE_HUBSPOT_DELETED_CONTACT_DATA_START",
    SAVE_HUBSPOT_DELETED_CONTACT_DATA_SUCCESS: "SAVE_HUBSPOT_DELETED_CONTACT_DATA_SUCCESS",
    SAVE_HUBSPOT_DELETED_CONTACT_DATA_FAILURE: "SAVE_HUBSPOT_DELETED_CONTACT_DATA_FAILURE",

    GET_INTEGRATIONS_SYNC_INFO_START: "GET_INTEGRATIONS_SYNC_INFO_START",
    GET_INTEGRATIONS_SYNC_INFO_SUCCESS: "GET_INTEGRATIONS_SYNC_INFO_SUCCESS",
    GET_INTEGRATIONS_SYNC_INFO_FAILURE: "GET_INTEGRATIONS_SYNC_INFO_FAILURE",

    GET_HUBSPOT_CALLS_DISPOSITION_DATA_START: "GET_HUBSPOT_CALLS_DISPOSITION_DATA_START",
    GET_HUBSPOT_CALLS_DISPOSITION_DATA_SUCCESS: "GET_HUBSPOT_CALLS_DISPOSITION_DATA_SUCCESS",
    GET_HUBSPOT_CALLS_DISPOSITION_DATA_FAILURE: "GET_HUBSPOT_CALLS_DISPOSITION_DATA_FAILURE",

    
    GET_HUBSPOT_CALLS_DIRECTION_DATA_START: "GET_HUBSPOT_CALLS_DIRECTION_DATA_START",
    GET_HUBSPOT_CALLS_DIRECTION_DATA_SUCCESS: "GET_HUBSPOT_CALLS_DIRECTION_DATA_SUCCESS",
    GET_HUBSPOT_CALLS_DIRECTION_DATA_FAILURE: "GET_HUBSPOT_CALLS_DIRECTION_DATA_FAILURE",

}


export default CRMTypes;


export const UPDATE_CRM_SELECTED_LABEL = 'UPDATE_CRM_SELECTED_LABEL';
export const UPDATE_CRM_FOR_ALL = 'UPDATE_CRM_FOR_ALL';
export const RESET_CRM_SELECTION = 'RESET_CRM_SELECTION';
export const RESET_ALL_CRM_SELECTION = 'RESET_ALL_CRM_SELECTION';
export const SET_CRM_SELECTED_LABEL = 'SET_CRM_SELECTED_LABEL';

export const CRM_SYNC_LABEL_STATUS = {
  LABEL_INCLUDED: 'LABEL_INCLUDED',
  LABEL_EXCLUDED: 'LABEL_EXCLUDED',
  NO_STATUS: 'NO_STATUS',
};

export const SYNC_TYPES = {
  INBOX: 'INBOX',
  // Add other sync types as needed
};

export const LABEL_ASSIGN_TYPES = {
  SELF: 'SELF',
  // Add other label assign types as needed
};