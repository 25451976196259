const LayoutTypes = {
    TOGGLE_LEFTMENU: 'TOGGLE_LEFTMENU',
    UPDATE_SELECTED_EMPLOYEE: 'UPDATE_SELECTED_EMPLOYEE',
    UPDATE_SELECTED_EMPLOYEE_CONVO: 'UPDATE_SELECTED_EMPLOYEE_CONVO',
    UPDATE_SELECTED_TEAM: 'UPDATE_SELECTED_TEAM',
    UPDATE_FILTER_DATES: 'UPDATE_FILTER_DATES',
    UPDATE_DATE_TYPES: 'UPDATE_DATE_TYPES',
    UPDATE_CURRENT_NAVIGATION: 'UPDATE_CURRENT_NAVIGATION'
}

export default LayoutTypes;