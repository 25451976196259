import React, { useState, useEffect } from "react";
import { Input, InputGroup } from "rsuite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Container, Row } from "reactstrap";
import showToast from "../../../../../components/Toasts/ShowToast";
import { checkForCrmiKeyInAuth } from "../../../../../util/helper";
import "./leadsquaredmodal.styles.scss";
import { createAuthLeadsquared } from "../../../../../redux/integrations/integrations.action";

// Style for the modal popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

// Inline style for the InputGroup
const styles = {
  marginBottom: 10,
};

// Custom InputGroup component with label and input
const CustomInputGroup = ({ label, value, onChange, placeholder, name, ...props }) => (
  <div className="custom-input-group d-flex-col">
    <p className="mt-1 select-txt">{label}</p>
    <InputGroup {...props} onChange={onChange} style={styles}>
      <Input value={value} name={name} placeholder={placeholder} />
    </InputGroup>
  </div>
);

/**
 * LeadsquaredModal component - A modal for connecting to Leadsquared
 * @param {Object} props - Component props
 * @param {boolean} props.open - Determines if the modal is open
 * @param {function} props.handleClose - Function to close the modal
 * @param {function} props.setLeadsquaredConnected - Function to set Leadsquared connection state
 * @param {function} props.checkIfLeadsquaredIntegrated - Function to check Leadsquared integration status
 * @param {function} props.sendMessageToChromeExtension - Function to send messages to Chrome extension
 */
const LeadsquaredModal = ({
  open,
  handleClose,
  setLeadsquaredConnected,
  checkIfLeadsquaredIntegrated,
  sendMessageToChromeExtension,
}) => {
  const [openPopup, setOpenPopup] = useState(open);

  // Sync the local state with the prop 'open'
  useEffect(() => {
    setOpenPopup(open);
  }, [open]);

  const [formData, setFormData] = useState({
    hostName: "",
    accessKey: "",
    secretKey: "",
  });

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handlers to open and close the popup
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  // Validate the Leadsquared URL
  function validateLeadsquaredURL(data) {
    return data.hostName.length && data.accessKey.length && data.secretKey.length ? true : false;
  }

  // Check the Leadsquared API key validity
  function checkLeadsquaredAPIKey() {
    return new Promise((resolve) => {
     
      const url = `${formData.hostName}Authentication.svc/UserByAccessKey.Get?accessKey=${formData.accessKey}&secretKey=${formData.secretKey}`
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(url, headers)
        .then((response) => {
          resolve(response.ok); // Resolve true if the API key is valid, otherwise false
        })
        .catch(() => {
          resolve(false); // Resolve false if an error occurs
        });
    });
  }

  function convertLeadSquaredUrlToRedirectUrl(url) {
    try {
      const parsedUrl = new URL(url);
  
      // Extract the host part and remove 'api-'
      const host = parsedUrl.host.replace('api-', '');
  
      // Reconstruct the new URL
      const newUrl = `${parsedUrl.protocol}//${host}/`;
  
      return newUrl;
    } catch (error) {
      console.error("Error in convertLeadSquaredUrlToRedirectUrl Invalid URL:", error);
      return null;
    }
  }

  // Handler for the "Continue" button click
  const onClickContinueBtn = async () => {
    if (!validateLeadsquaredURL(formData)) {
      showToast("Please fill all the fields to continue...");
    } else if (!(await checkLeadsquaredAPIKey())) {
      showToast("Wrong Host, Access Key or Secret key");
    } else {
     
      const body = {
        host: formData.hostName,
        homepage : convertLeadSquaredUrlToRedirectUrl(formData.hostName),
        access_id: formData.accessKey,
        secret_key: formData.secretKey,
      };

      const resSaveLeadsquaredAPIDetails = await createAuthLeadsquared(body) ;
      if (resSaveLeadsquaredAPIDetails?.status) {
        checkIfLeadsquaredIntegrated();
        sendMessageToChromeExtension(true, 500);
        showToast("Linked Leadsquared successfully");
        window.gtag("event", "Leadsquaredintegrated");
        window.gtagAW("event", "Leadsquaredintegrated");
        setLeadsquaredConnected(true);
        checkForCrmiKeyInAuth();
        handleClose();
      } else {
        showToast("Please check the value you have entered");
      }
    }
  };

  return (
    <Modal
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="leadsquared-modal"
    >
      <Box className="modal-container" sx={{ ...style }}>
        <Container className="files-container d-flex-col">
          <h5 className="mb-1">Connect to Leadsquared</h5>
          <CustomInputGroup
            label="Host Name"
            value={formData.hostName}
            onChange={handleChange}
            name="hostName"
            size="sm"
            placeholder="Host Name"
          />

          <CustomInputGroup
            label="Access Key"
            value={formData.accessKey}
            onChange={handleChange}
            name="accessKey"
            size="sm"
            placeholder="Access key"
          />

          <CustomInputGroup
            label="Secret Key"
            value={formData.secretKey}
            onChange={handleChange}
            name="secretKey"
            size="sm"
            placeholder="Secret key"
          />
        </Container>

        <Row className="btns-container d-flex">
          <button className="cancel-btn" onClick={handleClosePopup}>
            Cancel
          </button>
          <button className="sync-btn" onClick={onClickContinueBtn}>
            Connect
          </button>
        </Row>
      </Box>
    </Modal>
  );
};

export default LeadsquaredModal;
