const ConversationTypes = {
    GET_USER_TAGS_START: "get_user_tags_start",
    GET_USER_TAGS_SUCCESS: "get_user_tags_success",
    GET_USER_TAGS_FAILURE: "get_user_tags_failure",

    GET_ALL_USER_MESSAGES_START: "GET_ALL_USER_MESSAGES_START",
    GET_ALL_USER_MESSAGES_SUCCESS: "GET_ALL_USER_MESSAGES_SUCCESS",
    GET_ALL_USER_MESSAGES_FAILURE: "GET_ALL_USER_MESSAGES_FAILURE",

    GET_ALL_CHATTER_START: "GET_ALL_CHATTER_START",
    GET_ALL_CHATTER_SUCCESS: "GET_ALL_CHATTER_SUCCESS",
    GET_ALL_CHATTER_FAILURE: "GET_ALL_CHATTER_FAILURE",

    UPDATE_TOTAL_MESSAGES_COUNT: "UPDATE_TOTAL_MESSAGES_COUNT",
    UPDATE_NEED_REPLY_COUNT: "UPDATE_NEED_REPLY_COUNT",
    UPDATE_ACTIVE_CHAT_COUNT: "UPDATE_ACTIVE_CHAT_COUNT",


    TOTAL_MESSAGE_COUNT: "TOTAL_MESSAGE_COUNT",
    UNIQUE_CHAT_COUNT: "UNIQUE_CHAT_COUNT",
    TOTAL_MESSAGE_RATIO: "TOTAL_MESSAGE_RATIO",
    UNIQUE_CHAT_RATIO: "UNIQUE_CHAT_RATIO",
    MESSAGES_OVERVIEW: "MESSAGES_OVERVIEW",

    FILTER_CHATS_ON_SEARCH: "FILTER_CHATS_ON_SEARCH",
    FILTER_CHATS_FOR_ACTIVE_CHAT: "FILTER_CHATS_FOR_ACTIVE_CHAT",
    UPDATE_ACTIVE_CHATTER: "UPDATE_ACTIVE_CHATTER",

    GET_ALL_CHATTER_USING_GRAPHQL_START: "GET_ALL_CHATTER_USING_GRAPHQL_START",
    GET_ALL_CHATTER_USING_GRAPHQL_SUCCESS: "GET_ALL_CHATTER_USING_GRAPHQL_SUCCESS",
    GET_ALL_CHATTER_USING_GRAPHQL_FAILURE: "GET_ALL_CHATTER_USING_GRAPHQL_FAILURE",

    GET_NEXT_CHATTER_USING_GRAPHQL_SUCCESS: "GET_NEXT_CHATTER_USING_GRAPHQL_SUCCESS",

    GET_ALL_CHATTER_USING_GRAPHQL_UPDATE: "GET_ALL_CHATTER_USING_GRAPHQL_UPDATE",

    UPDATE_CHATTER_START: "UPDATE_CHATTER_START_START",
    UPDATE_CHATTER_SUCCESS: "UPDATE_CHATTER_SUCCESS",
    UPDATE_CHATTER_FAILURE: "UPDATE_CHATTER_FAILURE",

    GET_USERS_USING_GRAPHQL_START: "GET_USERS_USING_GRAPHQL_START",
    GET_USERS_USING_GRAPHQL_SUCCESS: "GET_USERS_USING_GRAPHQL_SUCCESS",
    GET_USERS_USING_GRAPHQL_FAILURE: "GET_USERS_USING_GRAPHQL_FAILURE",

    GET_USER_FOLLOWUP_DATA_START: "get_user_followup_data_start",
    GET_USER_FOLLOWUP_DATA_SUCCESS: "get_user_followup_data_success",
    GET_USER_FOLLOWUP_DATA_FAILURE: "get_user_followup_data_failure",

    GET_CONVERSATION_ANALYTICS_START: "get_conversation_analytics_start",
    GET_CONVERSATION_ANALYTICS_SUCCESS: "get_conversation_analytics_success",
    GET_CONVERSATION_ANALYTICS_FAILURE: "get_conversation_analytics_failure",

    GET_DATE_CONVERSATION_ANALYTICS_START: "get_date_conversation_analytics_start",
    GET_DATE_CONVERSATION_ANALYTICS_SUCCESS: "get_date_conversation_analytics_success",
    GET_DATE_CONVERSATION_ANALYTICS_FAILURE: "get_date_conversation_analytics_failure",

    GET_DATE_NEEDS_REPLY_AWAITING_REPLY_START: "get_date_needs_reply_awaiting_reply_start",
    GET_DATE_NEEDS_REPLY_AWAITING_REPLY_SUCCESS: "get_date_needs_reply_awaiting_reply_success",
    GET_DATE_NEEDS_REPLY_AWAITING_REPLY_FAILURE: "get_date_needs_reply_awaiting_reply_failure",

    CLEAR_CHATTER_TOKEN: "CLEAR_CHATTER_TOKEN", 
    UPDATE_CONVERSATIONS_LOADER: "UPDATE_CONVERSATIONS_LOADER",
    CHATTERS_LAST_FETCHED_TIME : "CHATTERS_LAST_FETCHED_TIME",
    GET_MESSAGES_FOR_ACTIVE_CHATTER: "GET_MESSAGES_FOR_ACTIVE_CHATTER",
    GET_NEXT_PAGE_MESSAGES_FOR_ACTIVE_CHATTER: "GET_NEXT_PAGE_MESSAGES_FOR_ACTIVE_CHATTER",
    UPDATE_MESSAGES_FOR_CHATTER: "UPDATE_MESSAGES_FOR_CHATTER",

    GET_USER_NOTES_START: "get_user_notes_start",
    GET_USER_NOTES_SUCCESS: "get_user_notes_success",
    GET_USER_NOTES_FAILURE: "get_user_notes_failure",

    CREATE_USER_NOTE_START: "create_user_note_start",
    CREATE_USER_NOTE_SUCCESS: "create_user_note_success",
    CREATE_USER_NOTE_FAILURE: "create_user_note_failure",

    UPDATE_USER_NOTE_START: "update_user_note_start",
    UPDATE_USER_NOTE_SUCCESS: "update_user_note_success",
    UPDATE_USER_NOTE_FAILURE: "update_user_note_failure",

    EDIT_DELETE_FOLLOWUP_DATA_START: "edit_delete_followup_data_start",
    EDIT_DELETE_FOLLOWUP_DATA_SUCCESS: "edit_delete_followup_data_success",
    EDIT_DELETE_FOLLOWUP_DATA_FAILURE: "edit_delete_followup_data_failure",

    CREATE_FOLLOW_UP_DATA_START: "create_follow_up_data_start",
    CREATE_FOLLOW_UP_DATA_SUCCESS: "create_follow_up_data_success",
    CREATE_FOLLOW_UP_DATA_FAILURE: "create_follow_up_data_failure",

    SET_LABEL_CHATTERS: "SET_LABEL_CHATTERS",
    ACTIVE_LABEL : "ACTIVE_LABEL",
    UPDATE_SENT_MESSAGE_TO_ACTIVE_CHATTER: "UPDATE_SENT_MESSAGE_TO_ACTIVE_CHATTER"
};

export default ConversationTypes;