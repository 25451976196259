
import { getHours } from 'date-fns';
import {
    createSelector
} from 'reselect';
import {
    sumUpTime,
    toSeconds,
    sumUpTimeInFormat,
    secondsToHours,
    secondsToHms,
    secondsToHmsInFormat,
} from '../../util/helper';
import {
    selectedEmployee
} from '../layout/layout.selector';
import { selectTeamsListData } from '../organization/organization.selector';

const dashboard = state => state.dashboard
 
/**
 * call type: 
 * 1 -> incoming calls
 * 2 -> outgoing calls
 * 3 -> missed calls
 * 5 -> rejected calls
 */
export const selectCallSections = createSelector(
    [dashboard],
    dashboard => dashboard.callSections
)






export const selectTopPerformers = createSelector(
    [dashboard,selectTeamsListData],
    (dashboard,teamsList) => dashboard.topPerformersList.map((item,index) => {
        let findItem = teamsList.find(it => it.user_id === item.user_id)
        return findItem ? (
            {
                name: findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name : findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA" ,
                team_name: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA",
                position: `${index + 1}`,
                color: "#5F72BD",
                imageBackground: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_color ? findItem.callyzer_orgamnization_team.team_color : "#E9F7F7" : "#E9F7F7"
            }
        ) : null
    })
)
export const selectTopPerformersOutGoing = createSelector(
    [dashboard,selectTeamsListData],
    (dashboard,teamsList) => dashboard.topPerformersListOutGoing.map((item,index) => {
        let findItem = teamsList.find(it => it.user_id === item.user_id)
        return findItem ? (
            {
                name: findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name : findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA" ,
                team_name: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA",
                position: `${index + 1}`,
                color: "#5F72BD",
                imageBackground: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_color ? findItem.callyzer_orgamnization_team.team_color : "#E9F7F7" : "#E9F7F7"
            }
        ) : null
    })
)
export const selectNeedAttention = createSelector(
    [dashboard,selectTeamsListData],
    (dashboard,teamsList) => dashboard.needAttentionList.map((item,index) => {
        let findItem = teamsList.find(it => it.user_id === item.user_id)
           return findItem ? (
             {
                name: findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name : findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA" ,
                team_name: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA",
                position: `${index + 1}`,
                color: "#5F72BD",
                imageBackground: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_color ? findItem.callyzer_orgamnization_team.team_color : "#E9F7F7" : "#E9F7F7"
              }
           ) : null
          
    })
)
export const selectNeedAttentionOutGoing = createSelector(
    [dashboard,selectTeamsListData],
    (dashboard,teamsList) => dashboard.needAttentionListOutGoing.map((item,index) => {
        let findItem = teamsList.find(it => it.user_id === item.user_id)
        return findItem ? (
            {
               name: findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name : findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA" ,
               team_name: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA",
               position: `${index + 1}`,
               color: "#5F72BD",
               imageBackground: findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_color ? findItem.callyzer_orgamnization_team.team_color : "#E9F7F7" : "#E9F7F7"
             }
          ) : null
    })
)



export const selectUniqueClients = createSelector(
    [dashboard],
    dashboard => {
        return {
            unique: dashboard.uniqueClients?.unique_clients ?? 0,
            total: dashboard.uniqueClients?.total_clients ?? 0
        }
    }
)



export const selectUniqueClientData = createSelector(
    [dashboard],
    dashboard => dashboard.uniqueClient
)

export const selectNewClientData = createSelector(
    [dashboard],
    dashboard => dashboard.newClient
)

export const selectDashboardLoader = createSelector(
    [dashboard],
    dashboard => dashboard.showLoader     
    )

export const selectUniqueSplineData = createSelector(
    [selectUniqueClientData, (_,props) => props.activeFilters],
    (uniqueClientData,activeFilters) => {
        let splineList = uniqueClientData.incoming?.concat(uniqueClientData?.outgoing ?? []) ?? []
        let splineData = activeFilters?.type ? splineList.filter(it => it.call_type === parseInt(activeFilters.type)) : splineList 

        let list = [];
        splineData.forEach(it => {
            if (!list.length) {
                list.push(it)
            } else {
                let findItem = list.find(item => item.dates === it.dates)
                if (findItem) {
                    findItem.total_outgoings = parseInt(it.total_outgoings) + parseInt(findItem.total_outgoings)
                    findItem.total_time = sumUpTimeInFormat([{
                        call_duration: it.total_time
                    },
                    {
                        call_duration: findItem.total_time
                    },
                ])
                } else {
                    list.push(it)
                }
            }
        })
        return list;
    }
)

export const selectNewSplineData = createSelector(
    [selectNewClientData, (_,props) => props.activeFilters],
    (newClientData,activeFilters) => {
        let splineList = newClientData.incoming?.concat(newClientData?.outgoing ?? []) ?? []
        let splineData = activeFilters?.type ? splineList.filter(it => it.call_type === parseInt(activeFilters.type)) : splineList 

        let list = [];
        splineData.forEach(it => {
            if (!list.length) {
                list.push(it)
            } else {
                let findItem = list.find(item => item.dates === it.dates)
                if (findItem) {
                    findItem.total_outgoings = parseInt(it.total_outgoings) + parseInt(findItem.total_outgoings)
                    findItem.total_time = sumUpTimeInFormat([{
                        call_duration: it.total_time
                    },
                    {
                        call_duration: findItem.total_time
                    },
                ])
                } else {
                    list.push(it)
                }
            }
        })
        return list;
    }
)

// export const selectUniquePerformanceData = createSelector(
//     [selectUniqueClientData,selectTeamsListData],
//     (uniqueClientData,teamsList) => ({
//         incoming_top: uniqueClientData.incoming?.slice(0,2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         outgoing_top: uniqueClientData.outgoing?.slice(0,2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         incoming_attention: uniqueClientData.incoming?.slice(-2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         outgoing_attention: uniqueClientData.outgoing?.slice(-2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         })
//     })
// )

// export const selectNewPerformanceData = createSelector(
//     [selectNewClientData,selectTeamsListData],
//     (newClientData,teamsList) => ({
//         incoming_top: newClientData.incoming?.slice(0,2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         outgoing_top: newClientData.outgoing?.slice(0,2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         incoming_attention: newClientData.incoming?.slice(-2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         }),
//         outgoing_attention: newClientData.outgoing?.slice(-2).map((item,index) => {
//             let findItem = createPerformerObject(item,index,teamsList)
//             return findItem ?? []
//         })
//     })
// )

export const selectCallsOverViewAws = createSelector(
    [dashboard],
    dashboard => {
        let  array = [];
        if (dashboard.callsOverView){
            console.log("calls over view",dashboard.callsOverView)
        let valuesArray = Array.from(dashboard.callsOverView.values())
        
        array = valuesArray.map(item => {
            return {
                incoming_count: item["1"] ? item["1"].totalCount : 0,
                incoming_duration: item["1"] ? secondsToHmsInFormat(item["1"].totalDuration) : "00:00:00",
                incoming_duration_int: item["1"] ? item["1"].totalDuration : 0,
                incoming_unique :  item["1"] ? item["1"].uniqueCalls : 0,

                missed_count: item["3"] ? item["3"].totalCount : 0,
                missed_duration: item["3"] ? item["3"].totalDuration : 0,

                rejected_count: item["5"] ? item["5"].totalCount : 0,
                rejected_duration: item["5"] ? item["5"].totalDuration : 0,

                mobile: item.user ? item.user.mobile : null,
                name: item.user ? item.user.name : null,

                outgoing_count: item["2"] ? item["2"].totalCount : 0,
                outgoing_duration: item["2"] ? secondsToHmsInFormat(item["2"].totalDuration) : "00:00:00",
                outgoing_duration_int: item["2"] ? item["2"].totalDuration : 0,
                outgoing_unique :  item["2"] ? item["2"].uniqueCalls : 0,

                team: item.team,
                user_id: item.userId,
                user: item.user
            }
            
        })

    }
    return array
    }
)

export const selectCallsOverviewDateComparision = createSelector(
    [dashboard],
    dashboard => dashboard.callsOverViewDateComparision
)

export const selectTotalsFromCallsOverview = createSelector(
    [selectCallsOverViewAws],
    callsOverView => {

        let callsOverViewClone = [...callsOverView]

        let totalsObj = {
            incoming_count: callsOverViewClone.reduce((prev,curr) => prev + curr.incoming_count,0),
            incoming_duration: callsOverViewClone.reduce((prev,curr) => prev + curr.incoming_duration_int,0),

            outgoing_count: callsOverViewClone.reduce((prev,curr) => prev + curr.outgoing_count,0),
            outgoing_duration: callsOverViewClone.reduce((prev,curr) => prev + curr.outgoing_duration_int,0),

            missed_count: callsOverViewClone.reduce((prev,curr) => prev + curr.missed_count,0),
            rejected_count: callsOverViewClone.reduce((prev,curr) => prev + curr.rejected_count,0),
        }

        totalsObj.total_calls = totalsObj.incoming_count + totalsObj.outgoing_count + totalsObj.missed_count + totalsObj.rejected_count

        totalsObj.total_duration = sumUpTime([
            {
                call_duration: secondsToHmsInFormat(totalsObj.outgoing_duration)
            },
            {
                call_duration: secondsToHmsInFormat(totalsObj.incoming_duration)
            }
        ])
        
    return totalsObj;

    }
)

export const selectTotalCallCountSpline = createSelector(
    [dashboard, (_,props) => props.activeFilters],
    (dashboard,activeFilters) => {
        if(activeFilters?.type !== 0 ) {
            return dashboard.totalCallCountSpline.map( item => {
                let callTypeItem = item["3"].buckets.find(it => it.key === activeFilters.type.toString());
                return [item.key_as_string,callTypeItem ? callTypeItem.doc_count : 0 ]
            })
        } else  {
            return dashboard.totalCallCountSpline.map( item => {
                return [item.key_as_string,item.doc_count]
            })
        }
    })

    export const selectTotalCallCountRatio = createSelector(
        [selectTotalsFromCallsOverview],
        (totalsObj) => {
            let obj = {
                outgoing_count: 0,
                incoming_count: 0
            }
            

            if(totalsObj) {


            obj = {
                outgoing_count: totalsObj.outgoing_count,
                incoming_count: totalsObj.incoming_count
            }

        }
            
            return obj;
        })

    export const selectUniqueCallCountSpline = createSelector(
        [dashboard, (_,props) => props.activeFilters],
        (dashboard,activeFilters) => {
            if(activeFilters?.type !== 0 ) {
                return dashboard.uniqueCallCountSpline.map( item => {
                    let callTypeItem = item["3"].buckets.find(it => it.key === activeFilters.type.toString());
                    return [item.key_as_string,callTypeItem ? callTypeItem["1"].value : 0 ]
                })
            } else  {
                return dashboard.uniqueCallCountSpline.map( item => {
                    let totalDuration = item["3"].buckets.reduce((prev,curr) => (prev["1"] ? prev["1"].value : prev) + curr["1"]?.value,0)
                    return [item.key_as_string,totalDuration]
                })
            }
        })

        export const selectUniqueCallCountRatio= createSelector(
            [dashboard],
            (dashboard) => {
                let obj = {
                    outgoing_unique: 0,
                    incoming_unique: 0
                }

                console.log("unique",dashboard.uniqueCallCountSpline)
    
               let sent = dashboard.uniqueCallCountSpline.reduce((prev,curr)  => {
                    let prevSentItem = prev["3"] ? prev["3"].buckets.find(it => it.key === "2") ? prev["3"].buckets.find(it => it.key === "2")["1"] ? prev["3"].buckets.find(it => it.key === "2")["1"].value : prev : prev : prev
    
                    let currSentItem = curr["3"] ? curr["3"].buckets.find(it => it.key === "2") ? curr["3"].buckets.find(it => it.key === "2")["1"] ? curr["3"].buckets.find(it => it.key === "2")["1"].value : 0 : 0 : 0
    
                    return prevSentItem + currSentItem
                },0)
    
                let received = dashboard.uniqueCallCountSpline.reduce((prev,curr)  => {
                    let prevSentItem = prev["3"] ? prev["3"].buckets.find(it => it.key === "1") ? prev["3"].buckets.find(it => it.key === "1")["1"] ? prev["3"].buckets.find(it => it.key === "1")["1"].value : prev : prev : prev
    
                    let currSentItem = curr["3"] ? curr["3"].buckets.find(it => it.key === "1") ? curr["3"].buckets.find(it => it.key === "1")["1"] ? curr["3"].buckets.find(it => it.key === "1")["1"].value : 0 : 0 : 0
    
                    return prevSentItem + currSentItem
                },0)
    
                obj = {
                    outgoing_unique:sent,
                    incoming_unique: received
                }
                return obj;
            })


            export const selectNewCallCountSpline = createSelector(
                [dashboard, (_,props) => props.activeFilters],
                (dashboard,activeFilters) => {
                    if(activeFilters?.type !== 0 ) {
                        return dashboard.newCallsCountSpline.map( item => {
                            let callTypeItem = item["3"].buckets.find(it => it.key === activeFilters.type.toString());
                            return [item.key_as_string, callTypeItem ? callTypeItem.doc_count : 0  ]
                        })
                    } else  {
                        return dashboard.newCallsCountSpline.map( item => {
                            return [item.key_as_string,item.doc_count]
                        })
                    }
                })

                
                export const selectNewCallCountRatio= createSelector(
                    [dashboard],
                    (dashboard) => {
                        let obj = {
                            outgoing_new: 0,
                            incoming_new: 0
                        }
            
                       let sent = dashboard.newCallsCountSpline.reduce((prev,curr)  => {
                            let prevSentItem = prev["3"] ? prev["3"].buckets.find(it => it.key === "2") ? prev["3"].buckets.find(it => it.key === "2").doc_count : prev : prev
            
                            let currSentItem = curr["3"] ? curr["3"].buckets.find(it => it.key === "2") ? curr["3"].buckets.find(it => it.key === "2").doc_count:  0 : 0 
            
                            return prevSentItem + currSentItem
                        },0)
            
                        let received = dashboard.newCallsCountSpline.reduce((prev,curr)  => {
                            let prevSentItem = prev["3"] ? prev["3"].buckets.find(it => it.key === "1") ? prev["3"].buckets.find(it => it.key === "1").doc_count : prev : prev
            
                            let currSentItem = curr["3"] ? curr["3"].buckets.find(it => it.key === "1") ? curr["3"].buckets.find(it => it.key === "1").doc_count : 0 : 0
            
                            return prevSentItem + currSentItem
                        },0)
            
                        obj = {
                            outgoing_new:sent,
                            incoming_new: received
                        }
                        return obj;
                    })
        
    

        

    export const selectTotalCallDurationSpline = createSelector(
        [dashboard, (_,props) => props.activeFilters],
        (dashboard,activeFilters) => {
            if(activeFilters?.type !== 0 ) {
                return dashboard.totalCallDurationSpline.map( item => {
                    let callTypeItem = item["3"].buckets.find(it => it.key === activeFilters.type.toString());
                    return [item.key_as_string,callTypeItem ? secondsToHours(callTypeItem["1"].value) : 0 ]
                })
            } else  {
                return dashboard.totalCallDurationSpline.map( item => {
                    let totalDuration = item["3"].buckets.reduce((prev,curr) => (prev["1"] ? prev["1"].value : prev) + curr["1"]?.value,0)
                    return [item.key_as_string,secondsToHours(totalDuration)]
                })
            }
        })

        export const selectTotalCallDurationRatio= createSelector(
            [selectTotalsFromCallsOverview],
            (totals) => {
                let obj = {
                    outgoing_duration: 0,
                    incoming_duration: 0
                }

                if(totals) {

                    obj = {
                        outgoing_duration: parseFloat(secondsToHours(totals.outgoing_duration)),
                        incoming_duration: parseFloat(secondsToHours(totals.incoming_duration))
                    }

                }
    
                
                return obj;
            })

            export const selectNewCallsOverViewAws = createSelector(
                [dashboard],
                dashboard => {
                    let  array = [];
                    if (dashboard.newCallsOverView){
                        array = Array.from(dashboard.newCallsOverView.values())
                }
                return array
                }
            )



export const selectTeamsCallsOverViewAws = createSelector(
    [selectCallsOverViewAws],
    callsOverView => {

        let callsOverViewClone = [...callsOverView]
        let teamsOverList = []

        callsOverViewClone.forEach(item => {
            if(!teamsOverList.length) {
                teamsOverList.push({...item})
            } else {
                let findItem = teamsOverList.find(it => it.team?.id === item.team?.id)
                
                if(findItem) {

                    findItem.incoming_count = findItem.incoming_count + item.incoming_count

                    findItem.incoming_duration = sumUpTimeInFormat([
                        {
                            call_duration: findItem.incoming_duration
                        },
                        {
                            call_duration: item.incoming_duration
                        }
                    ])

                    findItem.incoming_duration_int = findItem.incoming_duration_int + item.incoming_duration_int

                    findItem.missed_count = findItem.missed_count + item.missed_count

                    findItem.rejected_count = findItem.rejected_count + item.rejected_count

                    findItem.outgoing_count = findItem.outgoing_count + item.outgoing_count

                    findItem.outgoing_duration = sumUpTimeInFormat([
                        {
                            call_duration: findItem.outgoing_duration
                        },
                        {
                            call_duration: item.outgoing_duration
                        }
                    ])

                    findItem.outgoing_duration_int = findItem.outgoing_duration_int + item.outgoing_duration_int

                    findItem.userId = null
                    findItem.name = null
                } else {
                    teamsOverList.push({...item})
                }
            }
        })  
        
    return teamsOverList;

    }
)




/**
 *  top performer and need attention on the basis of incoming and outgoing counts.
 */
export const selectPerformerForCount = createSelector(
    [selectCallsOverViewAws],
    (overViewCallList) =>  {
        let overViewList = [...overViewCallList]
        let performerObj = {
            top_performer_incoming: [],
            top_performer_outgoing: [],
            need_attention_incoming: [],
            need_attention_outgoing: [],
        }

        let sortedIncoming = overViewList.sort((a,b) =>  (b.incoming_count ?? 0) - (a.incoming_count ?? 0))
        let sortedOutGoing = overViewList.sort((a,b) => (b.outgoing_count ?? 0) - (a.outgoing_count ?? 0))

        performerObj.top_performer_incoming = sortedIncoming.slice(0,2)

        performerObj.top_performer_outgoing = sortedOutGoing.slice(0,2)

        performerObj.need_attention_incoming = sortedIncoming.slice(-2)

        performerObj.need_attention_outgoing = sortedOutGoing.slice(-2)
      
        return performerObj
    }
)
    

/**
 *  top performer and need attention on the basis of incoming and outgoing counts.
 */
 export const selectPerformerForDuration = createSelector(
    [selectCallsOverViewAws],
    (overViewCallList) =>  {
        let overViewList = [...overViewCallList]
        let performerObj = {
            top_performer_incoming: [],
            top_performer_outgoing: [],
            need_attention_incoming: [],
            need_attention_outgoing: [],
        }

        let sortedIncoming = overViewList.sort((a,b) => ( (b.incoming_duration ?? 0) - (a.incoming_duration ?? 0) ))

        let sortedOutGoing = overViewList.sort((a,b) => (b.outgoing_duration ?? 0) - (a.outgoing_duration ?? 0))

        performerObj.top_performer_incoming =   sortedIncoming.slice(0,2)

        performerObj.top_performer_outgoing = sortedOutGoing.slice(0,2)

        performerObj.need_attention_incoming = sortedIncoming.slice(-2)

        performerObj.need_attention_outgoing = sortedOutGoing.slice(-2)
      
        return performerObj
    }
)

/**
 *  top performer and need attention on the basis of unique.
 */
 export const selectUniquePerformanceData = createSelector(
    [selectCallsOverViewAws],
    (overViewCallList) =>  {
        let overViewList = [...overViewCallList]
        let performerObj = {
            top_performer_incoming: [],
            top_performer_outgoing: [],
            need_attention_incoming: [],
            need_attention_outgoing: [],
        }

        let sortedIncoming = overViewList.sort((a,b) =>  (b.incoming_unique ?? 0) - (a.incoming_unique ?? 0))
        let sortedOutGoing = overViewList.sort((a,b) => (b.outgoing_unique ?? 0) - (a.outgoing_unique ?? 0))

        performerObj.top_performer_incoming = sortedIncoming.slice(0,2)

        performerObj.top_performer_outgoing = sortedOutGoing.slice(0,2)

        performerObj.need_attention_incoming = sortedIncoming.slice(-2)

        performerObj.need_attention_outgoing = sortedOutGoing.slice(-2)

      
        return performerObj
    }
)

/**
 *  top performer and need attention on the basis of new .
 */
 export const selectNewPerformanceData = createSelector(
    [selectNewCallsOverViewAws],
    (overViewCallList) =>  {
        let overViewList = [...overViewCallList]
        let performerObj = {
            top_performer_incoming: [],
            top_performer_outgoing: [],
            need_attention_incoming: [],
            need_attention_outgoing: [],
        }

        let sortedIncoming = overViewList.sort((a,b) =>  (b.incoming ?? 0) - (a.incoming ?? 0))
        let sortedOutGoing = overViewList.sort((a,b) => (b.outgoing ?? 0) - (a.outgoing ?? 0))

        performerObj.top_performer_incoming = sortedIncoming.slice(0,2)

        performerObj.top_performer_outgoing = sortedOutGoing.slice(0,2)

        performerObj.need_attention_incoming = sortedIncoming.slice(-2)

        performerObj.need_attention_outgoing = sortedOutGoing.slice(-2)

      
        return performerObj
    }
)

export const selectActiveHours = createSelector(
    [dashboard],
    dashboard => {  
        let map = new Map();

        if(dashboard.activeHours) {
            let buckets = dashboard.activeHours.aggregations[2].buckets;

        buckets.forEach((it) => {
          let item = it["3"]
          let time = getHours(it.key);
          if (time >= 8 && time <= 20) {
            if (!map.has(time)) {
              map.set(time, {
                total: it.doc_count,
                incoming: getDirectionalValueFromArray(1,item.buckets),
                outgoing: getDirectionalValueFromArray(2,item.buckets),
                missed: getDirectionalValueFromArray(3,item.buckets),
                rejected: getDirectionalValueFromArray(5,item.buckets),
              });
            } else {
              let mapItem = map.get(time);
              map.set(time, {
                ...mapItem,
                total: mapItem.total + it.doc_count,
                incoming: mapItem.incoming + getDirectionalValueFromArray(1,item.buckets),
                outgoing: mapItem.outgoing + getDirectionalValueFromArray(2,item.buckets),
                missed: mapItem.missed + getDirectionalValueFromArray(3,item.buckets),
                rejected: mapItem.rejected + getDirectionalValueFromArray(5,item.buckets),
              });
            }
          }
        });

        if (map.size) {
          map = new Map([...map].sort((a, b) => a[0] - b[0]));
        }
        }

        return map;
    }
)

const getDirectionalValueFromArray = (directionKey,list) => {
    try{
        let findItem = list.find(item => item.key === directionKey.toString());

        if(!findItem) return 0;

        return findItem.doc_count

    } catch(e) {
        console.error("Failed to get directional values from arrray",e)
    }
}

export const selectActiveHoursChat = createSelector(
    [dashboard],
    dashboard => dashboard.activeHoursChat
)

export const selectCallLogsList = createSelector(
    [dashboard,selectTeamsListData],
    (dashboard,teamList) => {
        let callsList = dashboard.callLogsList

        if(callsList && callsList.length) {
            callsList = callsList.map(item => {
                let teamItem = teamList.find(it => it.user_id === parseInt(item.Userid))

                return {
                    ...item,
                    ...(teamItem && teamItem.whatzapp_basic_info ? {user : teamItem.whatzapp_basic_info} : {}),
                    ...(teamItem && teamItem.callyzer_orgamnization_team ? {team : teamItem.callyzer_orgamnization_team} : {}),
                }

            })
        }
        
        return callsList;
    }
    )

export const selectNextTokenForCallLogs = createSelector(
    [dashboard],
    dashboard => dashboard.nextTokenCallLogs)


export const selectEmployeeCallLogsList = createSelector(
        [dashboard,selectTeamsListData],
        (dashboard,teamList) => {
            let callsList = dashboard.employeeCallLogs
    
            if(callsList && callsList.length) {
                callsList = callsList.map(item => {
                    let teamItem = teamList.find(it => it.user_id === parseInt(item.Userid))
    
                    return {
                        ...item,
                        ...(teamItem && teamItem.whatzapp_basic_info ? {user : teamItem.whatzapp_basic_info} : {}),
                        ...(teamItem && teamItem.callyzer_orgamnization_team ? {team : teamItem.callyzer_orgamnization_team} : {}),
                    }
    
                })
            }
            
            return callsList;
        }
        )

        export const selectEmployeeNextTokenForCallLogs = createSelector(
            [dashboard],
            dashboard => dashboard.employeeNextTokenCallLogs)