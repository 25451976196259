import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// users
import { logoutUser } from "../../redux/auth/auth.action"
import PersonIcon from '@mui/icons-material/Person';
import { createStructuredSelector } from "reselect"
import { selectUserOrgList } from "../../redux/organization/organization.selector"

const ProfileMenu = ({userOrgList,...props}) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [activeOrganization, setactiveOrganization] = useState()

  useEffect(() => {
      if(userOrgList && userOrgList.length) {
        let active = userOrgList?.find(it => it.default_org === 1)
      setactiveOrganization(active)
      }
    },[userOrgList])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])



  const handleLogOut = () => {
    try {
      localStorage.removeItem("currentOrgCode")
      localStorage.removeItem("currentOrgId")
      localStorage.removeItem("userMobile")
      localStorage.removeItem("currentOrgName")
      localStorage.removeItem("workspaceId")
      props.logoutUser()
    }
    catch(err) {
      console.log("Error while logging out", err)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user mr-1">
          <PersonIcon />
          </div>
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <FontAwesomeIcon className="ml-1" icon={faChevronDown} size="xs"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        {
          activeOrganization ? (
        <span className="dropdown-item">
          {activeOrganization.callyzer_organization.org_name}
        </span>
          ) : null
        }
          
          <Link to="/logout" 
          onClick={handleLogOut}
          className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{"Logout"}</span>
          </Link>
          <div className="dropdown-divider"/>
         
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}



const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser())
})


export default connect(null,mapDispatchToProps)(ProfileMenu)
