import { endOfMonth, lastDayOfYear, startOfMonth, startOfYear, subMonths, subYears } from "date-fns"
import { DateTypes } from "../../util/helper"
import LayoutTypes from "./layout.types"

export const toggleLeftmenu = (isOpen) => {
    return dispatch => {

        changeLeftSidebarType(isOpen)
    
        dispatch({
            type: LayoutTypes.TOGGLE_LEFTMENU
        })  

  }
}
/**
 * 
 * @param {*} startDate 
 * @param {*} endDate 
 */
export const updateFilterDates = (startDate,endDate) => {
  return dispatch => {
    dispatch({
      type: LayoutTypes.UPDATE_FILTER_DATES,
      startDate: startDate,
      endDate: endDate
    })
    
  }
}

export const updateDateType = (startDate, endDate) => {
 return dispatch => {
  console.log(startDate, endDate)
  // let first = null;
  // let last = null;
 

  // switch (dateType) {
  //   case DateTypes.TODAY:
  //   startDate = new Date().toJSON().split("T")[0]
  //   endDate = startDate
  //   break;

  //   case DateTypes.YESTERDAY:
  //       let yesterday = new Date()
  //       yesterday.setTime(yesterday.getTime() - (1 * 24 * 60 * 60 * 1000))
  //       startDate = yesterday.toJSON().split("T")[0]
  //       endDate = startDate
  //     break;

  //     case DateTypes.THIS_WEEK: 
  //     let thisWeek = new Date()
  //      first = thisWeek.getDate() - thisWeek.getDay(); // First day is the day of the month - the day of the week
  //      last = first + 6; // last day is the first day + 6

  //       startDate = new Date(thisWeek.setDate(first)).toJSON().split("T")[0]
  //       endDate = new Date().toJSON().split("T")[0]

  //     break;

  //     case DateTypes.LAST_WEEK:
  //       let lastWeek = new Date()
  //        first = lastWeek.getDate() - lastWeek.getDay(); // First day is the day of the month - the day of the week
  //        last = first + 6; // last day is the first day + 6

  //       startDate = new Date(lastWeek.setDate(first - 7)).toJSON().split("T")[0]
  //       endDate = new Date(lastWeek.setDate(last - 7)).toJSON().split("T")[0]
  //       break;


  //     case DateTypes.THIS_MONTH:
  //       startDate =  startOfMonth(new Date()).toJSON().split("T")[0]
  //       endDate = new Date().toJSON().split("T")[0]
  //       break;

  //     case DateTypes.LAST_MONTH:
  //       let lastMonth = new Date()
  //       lastMonth.setMonth(lastMonth.getMonth() - 1)

  //       startDate =  startOfMonth(lastMonth).toJSON().split("T")[0]
  //       endDate = endOfMonth(lastMonth).toJSON().split("T")[0]
  //       break;
     
  //     case DateTypes.LAST_6_MONTHS:
  //     let last6Months = subMonths(new Date(),6)

  //       startDate =  startOfMonth(last6Months).toJSON().split("T")[0]
  //       endDate = new Date().toJSON().split("T")[0]
      
  //     break;

  //     case DateTypes.THIS_YEAR:
  //       let thisYear = startOfYear(new Date())

  //       startDate =  thisYear.toJSON().split("T")[0]
  //       endDate = new Date().toJSON().split("T")[0]

  //     break;

  //     case DateTypes.LAST_YEAR:
  //       let lastYear = subYears(new Date(),1)

  //       startDate =  startOfYear(lastYear).toJSON().split("T")[0]
  //       endDate = lastDayOfYear(lastYear).toJSON().split("T")[0]
  //     break;

  //   default:
  //     break;
  // }

  dispatch({type: LayoutTypes.UPDATE_DATE_TYPES,
    startDate: `${startDate} 00:00:00`,
    endDate : `${endDate} 23:59:59`
  })
 }
}

/**
 * 
 * @param {*} employee selected employee
 * @returns updates the employee in central storage
 */
export const updateSelectedEmployee = (employee) => {
  return dispatch => {
    dispatch({
      type: LayoutTypes.UPDATE_SELECTED_EMPLOYEE,
      employee: employee
    })
  }
}
/**
 * 
 * @param {*} employee selected employee
 * @returns updates the employee in central storage
 */
export const updateSelectedEmployeeConvo = (employee) => {
  return dispatch => {
    dispatch({
      type: LayoutTypes.UPDATE_SELECTED_EMPLOYEE_CONVO,
      employee: employee
    })
  }
}

/**
 * 
 * @param {*} employee selected employee
 * @returns updates the employee in central storage
 */
 export const updateSelectedTeam = (team,name) => {
  return dispatch => {
    dispatch({
      type: LayoutTypes.UPDATE_SELECTED_TEAM,
      team: team,
      name: name
    })
  }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
 const changeLeftSidebarType = (isOpen) => {
     console.log('is open', isOpen)
    try {
        if (isOpen) {
            changeBodyAttribute("data-sidebar-size", "")
            changeBodyAttribute("data-keep-enlarged", "true")
            manageBodyClass("vertical-collpsed", "add")
            manageBodyClass("sidebar-enable", "add")
        } else {
            manageBodyClass("sidebar-enable", "remove")
            manageBodyClass("vertical-collpsed", "remove")
        }
    } catch (error) {}
  }

  /**
 * Toggle the class on body
 * @param {*} cssClass
 */
const manageBodyClass = (cssClass, action = "toggle") => {
    switch (action) {
      case "add":
        if (document.body) document.body.classList.add(cssClass)
        break
      case "remove":
        if (document.body) document.body.classList.remove(cssClass)
        break
      default:
        if (document.body) document.body.classList.toggle(cssClass)
        break
    }
  
    return true
  }

  /**
 * Changes the body attribute
 */
const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value)
    return true
  }

 export const setCurrentNavigation = route => dispatch =>  dispatch({type: LayoutTypes.UPDATE_CURRENT_NAVIGATION,payload: route})