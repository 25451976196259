import React from 'react'
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';


const ConfirmationModal = ({open, handleClose, title, message,onClickOk, okText,onClickCancel, cancelText}) => {
  return (
    <Modal open={open} onClose={onClickCancel}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {
        message && 
        <Modal.Body>
        {message}
    </Modal.Body>
    }
    <Modal.Footer>
      <Button onClick={onClickOk} appearance="primary">
        {okText ?? "ok"}
      </Button>
      <Button onClick={onClickCancel} appearance="primary">
        {cancelText ?? "cancel"}
      </Button>
      
    </Modal.Footer>
  </Modal>
  )
}

export default ConfirmationModal