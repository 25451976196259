import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { createStructuredSelector } from "reselect";
import HorizontalLayout from "../components/HorizontalLayout";
import Navbar from "../components/HorizontalLayout/Navbar";
import { selectIsAuth } from "../redux/auth/auth.selector";
import { setCurrentNavigation } from "../redux/layout/layout.action";

const AuthMiddleware = ({
    component: Component,
  layout: Layout,
  setCurrentNavigation,
  isAuth,
  ...rest
}) => {

    const navigation = useHistory()

    useEffect(() => {
        if(navigation.location.pathname){
            setCurrentNavigation(navigation.location.pathname)
        }
    },[navigation.location.pathname])

    return (
       <>
       {
        isAuth ? 
        (   
                  <HorizontalLayout >
            <Component/>
            </HorizontalLayout>
        ) : <Redirect to="/workspace/signup" />
       }
       </>
    )
}

const mapStateToProps = createStructuredSelector({
isAuth: selectIsAuth
})

const mapDispatchToProps = dispatch => ({
    setCurrentNavigation: (route) => dispatch(setCurrentNavigation(route)),
  })

export default connect(mapStateToProps,mapDispatchToProps)(AuthMiddleware);