import LabelsTypes from "./labels.types";

const INITIAL_STATE = {
  allotedLabelsData: [],
}

const labelsReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LabelsTypes.GET_ALLOTED_LABELS_SUCCESS:
      return {
        ...state,
        allotedLabelsData: action.payload
      }

   

    default:
      return state;
  }
}

export default labelsReducer