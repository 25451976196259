import React, { useEffect, useState } from "react";
import "./integrations.scss";
import fbIcon from "../../assets/icons/Integrations/fb.svg";
import googleSheetIcon from "../../assets/icons/Integrations/google-sheet.svg";
import hubspotIcon from "../../assets/icons/hubspot-logo.png";
import bitrixIcon from "../../assets/icons/bitrix-icon.png";
import freshdeskIcon from "../../assets/icons/freshdesk-icon.png";
import zohoIcon from "../../assets/icons/zoho-icon.png";
import webhookIcon from "../../assets/icons/webhook-icon.png";
import salesforceIcon from "../../assets/icons/salesforce-logo.svg";
import leadsquaredIcon from "../../assets/icons/leadsquared-logo.svg";
import CRMIntegration from "./CRMIntegration/CRMIntegration";
import IntegrationActiveSection from "./IntegrationActiveSection/IntegrationActiveSection";
import { selectUserDataV2, selectUserExistenceData } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { checkUserExistence } from "../../redux/auth/auth.action";
import UpgradePlan from "../../components/Popups/UpgradePlan/UpgradePlan";
import MyAccountDropdown from "../../components/MyAccountDropdown/MyAccountDropdown";
import { EXTENSION_ID_PRODUCTION } from "../../util/api";
import { useTranslation } from "react-i18next";
import { getExtensionIdToSendMessageToFromLocal, getUserRoleId, setExtensionIdToSendMessageToInLocal } from "../../util/helper";

let IntegrationActiveSections = {
  GOOGLE_SHEET_CRM_SECTION: "GOOGLE_SHEET_CRM_SECTION",
  HUBSPOT_CRM_SECTION: "HUBSPOT_CRM_SECTION",
  BITRIX_CRM_SECTION: "BITRIX_CRM_SECTION",
  FRESHDESK_CRM_SECTION: "FRESHDESK_CRM_SECTION",
  ZOHO_CRM_SECTION: "ZOHO_CRM_SECTION",
  FACEBOOK_LEADS_INTEGRATION_SECTION: "FACEBOOK_LEADS_INTEGRATION_SECTION",
  CUSTOM_CRM_INTEGRATION_SECTION: "CUSTOM_CRM_INTEGRATION_SECTION",
  WEBHOOK_CRM_SECTION: "WEBHOOK_CRM_SECTION",
  SALESFORCE_CRM_SECTION: "SALESFORCE_CRM_SECTION",
  LEADSQUARED_CRM_SECTION: "LEADSQUARED_CRM_SECTION",
};


/**
 * @module Integrations
 */
/**
 * Integrations Module
 * @namespace Integrations
 */

/**
 * In this component we have all our integrations like google sheet integration,
 * facebook integration etc. We will integrate from these sources to bring
 * leads to our Workspace and finally show them in the CRM section
 * @returns Integration Component
 */

/**
 * We have three sub-components in this component:
 * 1.LeadIntegration: All our leads integration sources will be shown in this component
 * 2.CRMIntegration: All our CRM Integrations will be shown in this component
 * 3.IntegrationActiveSection: After clicking on any of the integration, this component will
 * be rendered as main section
 */

const Integrations = ({ userExistenceData, checkIfUserExists, userDataV2 }) => {
  const {t} = useTranslation()
  let dummyIntegrationsData = [
    {
      title: "Google Sheet",
      details: t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#B2E0CA",
      logo: googleSheetIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.GOOGLE_SHEET_CRM_SECTION,
      dataKey: "googleSheet",
      accessLevel: "admin",
    },
    {
      title: "Hubspot",
      details: t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: hubspotIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.HUBSPOT_CRM_SECTION,
      dataKey: "hubspot",
      accessLevel: "all",
    },
    {
      title: "Freshdesk",
      details: t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: freshdeskIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.FRESHDESK_CRM_SECTION,
      dataKey: "freshdesk",
      accessLevel: "all",
    },
    {
      title: "Zoho",
      details: t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: zohoIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.ZOHO_CRM_SECTION,
      dataKey: "zoho",
      accessLevel: "all",
    },
    {
      title: "Salesforce",
      details:
        t("Manage_Your.message")+" Salesforce "+ t("CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: salesforceIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.SALESFORCE_CRM_SECTION,
      dataKey: "salesforce",
      accessLevel: "all",
    },
    {
      title: "Leadsquared",
      details:
        t("Manage_Your.message")+" Leadsquared "+ t("CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: leadsquaredIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.LEADSQUARED_CRM_SECTION,
      dataKey: "leadsquared",
      accessLevel: "all",
    },
    {
      title: "Bitrix",
      details:
      t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: bitrixIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.BITRIX_CRM_SECTION,
      dataKey: "bitrix",
      accessLevel: "all",
    },
    {
      title: "Webhook",
      details: t("Manage_your_CRM_directly_from_eazybe_workspace_and_whatsapp.message"),
      bgColor: "#EAF3FC",
      logo: webhookIcon,
      type: "CRM Integration",
      activeSection: IntegrationActiveSections.WEBHOOK_CRM_SECTION,
      dataKey: "webhooks",
      accessLevel: "all",
    },
    // {
    //   title: "Custom",
    //   details: "Manage you Business CRM directly from eazybe workspace and whatsapp",
    //   bgColor: "#EAF3FC",
    //   logo: hubspotIcon,
    //   type: "CRM Integration",
    //   activeSection : IntegrationActiveSections.CUSTOM_CRM_INTEGRATION_SECTION,
    //   dataKey : "customIntegration",
    // accessLevel : "all",
  
    // },
  ];
  const [connectHubspot, setConnectHubspot] = useState(false);
  const [connectIntegration, setConnectIntegration] = useState(false);
  const [integrationsData, setIntegrationsData] = useState({});
  const [crmIntegrationData, setCrmIntegrationData] = useState(
    dummyIntegrationsData
  );
  const [extensionIdToSendMessageTo, setExtensionIdToSendMessageTo] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [userRoleId, setUserRoleId] =useState(getUserRoleId());
  useEffect(() => {
    console.log("Integrations component mounted");
    checkIfUserExists(localStorage.getItem("userEmail"));
  }, []);

  useEffect(() => {
    console.log("userExistenceData in integrations", {userExistenceData, userDataV2});
    let tempIntegrationsData = userExistenceData?.data?.integrations;
    setIntegrationsData(tempIntegrationsData);

    if (userExistenceData?.data) {
      let tempData = [];
      crmIntegrationData.forEach((item) => {
        let isConnected
        if(item.dataKey === "salesforce"){
          isConnected = userDataV2?.integrations?.salesforce
        }
       else if(item.dataKey === "leadsquared"){
          isConnected = userDataV2?.integrations?.leadsquared
        }
        else {
          isConnected = tempIntegrationsData[`${item?.dataKey}`];

        }
        tempData.push({
          ...item,
          isConnected: isConnected ?? false,
        });
      });
      console.log("tempData", tempData);
      setCrmIntegrationData([...tempData]);
    }
  }, [userExistenceData, userDataV2]);

  useEffect(() => {
    initializeExtensionIdToSendMessageTo();
    // setTimeout(()=> {
    const queryParams = new URLSearchParams(window.location.search);

    console.log("queryParams", queryParams);

    let pathParams = JSON.parse(localStorage.getItem("pathParams"));

    const queryParamsObject = {};

    queryParams.forEach((value, key) => {
      queryParamsObject[key] = value;
    });

    console.log("queryParamsObject", queryParamsObject);


    const tempExtensionId =
      queryParams?.get("extensionId");
      if(tempExtensionId){
        setExtensionIdToSendMessageTo(tempExtensionId);
        setExtensionIdToSendMessageToInLocal(tempExtensionId);
      }

    // let tempSection  = queryParams?.get("section");
    // let tempSection  = pathParams?.action  ;
    // if(tempSection){
    //   setConnectHubspot(true)
    // }
    // else {
    //   setConnectHubspot(false)

    // }
    let tempAction = queryParams?.get("action")?.includes("connect") ?? false;
    let tempSection2 = queryParams?.get("section");
    console.log("tempSection2", tempSection2);
    console.log("queryParams: ", queryParams);
    // console.log("window.location.search : ",  window.location.search);
    // console.log("tempSection :", tempSection);
    console.log("tempAction :", tempAction);
    if (tempAction) {
      setConnectIntegration(true);
    } else {
      setConnectIntegration(false);
    }
    if (tempSection2) {
      switchComponent(tempSection2);
    } else if (tempSection2) {
      switchComponent(tempSection2);
    }
    // }, 500)
  }, []);


  const initializeExtensionIdToSendMessageTo = async ()=> {
    const tempExtensionIdToSendMessage = getExtensionIdToSendMessageToFromLocal();
    console.log("initializeExtensionIdToSendMessageTo tempExtensionIdToSendMessage",tempExtensionIdToSendMessage )
    if(tempExtensionIdToSendMessage?.length){
      setExtensionIdToSendMessageTo(tempExtensionIdToSendMessage);    }
  }

  const switchComponent = (sectionToSwitch) => {
    console.log("Switch component entered", sectionToSwitch);
    switch (sectionToSwitch) {
      case "hubspot":
      case "connectHubspot":
        setMainActiveSection(IntegrationActiveSections.HUBSPOT_CRM_SECTION);
        break;
      case "googleSheet":
        setMainActiveSection(
          IntegrationActiveSections.GOOGLE_SHEET_CRM_SECTION
        );
        break;
      case "bitrix":
        setMainActiveSection(IntegrationActiveSections.BITRIX_CRM_SECTION);
        break;
      case "freshdesk":
        setMainActiveSection(IntegrationActiveSections.FRESHDESK_CRM_SECTION);
        break;
      case "zoho":
        setMainActiveSection(IntegrationActiveSections.ZOHO_CRM_SECTION);
        break;
      case "webhooks":
        setMainActiveSection(IntegrationActiveSections.WEBHOOK_CRM_SECTION);
        break;
      case "salesforce":
        setMainActiveSection(IntegrationActiveSections.SALESFORCE_CRM_SECTION);
        break;
      case "leadsquared":
        setMainActiveSection(IntegrationActiveSections.LEADSQUARED_CRM_SECTION);
        break;
    }
  };

  const setMainSectionIntegrations = () => {
    setMainActiveSection(0);
  };

  const dummyLeadsIntegrationData = [
    {
      title: "Facebook Leads",
      details:
        "Fetch your leads generated via Facebook campaigns automatically to whatsapp",
      bgColor: "#CDE2FD",
      logo: fbIcon,
      type: "Integration",
      activeSection:
        IntegrationActiveSections.FACEBOOK_LEADS_INTEGRATION_SECTION,
    },
  ];

  /**
   * With the help of this mainActiveSection variable, we will be changing
   * our active screen in the Integration section.
   */

  const [mainActiveSection, setMainActiveSection] = useState(0);

  return (
    <div className="integrations-container pt-1">
      <div className="header-container d-flex-row">
        <h3 className="header">Integrations</h3>
        <MyAccountDropdown setIsOpen={setIsOpen} />
      </div>
      {mainActiveSection == 0 ? (
        <>
          <CRMIntegration
            crmIntegrationData={crmIntegrationData}
            setMainActiveSection={setMainActiveSection}
            integrationsData={integrationsData}
          />
        </>
      ) : (
        <IntegrationActiveSection
          mainActiveSection={mainActiveSection}
          setMainActiveSection={setMainActiveSection}
          IntegrationActiveSections={IntegrationActiveSections}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
          userRoleId={userRoleId}
        />
      )}
      <UpgradePlan route="/integrations" />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userExistenceData: selectUserExistenceData,
  userDataV2:  selectUserDataV2,
  // removedEmployeeData: selectRemovedUserData
});

const mapDispatchToProps = (dispatch) => ({
  checkIfUserExists: (userEmail) => dispatch(checkUserExistence(userEmail)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
