import React, { useState } from 'react';
import styles from './styles.module.scss';
import DynamicAssignment from '../dynamic-assignment';
import { DashboardOutlined, SupervisedUserCircle } from '@material-ui/icons';
import { AdminPanelSettings } from '@mui/icons-material';

const SecureDashboard = () => {
  const [activeScreen, setActiveScreen] = useState('dashboard');

  return (
    <div className={styles.main_container}>
      <div className={styles.main_content_viewer}>
        <header>
          <h2>Dashboard</h2>
        </header>
        {activeScreen == 'dashboard' ? <DynamicAssignment /> : null}
      </div>
      <div className={styles.sidebar}>
        <div className={styles.dashboard}>
          <DashboardOutlined className={styles.icon} />
          <p>Dashboard</p>
        </div>
        <div className={styles.dashboard}>
          <SupervisedUserCircle className={styles.icon} />
          <p>User Info</p>
        </div>
        <div className={styles.dashboard}>
          <AdminPanelSettings className={styles.icon} />
          <p>Admin Panel</p>
        </div>
      </div>
    </div>
  )
}

export default SecureDashboard