import React, { useEffect } from 'react';
import './freshdesknotconnected.scss';
import freshdeskIconImg from '../../../../../assets/icons/freshdesk-icon.png';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import AlertModal from '../../../../../components/eazybe-components/AlertModal/AlertModal';
import { useState } from 'react';
import ConfirmationModal from '../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal';
import showToast from '../../../../../components/Toasts/ShowToast';
import freshdesk1 from '../../../../../assets/images/freshdesk-1.png';
import freshdesk2 from '../../../../../assets/images/freshdesk-2.png';
import freshdesk3 from '../../../../../assets/images/freshdesk-3.png';
import { useTranslation } from 'react-i18next';

const FreshdeskNotConnected = ({ onClickConnect, freshdeskConnected, setFreshdeskConnected, sendMessageToChromeExtension }) => {
    const [unlinkModalActive, setUnlinkModalActive] = useState(false);
    const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
    const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);
    const { t } = useTranslation()

    const setUpGuideSteps = [
        {
            SrNo: 'Step 1: ',
            title: '  Login to your Freshdesk account.',
            isImg: true,
            img: freshdesk1
        },
        {
            SrNo: 'Step 2: ',
            title: '  Domain/Helpdesk name can be obtained from the URL as shown below.',
            isImg: false,
            img: null
        },
        {
            SrNo: 'Step 3: ',
            title: '  Navigate to the top right corner and click on the My Account icon.',
            isImg: false,
            img: null
        },
        {
            SrNo: 'Step 4: ',
            title: '  Open the profile settings page.',
            isImg: true,
            img: freshdesk2
        },
        {
            SrNo: 'Step 5: ',
            title: '  Obtain the API key by completing the CAPTCHA check as shown.',
            isImg: true,
            img: freshdesk3
        },
    ]

    useEffect(() => {
        console.log("freshdeskConnected changed", freshdeskConnected);
    }, [freshdeskConnected])



    let featuresArr = [
        t("Create_New_Contacts.message"),
        t("Link_Existing_Contacts.message"),
        t("Update_Contact_Information.message"),
        t("Create_Tasks.message"),
        t("Create_Notes.message")
    ]

    const goBackToWhatsapp = () => {
        if (sendMessageToChromeExtension) {
            sendMessageToChromeExtension();
        }
        else {
            window.open('https://web.whatsapp.com/');
        }
    }


    const onClickUnlinkFreshdesk = () => {
        setUnlinkModalActiveTrue();
    }

    const unlinkFreshdesk = () => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
        };
        fetch(
            `https://eazybe.com/api/v1/whatzapp/freshdesk/deleteauth?workspace_id=${localStorage.getItem(
                "workspaceId"
            )}`, requestOptions
        )
            .then((res) => res.json())
            .then((response) => {
                //   console.log("response : ", response);
                if (response?.result) {
                    // sendMessageToChromeExtension(true, 500);
                    showToast("Unlinked Freshdesk successfully");
                    setFreshdeskConnected(false);
                    setUnlinkModalActiveFalse();

                } else {
                    showToast("Something went wrong");
                    setUnlinkModalActiveFalse();

                }
            });
    }



    return (
        <>
            <div className='freshdesk-not-connected-con d-flex d-flex-col'>
                <div className='header-con d-flex'>
                    <img src={freshdeskIconImg} className="hubspot-icon-big" />
                    <div className='connection-status d-flex d-flex-col'>
                        <div className='name-con d-flex'>
                            <h3>Freshdesk</h3>

                            {
                                freshdeskConnected ?
                                    <>
                                        <div className='d-flex align-items-center'>
                                            <DoneIcon htmlColor='green' fontSize="24px" />
                                            <p className='ml-0-5'>{t("Connected.message")}</p>
                                        </div>
                                    </>

                                    :
                                    <div className='d-flex align-items-center'>
                                        <ClearIcon htmlColor='red' fontSize="24px" />
                                        <p className='ml-0-5'>
                                            {t("Not_Connected.message")}
                                        </p>
                                    </div>
                            }

                        </div>
                        <p>
                        {t("Link_Contacts_Create_Contacts_Create_Tasks.message")}
                        </p>
                    </div>

                    {
                        freshdeskConnected ? <div>
                            <button onClick={goBackToWhatsapp} className='connect-hubspot-btn'>Go back to WhatsApp</button>
                            <button onClick={onClickUnlinkFreshdesk} className='unlink-hubspot-btn'>
                                {t("Unlink.message")}
                            </button>
                        </div>
                            :
                            <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Freshdesk</button>
                    }

                    {/* <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Hubspot</button> */}

                </div>
                <hr />
                <div className='info-con'>
                    <div className='freshdesk-features'>
                        <h5>
                            {t("Features.message")}
                        </h5>
                        <div className='features'>
                            {
                                featuresArr.map((feature) => (
                                    <div className='feature-item d-flex'>
                                        <img className='feature-img' src={freshdeskIconImg} />
                                        <p className='feature-text'>{feature}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='freshdesk-setup-guide-container'>
                        <div className='freshdesk-setup-guide default-scroll-bar'>
                            <h5>Setup Guide</h5>

                            {
                                setUpGuideSteps.map(step => <>
                                    <p className='freshdesk-setup-step'><span className='freshdesk-setup-step-title'>{step.SrNo}</span>{step.title}</p>

                                    {step.isImg && <img className='freshdesk-guide-img' src={step.img} alt='guide' />}
                                </>)
                            }

                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={unlinkModalActive}
                handleClose={setUnlinkModalActiveFalse}
                onClickOk={unlinkFreshdesk}
                onClickCancel={setUnlinkModalActiveFalse}
                title="Unlink Freshdesk"
                message={t("Are_you_sure_you_want_to_unlink.message") + "Freshdesk"}
                okText={t("Unlink.message")}
            />
        </>

    )
}

export default FreshdeskNotConnected