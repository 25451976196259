/*global chrome*/
import axios from "axios";
import React, { useEffect, useState } from "react";

import "./freshdeskintegrationpage.scss";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import FreshdeskNotConnected from "./FreshdeskNotConnected/FreshdeskNotConnected";
import FreshdeskModal from "./FreshdeskModal/FreshdeskModal";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";


let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
let EXTENSION_ID_TEST = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
let REDIRECT_URI = "https://workspace.eazybe.com/integrations?section=hubspot";

const FreshdeskIntegrationPage = ({ setMainSectionIntegrations, connectIntegration,extensionIdToSendMessageTo, saveDontShowUpgradeStatus }) => {
  let FRESHDESK_SECTION_IDS = {
    ALREADY_CONNECTED: "ALREADY_CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    GO_BACK_TO_EXTENSION: "GO_BACK_TO_EXTENSION",
    HUBSPOT_PAGE1: "HUBSPOT_PAGE1",
  };

  const [activeSectionHubspot, setActiveSectionHubspot] = useState(
    FRESHDESK_SECTION_IDS.NOT_CONNECTED
  );
  // const [activeSectionHubspot, setActiveSectionHubspot] = useState(FRESHDESK_SECTION_IDS.ALREADY_CONNECTED);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in hubspot section", queryParams);
    let tempCode = queryParams?.get("code");
    console.log("tempCode : ", tempCode);
    checkIfFreshdeskIntegrated();
    console.log("connectIntegration in freshdeskIntegration", connectIntegration);
    /// Redirect to redirect_uri 
    if(connectIntegration) {
      connectFreshdesk();
    }
  }, [connectIntegration]);

  const checkIfFreshdeskIntegrated = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/freshdesk/getauth?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("response : ", response);
        if (response.result) {
          setFreshdeskConnected(true);
          saveDontShowUpgradeStatus(true)
          // sendMessageToChromeExtension(true, 500);setFreshdeskConnected
          setActiveSectionHubspot(FRESHDESK_SECTION_IDS.ALREADY_CONNECTED);
        } else {
          setFreshdeskConnected(false);
          // sendMessageToChromeExtension(false, 500);
          setActiveSectionHubspot(FRESHDESK_SECTION_IDS.NOT_CONNECTED);

        }
      });
  };

  const sendMessageToChromeExtension = (status, time = 10, id="clgficggccelgifppbcaepjdkklfcefd", key) => {
    console.log("sendMessageToChromeExtension called")
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
           EXTENSION_ID_PRODUCTION,
          { key : key ?? "FRESHDESK_CONNECTED" },
          (response) => {
            console.log("sendMessageToChromeExtension  response : ", response);
            if (!response?.success) {
            }

          }
        );

        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo,
          { key : key ?? "FRESHDESK_CONNECTED" },
          (response) => {
            console.log("sendMessageToChromeExtension  response : ", response);
            if (!response?.success) {
            }

          }
        );
      }
    }, time);
  };
  const [freshdeskConnected, setFreshdeskConnected] = useState(false);
  const [freshdeskModalActive, setFreshdeskModalActive] = useState(false);

  const enableFreshdeskModal =()=> setFreshdeskModalActive(true);
  const disableFreshdeskModal =()=> setFreshdeskModalActive(false);

  const connectFreshdesk =()=> {
    enableFreshdeskModal();
  }



  return (
    <div>
      <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mt-1 cursor-pointer"
      />
      <FreshdeskNotConnected
        freshdeskConnected={freshdeskConnected}
        setFreshdeskConnected={setFreshdeskConnected}
        onClickConnect={connectFreshdesk}
        checkIfBitrixConnected={checkIfFreshdeskIntegrated}
        sendMessageToChromeExtension={sendMessageToChromeExtension}
      />

      <FreshdeskModal
        open={freshdeskModalActive}
        handleClose={disableFreshdeskModal}
        setFreshdeskConnected={setFreshdeskConnected}
        checkIfFreshdeskIntegrated={checkIfFreshdeskIntegrated}
        sendMessageToChromeExtension={sendMessageToChromeExtension}

      />
    </div>
  );
};



const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreshdeskIntegrationPage);