/*global chrome*/
import axios from "axios";
import React, { useEffect, useState } from "react";

import "./bitrixintegrationpage.scss";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import BitrixNotConnected from "./BitrixNotConnected/BitrixNotConnected";
import BitrixModal from "./BitrixModal/BitrixModal";
import showToast from "../../../../components/Toasts/ShowToast";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import { checkForCrmiKeyInAuth } from "../../../../util/helper";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";

let REFRESH_TOKEN = "8c82e429-0530-420a-bd83-c746af0c12d8";
let CLIENT_ID = "app.6448e61fad4676.49982309";
let CLIENT_SECRET = "ERKhLdttfX7jfBsf6KOT1oIG0jUPK5Es3E4G3dqeZ5cSi1bIeg";
let REDIRECT_URI = "https://workspace.eazybe.com/integrations?section=bitrix";
let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
let EXTENSION_ID_TEST = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
let REDIRECT_URI_LOCALHOST = "https://localhost:4001/integrations?section=bitrix";

const BitrixIntegrationPage = ({ setMainSectionIntegrations, connectIntegration, extensionIdToSendMessageTo, saveDontShowUpgradeStatus }) => {
  let BITRIX_SECTION_IDS = {
    ALREADY_CONNECTED: "ALREADY_CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    GO_BACK_TO_EXTENSION: "GO_BACK_TO_EXTENSION",
    HUBSPOT_PAGE1: "HUBSPOT_PAGE1",
  };

  const [activeSectionHubspot, setActiveSectionHubspot] = useState(
    BITRIX_SECTION_IDS.NOT_CONNECTED
  );
  const [bitrixConnected, setBitrixConnected] = useState(false);
  const [bitrixModalActive, setBitrixModalActive] = useState(false);

  const enableBitrixModal =()=> setBitrixModalActive(true);
  const disableBitrixModal =()=> setBitrixModalActive(false);
  // const [activeSectionHubspot, setActiveSectionHubspot] = useState(BITRIX_SECTION_IDS.ALREADY_CONNECTED);
  const [showLoader, setShowLoader] = useState(true);
  let clientRedirectURL =
    `https://oauth.bitrix.info/oauth/authorize/?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in hubspot section", queryParams);
    let tempCode = queryParams?.get("code");
    console.log("tempCode : ", tempCode);
    if (tempCode) {
      getBearerToken(tempCode);
    } else {
      checkIfBitrixAlreadyConnected();
    }

    /// Redirect to redirect_uri 
    if(connectIntegration) {
      window.open(clientRedirectURL);
    }

  }, []);


  

  const onClickConnectBitrix = () => {
    window.open(clientRedirectURL);
  };
  

  const getBearerToken = (authCode) => {
    console.log("getBearerToken called : ", authCode);
    fetch(`https://eazybe.com/api/v1/whatzapp/bitrix/authentication?client_id=${CLIENT_ID}&code=${authCode}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code`, {
      method : 'GET',
    })
    .then(response => response.json())
    .then(res=> {
      if(res?.data){
        let tempAccessToken = res?.data?.access_token;
        let tempRefreshToken = res?.data?.refresh_token;
        let tempEndpoint = res?.data?.client_endpoint;
        let tempExpires = res?.data?.expires;
        if(tempRefreshToken && tempAccessToken && tempEndpoint){
          saveBitrixToken(tempAccessToken, tempRefreshToken, tempEndpoint, tempExpires);
        }
        else {
          showToast("Failed to authenticate, please try again")
        }
      }
      else {
        showToast("Failed to authenticate, please try again")

      }
      console.log("getBearerToken res", res);
    })

  };


  const saveBitrixToken =(tempAccessToken, tempRefreshToken, tempEndpoint, tempExpires)=> {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
    let url = `https://eazybe.com/api/v1/whatzapp/bitrix/createauth`;
    let body = {
      workspace_id: localStorage.getItem("workspaceId"),
      access_token: tempAccessToken,
      refresh_token: tempRefreshToken,
      client_endpoint : tempEndpoint,
      expires : tempExpires

  }

  fetch(url, {
    method : 'POST',
    body : JSON.stringify(body),
    headers: myHeaders,
  })
  .then(res=> res.json())
  .then(response=> {
    console.log("saveBitrixToken response", response);
    if(response?.result){
      window.gtag("event", "Bitrixintegrated");
        window.gtagAW("event", "Bitrixintegrated");
      sendMessageToChromeExtension(null, 500);
      checkForCrmiKeyInAuth();
      setBitrixConnected(true)
      saveDontShowUpgradeStatus(true);
      showToast("Successfully linked Bitrix")
    }
    else {
      showToast("Failed to authenticate, please try again")

    }
    console.log("Response after saving bitrix token", response)
  })
  }

  const checkIfBitrixAlreadyConnected = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/bitrix/getauth?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("response : ", response);
        if (response?.result) {
          // sendMessageToChromeExtension(null, 500);
          // sendMessageToChromeExtension(true, 500);
          setBitrixConnected(true);
          // setActiveSectionHubspot(BITRIX_SECTION_IDS.ALREADY_CONNECTED);
        } else {
          // sendMessageToChromeExtension(false, 500);
          setBitrixConnected(false);

          // setActiveSectionHubspot(BITRIX_SECTION_IDS.NOT_CONNECTED);
        }
      });
  };

  const sendMessageToChromeExtension = (status, time = 10, id="clgficggccelgifppbcaepjdkklfcefd", key) => {
    console.log("sendMessageToChromeExtension : ", {EXTENSION_ID_PRODUCTION});
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
           EXTENSION_ID_PRODUCTION,
          { key : key ?? "BITRIX_CONNECTED" },
          (response) => {
            console.log("sending msg to extension : ", {EXTENSION_ID_PRODUCTION, response});
            if (!response?.success) {
            }
          }
        );
        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo,
          { key : key ?? "BITRIX_CONNECTED" },
          (response) => {
            console.log("sending msg to extension : ", {EXTENSION_ID_PRODUCTION, response});
            if (!response?.success) {
            }
          }
        );
      }
    }, time);
  };
 

  const connectBitrix =()=> {
    enableBitrixModal();
  }

  const checkIfBitrixConnected =()=> {}


  return (
    <div>
      <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mt-1 cursor-pointer"
      />
      <BitrixNotConnected
        bitrixConnected={bitrixConnected}
        setBitrixConnected={setBitrixConnected}
        onClickConnect={onClickConnectBitrix}
        checkIfBitrixConnected={checkIfBitrixConnected}
        sendMessageToChromeExtension={sendMessageToChromeExtension}
      />

      <BitrixModal
        open={bitrixModalActive}
        handleClose={disableBitrixModal}
      />
    </div>
  );
};



const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BitrixIntegrationPage);