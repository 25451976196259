import { differenceInDays, differenceInMilliseconds } from "date-fns";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectOrgCreditHistory } from "../../redux/auth/auth.selector";
import { getFormattedDDMMYY } from "../../util/commonfunctions";
import TrialEndedStickyTopComp from "../Popups/TrialEndedStickyTopComp/TrialEndedStickyTopComp";
import Header from "./Header";
import Navbar from "./Navbar";
import { selectUserCreditHistory } from "../../redux/organization/organization.selector";
import { getDayDifference } from "../../util/helper";

const HorizontalLayout = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [content, setContent] = useState("Your 7-day trial has ended");

  function handleClose() {
    setOpenPopup(false);
  }

 
  useEffect(() => {
    // console.log("HorizontalLayout props", props);
  
    if (props.fetchedOrgCreditHistory?.data?.validity) {
      const currentDate = new Date();
      const validityDate = new Date(props.fetchedOrgCreditHistory.data.validity);
  
      // Get the difference in months between the current date and the validity date
      const daysDifference = getDayDifference(currentDate, validityDate);
  
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const isTrial = props.fetchedOrgCreditHistory.data.is_trial === 1;
      const daysLeft = Math.ceil((validityDate - currentDate) / millisecondsInADay);
  
      if (isTrial) {
        setOpenPopup(true);
  
        if (daysLeft >= 0) {
          setContent(`Your free trial will expire in ${daysLeft} days`);
        } else {
          setContent("Your free trial has ended");
        }
      } else {
        setContent("Your plan has expired. Renew it by clicking on the upgrade button.");
      }
  
      // Show the popup if the validity date has passed
      const shouldShowPopup =
        (daysDifference === 0 && validityDate.getDate() < currentDate.getDate()) ||
        daysDifference < 0;
  
      if (shouldShowPopup && localStorage.getItem("currentOrgId")) {
        setOpenPopup(true);
      }
  
      // console.log("HorizontalLayout daysDifference", { daysDifference });
    }
  }, [props.fetchedOrgCreditHistory, props.fetchedUserCreditHistoryData]);
  
  // console.log("Horizontal Layout rendered");

  return (
    <>
      {!window.location.pathname.startsWith("/settings/billing") ? (
        <TrialEndedStickyTopComp
          openPopup={openPopup}
          handleClose={handleClose}
          content={content}
          userPlanDetails={props.fetchedUserCreditHistoryData}
        />
      ) : null}
      <Navbar />
      {window.location.pathname !== "/conversations" &&
      window.location.pathname !== "/integrations" &&
      window.location.pathname !== "/leads" &&
      !window.location.pathname.startsWith("/organization") &&
      !window.location.pathname.startsWith("/settings") ? (
        // !window.location.pathname.startsWith('/quick-replies')  ? (
        // !window.location.pathname == '/quick-replies'  ? (

        <Header
          theme={() => {}}
          openPopup={openPopup}
          content={content}
          handleClose={handleClose}
        />
      ) : null}

      <div className="main-content ht-100vh">{props.children}</div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  fetchedUserCreditHistoryData: selectUserCreditHistory,
  fetchedOrgCreditHistory: selectOrgCreditHistory,
});

export default connect(mapStateToProps)(React.memo(HorizontalLayout));
