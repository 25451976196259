/**
 * @namespace Commonfunctions
 */

import { format } from "date-fns";
import showToast from "../components/Toasts/ShowToast";

// function to get initials of a name, for eg: Initials of Vipul Roy will be "VR".
export const getInitials = (name) => {
  try {
    if (!name) return "NA";
    // console.log("Original Name:", name);

    // Check the type of name
    if (typeof name !== 'string') {
      console.error("Name is not a string");
      return "NA";
    }

    // Log the intermediate results
    const initialsArray = name.match(/(\b\S)?/g);
    // console.log("Initials Array:", initialsArray);

    const initials = initialsArray.join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    // console.log("Final Initials:", initials);

    return initials;
  } catch (err) {
    console.error("Failed to get name initial", err);
    return "NA";
  }
};

// function to get only first and last name

export const getNameToShow = (name) => {
  try {
    if (!name || typeof name !== 'string') {
      throw new Error("Invalid name");
    }

    const nameArr = name.split(" ");
    const fullName = (nameArr.length > 1) ? [nameArr[0], nameArr[nameArr.length - 1]] : [name];

    return fullName;
  } catch (error) {
    console.error("Error in getNameToShow:", error.message);
    return [];
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * To get the initial of a name, for eg: Initials of Imran Md will be IM or Sid Pandey will be SP
 * @function getNameInitials
 * @memberof Commonfunctions
 * @returns Initials of a name
 */
export const getNameInitials = (name) => {
  if (!name) return "NA";
  let nameArr = name.trim().split(" ");
  let firstInitial = nameArr[0][0];
  let lastInitial = "";
  if (nameArr.length > 1) {
    lastInitial = nameArr[nameArr.length - 1][0];
  }
  let finalInitials = firstInitial + lastInitial;
  // console.log(`getNameInitials name : ${name}, finalInitials : ${finalInitials}`);
  return finalInitials !== "undefined" ? finalInitials : "NA";
};


/**
 * To get the first name initial of a name, for eg: Initials of Imran Md will be I or Sid Pandey will be S
 * @function getFirstNameInitial
 * @memberof Commonfunctions
 * @returns Initials of a name
 */
export const getFirstNameInitial = (name) => {
  let nameArr = name.split(" ");
  let firstInitial = nameArr[0][0];

  return firstInitial ? firstInitial : "!";
};

/**
 * To get convert the date and time to a viewable format for user
 * @function getFormattedDateAndTime
 * @memberof Commonfunctions
 * @param(String) DateTime
 * @returns formatted Date and time
 */

export const getFormattedDateAndTime = (tempDate) => {
  try {
    let date = new Date(tempDate);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    let day = date.getDate().toString().padStart(2, '0');
    let month = date.toLocaleString("default", { month: "short" });
    let year = date.getFullYear();
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let seconds = date.getSeconds().toString().padStart(2, '0');

    let formattedDateStr = `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateStr;
  } catch (error) {
    console.error("Error formatting date and time:", error);
    return null;
  }
};







/**
 * To convert the employee id into serial no type format from raw format
 * @function getFormattedEmployeeId
 * @memberof Commonfunctions
 * @param(String) EmployeeId
 * @returns formatted employee id in form of s.no.
 */

export const getFormattedEmployeeId = (employeeId) => {
  // console.log("employeeId", employeeId);
  if (employeeId) {
    let oldEmpId = employeeId;
    let newEmpId;
    let oldEmpIdArr = oldEmpId.split("-");
    newEmpId = oldEmpIdArr.splice(-1)[0];
    newEmpId = parseInt(newEmpId);
    return newEmpId;
  }

  return "-"

};



/**
 * To get small required length string instead of a very long string which causes UI irregularities
 * @function getFixLengthString
 * @memberof Commonfunctions
 * @param(String) String like "Hi there bro", "VerylonglonglongTeamName"
 * @param(number) length length of the final string
 * @returns formatted short string
 */

export const getFixLengthString = (string, length) => {
  let tempString = string;
  if (tempString?.length < length) {
    return tempString;
  }
  else if (tempString) {
    tempString = tempString.slice(0, length) + "...";
    return tempString
  }
  else return "No Team"
}

/// short- Jan, Feb, Mar, Apr .... long - default
export const monthNames = (format) => {
  if (format == "short") {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ]
  }
  else {
    return ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]
  }
};


/// Get formatted date and time

export const getFormattedDateTime = (dateTimeStr) => {
  let tempFollowupDateTimeRaw = new Date(dateTimeStr);
  // console.log(tempFollowupDateTimeRaw);
  let tempFollowupDate = tempFollowupDateTimeRaw.getDate()
  // setFollowupDate(tempFollowupDate);
  let tempFollowupMonth = monthNames("short")[tempFollowupDateTimeRaw.getMonth()]
  let tempFollowupTime = new Intl.DateTimeFormat('default',
    {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    }).format(tempFollowupDateTimeRaw);

  let tempFinalFollowupDateTime = tempFollowupDate + " " + tempFollowupMonth + " " + tempFollowupTime
  // console.log(tempFinalFollowupDateTime);
  return tempFinalFollowupDateTime


}

/// Get formatted date and year

export const getFormattedDDMMYY = (dateTimeStr) => {

  try {
    let tempFollowupDateTimeRaw = new Date(dateTimeStr);
    // console.log(tempFollowupDateTimeRaw);
    let tempFollowupDate = tempFollowupDateTimeRaw.getDate()
    // setFollowupDate(tempFollowupDate);
    let tempFollowupMonth = monthNames("short")[tempFollowupDateTimeRaw.getMonth()]
    let tempYear = tempFollowupDateTimeRaw.getYear().toString().slice(1)
    let tempFollowupTime = new Intl.DateTimeFormat('default',
      {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
      }).format(tempFollowupDateTimeRaw);

    let tempFinalFollowupDateTime = tempFollowupDate + " " + tempFollowupMonth + " " + tempYear;
    // console.log(tempFinalFollowupDateTime);
    return tempFinalFollowupDateTime
  }
  catch (err) {
    console.log("error ocurred in getFormattedDDMMYY function");
    return "No date"
  }


}


/// Get formatted time only

export const getFormattedTimeOnly = (dateTimeStr) => {
  // let tempFollowupDateTimeRaw = new Date(dateTimeStr);
  // let tempFollowupTime = new Intl.DateTimeFormat('default',
  //   {
  //     hour12: true,
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   }).format(tempFollowupDateTimeRaw);

  // // let tempFinalFollowupDateTime = tempFollowupDate + " " + tempFollowupMonth + " " + tempFollowupTime
  // return tempFollowupTime

  return format(dateTimeStr,"h:mm aaaa")
}



// To check if an url of a given image is actually returning an image

export const checkImageUrl = async (imgUrl) => {

  function testImage(url, timeoutT) {
    return new Promise(function (resolve, reject) {
      var timeout = timeoutT || 5000;
      var timer, img = new Image();
      img.onerror = img.onabort = function () {
        clearTimeout(timer);
        resolve(false);
      };
      img.onload = function () {
        clearTimeout(timer);
        resolve(true);
      };
      timer = setTimeout(function () {
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        resolve(false);
      }, timeout);
      img.src = url;
    });
  }

  let finalResult = await testImage(imgUrl);
  return finalResult;

}

export const USER_ROLE_TYPES ={
  ADMIN : "ADMIN",
  MANAGER : "MANAGER",
  EMPLOYEE : "EMPLOYEE",
  OWNER : "OWNER",
}

/// To show toast if user doesn't has access to that particular feature
/// pass 0 -  admin only access
/// pass 1 -  admin, manager only access


export const checkForPermission = (accessLevel) => {
  if(accessLevel=== USER_ROLE_TYPES.EMPLOYEE) return true;
  let userRoleId = localStorage.getItem("userRoleId");
  let finalResult;

  switch (accessLevel) {
    case USER_ROLE_TYPES.ADMIN:
    case 0:
      userRoleId == 1 ? finalResult = true : finalResult = false;
      break;
    case USER_ROLE_TYPES.MANAGER:
    case 1:
      userRoleId == 3 ? finalResult = false : finalResult = true;
      break;
  }

  return finalResult;

}


export const getImageUrlFromFileUpload = (imgSrc) => {
  const objectUrl = URL.createObjectURL(imgSrc);
  return objectUrl;
}


/// To get the user role name wrt to the user role id

const getUserRoleName = (roleId) => {
  let employeeRole = "Individual";
  switch (roleId) {
    case "1":
      employeeRole = "Admin";
      break;
    case "2":
      employeeRole = "Manager";
      break;
    case "3":
      employeeRole = "Employee";
      break;
    default:
      employeeRole = "Individual";
      break;

  }

  return employeeRole;
}



//// TO capitalize first letter of any string

export function capitalizeFirstLetter(str) {
  if (str.length === 0) {
    return str; // Return empty string as is
  }

  // Capitalize the first letter and concatenate the rest of the string
  return str[0].toUpperCase() + str.slice(1);
}