import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getLoggedInUserWorkspaceId } from "./helper";


export const initializeGlitchTip = async ()=> {


   
    // let extensionVersion = getChromeExtensionVersion()
    // console.log("extensionVersion in sentry js ", extensionVersion);


      Sentry.init({ dsn: "https://e221165cad49445e95b172c4a10522e7@app.glitchtip.com/5301",
     })

  
            Sentry.setUser({
              id:  getLoggedInUserWorkspaceId(),
              username: getLoggedInUserWorkspaceId(),
          });
        Sentry?.setContext('customData', {
          project : "Workspace"
        });
              
}



