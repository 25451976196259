import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './root-reducer';

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}
const composedEnhancers = compose(applyMiddleware(...middleware))
export const store = createStore(rootReducer,undefined ,composedEnhancers)

export const persistor = persistStore(store);