import React from "react";
import GoogleSheetIntegrationPage from "../IntegrationPages/GoogleSheetIntegration/GoogleSheetIntegrationPage";
import HubspotIntegrationPage from "../IntegrationPages/HubspotIntegration/HubspotIntegrationPage";
import "./integrationactivesection.scss";
import CustomCRMIntegration from "../IntegrationPages/CustomCRMIntegration";
import BitrixIntegrationPage from "../IntegrationPages/BitrixIntegration";
import FreshdeskIntegrationPage from "../IntegrationPages/FreshdeskIntegration";
import ZohoIntegrationPage from "../IntegrationPages/ZohoIntegration";
import WebhookIntegrationPage from "../IntegrationPages/WebookIntegration/WebhookIntegrationPage";
import SalesforceIntegrationPage from "../IntegrationPages/SalesforceIntegration";
import LeadsquaredIntegrationPage from "../IntegrationPages/LeadsquaredIntegration";

/**
 * @module IntegrationActiveSection
 */

/**
 *
 * @param {mainActiveSection} - Section which is currently active
 * @param {setMainActiveSection} - function to change current active main Section
 * @returns Current Active Integration Section, for eg- if we click on Facebook Integration then FacebookIntegrationPage will be returned
 */

const IntegrationActiveSection = ({
  mainActiveSection,
  setMainActiveSection,
  IntegrationActiveSections,
  setMainSectionIntegrations,
  connectIntegration,
  extensionIdToSendMessageTo,
  userRoleId,

}) => {
  return (
    <>
      {mainActiveSection ==
        IntegrationActiveSections.GOOGLE_SHEET_CRM_SECTION && (
        <GoogleSheetIntegrationPage
          setMainActiveSection={setMainActiveSection}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.HUBSPOT_CRM_SECTION && (
        <HubspotIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection ==
        IntegrationActiveSections.CUSTOM_CRM_INTEGRATION_SECTION && (
        <CustomCRMIntegration
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.BITRIX_CRM_SECTION && (
        <BitrixIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.FRESHDESK_CRM_SECTION && (
        <FreshdeskIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.ZOHO_CRM_SECTION && (
        <ZohoIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.WEBHOOK_CRM_SECTION && (
        <WebhookIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.SALESFORCE_CRM_SECTION && (
        <SalesforceIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
          userRoleId={userRoleId}
        />
      )}
      {mainActiveSection == IntegrationActiveSections.LEADSQUARED_CRM_SECTION && (
        <LeadsquaredIntegrationPage
          setMainActiveSection={setMainActiveSection}
          setMainSectionIntegrations={setMainSectionIntegrations}
          connectIntegration={connectIntegration}
          extensionIdToSendMessageTo={extensionIdToSendMessageTo}
          userRoleId={userRoleId}
        />
      )}
    </>
  );
};

export default IntegrationActiveSection;
