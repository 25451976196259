
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './reducer'
import dashboardReducer from '../redux/dashboard/dashboard.reducer';
import layoutReducer from '../redux/layout/layout.reducer';
import authReducer from '../redux/auth/auth.reducer';
import organizationReducer from '../redux/organization/organization.reducer';
import crmReducer from '../redux/crm/crm.reducer';
import quickRepliesReducer from '../redux/quickreplies/quickreplies.reducer';
import conversationReducer from '../redux/conversation/conversation.reducer';
import labelsReducer from '../redux/labels/labels.reducer';
import cloudReducer from '../redux/cloud/cloud.reducer';


const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['auth'],
    // whitelist: []
  }

  const appReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    layout: layoutReducer,
    auth: authReducer,
    organization: organizationReducer,
    crm: crmReducer,
    quickReplies: quickRepliesReducer,
    conversation: conversationReducer,
    labels: labelsReducer,
    cloud: cloudReducer
  })

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
      state = undefined;
    }
    return appReducer(state, action);
  }

  export default persistReducer(persistConfig, rootReducer);