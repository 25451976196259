/*global chrome*/
import React, { useEffect, useState } from "react";
import "./webhookintegrationpage.scss";
import WebhookOverviewSection from "./OverviewSection";
import WebhookSetupGuideSection from "./SetupGuideSection";
import WebhookIntegrationHeader from "./WebhookIntegationHeader";
import showToast from "../../../../components/Toasts/ShowToast";

const sectionData = {
  overview: {
    description:
      "Eazybe custom webhooks allows you to quickly connect WhatsApp to any destination. By using Eazybe custom webhooks, you can automatically trigger actions when you receive or send WhatsApp messages.",
    example: "Some examples of using Eazybe custom webhook:",
    featuresArr: [
      "When your team members receive a WhatsApp message, trigger a custom webhook.",
      "When your team members send a WhatsApp message, trigger a custom webhook.",
    ],
  },
};

const WebhookIntegrationPage = ({setMainSectionIntegrations, connectIntegration}) => {

    const [webhookIntegrationData, setWebhookIntegrationData] = useState(null)

    useEffect(()=> {
        getWebhookIntegrationData();
    }, [])

    const getWebhookIntegrationData =()=> {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(`https://eazybe.com/api/v1/whatzapp/webhook/endpoints?org_id=${localStorage.getItem("currentOrgId")}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setWebhookIntegrationData(result);
                console.log("getWebhookIntegrationData",result)
            })
            .catch(error => console.log('error', error));
    }

    const sendMessageToChromeExtension = (status, time = 10, id="clgficggccelgifppbcaepjdkklfcefd", key) => {
      setTimeout(() => {
        if (chrome?.runtime) {
          console.log("Sending message to extension")
          chrome.runtime.sendMessage(
            id,
            // EXTENSION_ID_TEST,
            { key : key ?? "WEBHOOK_CONNECTED" },
            (response) => {
              console.log("response : ", response);
              if (!response?.success) {
              }
            }
          );
        }
      }, time);
    };

    const onClickSaveEndPoint = (type, value) => {
        // console.log("onClickSaveEndPoint type", type)
        // console.log("onClickSaveEndPoint value", value)
        if(value || value === 0){

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let data = { org_id: localStorage.getItem("currentOrgId") };
    
        switch (type) {
          case "duplex_webhook":
            data = { ...data, duplex_webhook: value };
            break;
          // case "incoming":
          //   data = { ...data, incoming: value };
          //   break;
          // case "outgoing":
          //   data = { ...data, outgoing: value };
          //   break;
          case "status":
            data = { ...data, status: value };
            break;
          case "auth_token":
            data = { ...data, auth_token: value };
            break;
          default:
            break;
        }
    
        var raw = JSON.stringify(data);
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        fetch(
          "https://eazybe.com/api/v1/whatzapp/webhook/endpoints",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            sendMessageToChromeExtension();
            getWebhookIntegrationData();
            console.log("onClickSaveEndPoint",result)
        
        })
          .catch((error) => console.log("error", error));
        }
        else {
            showToast("Please enter valid value");
        }
      };


    

  return (
    <section className="webhook-integration-section d-flex-col default-scroll-bar">
        <WebhookIntegrationHeader 
        onClickSaveEndPoint={onClickSaveEndPoint}
        webhookIntegrationData={webhookIntegrationData}
        setMainSectionIntegrations={setMainSectionIntegrations}
        />
      <div className="d-flex mid">
      <WebhookOverviewSection
        description={sectionData.overview.description}
        example={sectionData.overview.example}
        featuresArr={sectionData.overview.featuresArr}
      />
      <WebhookSetupGuideSection 
      onClickSaveEndPoint={onClickSaveEndPoint}
      webhookIntegrationData={webhookIntegrationData}
      />
      </div>
    </section>
  );
};

export default WebhookIntegrationPage;
