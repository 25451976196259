import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import './loader.styles.css'
import loader from '../../assets/icons/logo-animation.gif'

const EazybeLoader = ({ style, className }) => {
  return (
    <div style={style} className={className ? `${className} eazybe-loader` : 'eazybe-loader'} sx={{ display: 'flex' }}>
      {/* <CircularProgress style={{ width: "4rem", height: "4rem" }} /> */}
      <img src={loader} alt="loader" style={{ width: '5rem', height: '5rem' }} />
    </div>
  )
}

export default EazybeLoader