import OrganizationTypes from "./organization.types";
import { FETCH, API, APP_URL_PREFIX } from "../../util/api";
import { format, subDays } from "date-fns";
import { isStartDateOneDayBeforeEndDate } from "../../util/helper";



/**
 *  @namespace OrganizationActions
 */

/**
 * To get organisation  data using organisation code
 * @memberof OrganizationActions
 * @method getOrganizationData
 * @param {string} OrgCode Organisation Code such as DUM-02062022-7793
 * @returns
 */
export const getOrganizationData = (orgCode) => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_ORGANIZATION_DATA_START });

    FETCH({
      method: "GET",
      url: API.GET_ORGANIZATION_DATA + `?organization_code=${orgCode}`,
    })
      .then((res) => {
        let errorMessage;
        if (res.status !== 200 && res.status !== 201) {
          errorMessage = "Something Went Worng!";
        }
        if (errorMessage) throw new Error(errorMessage);
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_ORGANIZATION_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_ORGANIZATION_DATA_FAILURE });
      });
  };
};
/**
 * To create organisation
 * @memberof OrganizationActions
 * @method createOrganization
 * @param {object} body Object of body comprises of variables like organisation logo, organisation name
 * @returns
 */

export const createOrganization = (body) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "POST",
      url: API.CREATE_ORGANIZATION,
      body: JSON.stringify(body),
      ContentType: "application/json"
    })
   
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getSyncReport = (workspaceId) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "GET",
      url: API.GET_USER_SYNC_REPORT + `?workspace_id=${localStorage.getItem('workspaceId')}`
    })
   
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};


// export const createOrganization = (body) => {
//   return (dispatch) => {
//     dispatch({ type: OrganizationTypes.CREATE_ORGANIZATION_START });

//     FETCH(
//       // "https://eazybe.com/api/v1/whatzapp/createCallyzerOrganization",
//       {
//         method: "POST",
//         url: API.CREATE_ORGANIZATION,
//         body: JSON.stringify(body),
//         ContentType: "application/json",
//       }
//     )
//       .then((res) => {
//         return res.json();
//       })
//       .then((resData) => {
//         console.log(resData);
//         if (resData.type) {
//           dispatch({
//             type: OrganizationTypes.CREATE_ORGANIZATION_SUCCESS,
//             payload: resData.data,
//           });
//         } else {
//           dispatch({ type: OrganizationTypes.CREATE_ORGANIZATION_FAILURE });
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: OrganizationTypes.CREATE_ORGANIZATION_FAILURE });
//       });
//   };
// };

/**
 * To update organisation
 * @memberof OrganizationActions
 * @method updateOrganization
 * @param {object} body Object of body comprises of variables like organisation logo, organisation name
 * @returns
 */

export const updateOrganization = (body) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "PATCH",
      url: API.UPDATE_ORGANISATION,
      body: JSON.stringify(body),
      ContentType: "application/json",
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// export const updateOrganization = (body) => {
//   return (dispatch) => {
//     dispatch({ type: OrganizationTypes.UPDATE_ORGANISATION_START });

//     FETCH(
//       // "https://eazybe.com/api/v1/whatzapp/createCallyzerOrganization",
//       {
//         method: "PATCH",
//         url: API.UPDATE_ORGANISATION,
//         body: JSON.stringify(body),
//         ContentType: "application/json",
//       }
//     )
//       .then((res) => {
//         return res.json();
//       })
//       .then((resData) => {
//         console.log(resData);
//         dispatch({
//           type: OrganizationTypes.UPDATE_ORGANISATION_SUCCESS,
//           payload: resData,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: OrganizationTypes.UPDATE_ORGANISATION_FAILURE });
//       });
//   };
// };

/**
 * To create organisation
 * @memberof OrganizationActions
 * @method createOrganization
 * @param {object} body Object of body comprises of variables like organisation logo, organisation name
 * @returns
 */

export const removeInvitedUser = (userId) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "POST",
      url: API.REMOVE_USER + `?user_id=${userId}&org_id=${localStorage.getItem("currentOrgId")}`,
      ContentType: "application/json",
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// export const removeInvitedUser = (userId) => {
//   return (dispatch) => {
//     dispatch({ type: OrganizationTypes.REMOVE_USER_START });

//     FETCH(
//       // "https://eazybe.com/api/v1/whatzapp/createCallyzerOrganization",
//       {
//         method: "POST",
//         url: API.REMOVE_USER + `?user_id=${userId}`,
//         ContentType: "application/json",
//       }
//     )
//       .then((res) => {
//         return res.json();
//       })
//       .then((resData) => {
//         console.log(resData);
//         dispatch({
//           type: OrganizationTypes.REMOVE_USER_SUCCESS,
//           payload: resData,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: OrganizationTypes.REMOVE_USER_FAILURE });
//       });
//   };
// };

/**
 * To create team
 * @memberof OrganizationActions
 * @method createTeam
 * @param {object} body Object of body comprises of variables like team name, team color code, parent organisation id
 * @returns
 */

export const createTeam = (body) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "POST",
      url: API.CREATE_TEAM,
      body: JSON.stringify(body),
      ContentType: "application/json",
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * To update team details
 * @memberof OrganizationActions
 * @method updateTeamDetails
 * @param {object} body Object of body comprises of id, team_name, color_code
 * @returns
 */

export const updateTeamDetails = (body) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "PATCH",
      url: API.UPDATE_TEAM_DETAILS,
      body: JSON.stringify(body),
      ContentType: "application/json",
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * Api to get color palette
 * @memberof OrganizationActions
 * @method getColorPalette
 * @returns An array of objects with all the color palettes available
 */
export const getColorPalette = () => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_COLOR_PALETTE_START });

    FETCH({
      method: "GET",
      url: API.GET_COLOR_PALETTE,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_COLOR_PALETTE_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_COLOR_PALETTE_FAILURE });
      });
  };
};

/**
 * To get list of  existing teams in an organisation using org_id
 * @memberof OrganizationActions
 * @method getOrgTeams
 * @param {number} orgId such as 1, 2, 3
 * @returns A high level list of teams with details to show on the teams screen
 */
export const getOrgTeams = (orgId) => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_ORG_TEAMS_START });

    FETCH({
      method: "GET",
      url: API.GET_ORG_TEAMS + `?org_id=${orgId}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_ORG_TEAMS_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_ORG_TEAMS_FAILURE });
      });
  };
};

/**
 * To get list of  roles a user can have in an organisation
 * @memberof OrganizationActions
 * @method getUserRoles
 * @returns An array list of user roles
 */
export const getUserRoles = () => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_USER_ROLES_START });

    FETCH({
      method: "GET",
      url: API.GET_USER_ROLES,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_USER_ROLES_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_USER_ROLES_FAILURE });
      });
  };
};

/**
 * To add employees in an organisation. We will send invite to added users.
 * @memberof OrganizationActions
 * @method addEmployees
 * @param {Object} body
 * @returns An array list of user roles
 */
export const addEmployees = (body) => {
  return new Promise((resolve, reject) => {
    {
      FETCH({
        method: "POST",
        url: API.ADD_EMPLOYEES,
        body: JSON.stringify(body),
        ContentType: "application/json",
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          resolve(resData);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    }
  });
};

/**
 * To get the list of employees in an organisation.
 * We are sending status 2, which will give us the list of employees who have accepted the invitation.
 * @memberof OrganizationActions
 * @method getEmployeesList
 * @param {number} orgId such as 1, 2, 3 etc
 * @returns An array list of user roles
 */

export const getOrgEmployeesList = (orgId, status) => {
  console.log("getOrgEmployeesList started")
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_ORG_EMPLOYEES_START });

    FETCH({
      method: "GET",
      url: API.GET_EMPLOYEES + `?org_id=${orgId}&workspace_id=${localStorage.getItem("workspaceId")}&status=${status ?? 2}&type=teams`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_ORG_EMPLOYEES_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_ORG_EMPLOYEES_FAILURE });
      });
  };
};

/**
 * To get the list of employees in an organisation.
 * We are sending status 2, which will give us the list of employees who have accepted the invitation.
 * @memberof OrganizationActions
 * @method getInvitedEmployeesList
 * @param {number} orgId such as 1, 2, 3 etc
 * @returns An array list of user roles
 */

export const getOrgInvitedEmployeesList = (orgId) => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_ORG_INVITED_EMPLOYEES_START });

    FETCH({
      method: "GET",
      url: API.GET_EMPLOYEES + `?org_id=${orgId}&workspace_id=${localStorage.getItem("workspaceId")}&type=teams`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: OrganizationTypes.GET_ORG_INVITED_EMPLOYEES_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_ORG_INVITED_EMPLOYEES_FAILURE });
      });
  };
};

/**
 * To get the current active seats.
 * @memberof OrganizationActions
 * @method getCurrentActiveSeats
 * @param {number} orgId such as 1, 2, 3 etc
 * @returns number of active seats
 */

export const getCurrentSubscribedSeats = (orgId) => {
  return (dispatch, getState) => {
    dispatch({ type: OrganizationTypes.GET_CURRENT_SUBSCRIBED_SEATS_START });

    FETCH({
      method: "GET",
      url: API.GET_USER_CREDIT_HISTORY + `?workspace_id=${localStorage.getItem("workspaceId")}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {

       let currentSubbedSeats = 0;
       console.log("getCurrentSubscribedSeats", resData )

       if(resData.data){
        currentSubbedSeats = resData.data.seat_issued
       }

        dispatch({
          type: OrganizationTypes.GET_CURRENT_SUBSCRIBED_SEATS_SUCCESS,
          payload: currentSubbedSeats,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_CURRENT_SUBSCRIBED_SEATS_FAILURE });
      });
  };
};
/**
 * To get the current active seats.
 * @memberof OrganizationActions
 * @method getCurrentActiveSeats
 * @param {number} orgId such as 1, 2, 3 etc
 * @returns number of active seats
 */

export const getCurrentActiveSeats = (orgId) => {
  return (dispatch) => {
    dispatch({ type: OrganizationTypes.GET_CURRENT_ACTIVE_SEATS_START });

    FETCH({
      method: "GET",
      url: API.GET_EMPLOYEES + `?org_id=${orgId}&workspace_id=${localStorage.getItem("workspaceId")}&type=teams`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {

        let count = 0;
        resData.forEach((item)=> {
          if(item?.syncing_active == 1){
            count += 1;
          }
        })

        dispatch({
          type: OrganizationTypes.GET_CURRENT_ACTIVE_SEATS_SUCCESS,
          payload: count,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OrganizationTypes.GET_CURRENT_ACTIVE_SEATS_FAILURE });
      });
  };
};



/**
 * To check if the user exists in any organisation
 * @memberof OrganizationActions
 * @method checkForExistingUser
 * @param {number} userMobile
 * @returns An object of user details
 */

export const checkForExistingUser = (userMobile) => {
 
  return new Promise((resolve, reject)=> {
    FETCH({
      method: "GET",
      url: API.CHECK_USER_EXISTENCE + `?user_mobile=${userMobile}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });

  })

   
  
};
/**
 * To check if the user exists in any organisation
 * @memberof OrganizationActions
 * @method checkForExistingUser
 * @param {number} userMobile
 * @returns An object of user details
 */

export const checkMobileNumberExistence = (userMobile) => {
 
  return new Promise((resolve, reject)=> {
    FETCH({
      method: "GET",
      url: API.CHECK_MOBILE_NUMBER_EXISTENCE + `?mobile=${userMobile}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        resolve(false);
      });

  })

   
  
};

/**
 * To update user details
 * @memberof OrganizationActions
 * @method updateUserDetails
 * @param {object} body Object of body comprises of variables like organisation logo, organisation name
 * @returns
 */

export const updateUserDetails = (body) => {
  // return (dispatch) => {
  //   dispatch({ type: OrganizationTypes.UPDATE_USER_START });
  return new Promise((resolve, reject)=> {
    FETCH(
      // "https://eazybe.com/api/v1/whatzapp/createCallyzerOrganization",
      {
        method: "PATCH",
        url: API.UPDATE_EMPLOYEE,
        body: JSON.stringify(body),
        ContentType: "application/json"
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData)
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });


  })
   
}

/**
 * To get the list of manager of a particular team
 * @memberof OrganizationActions
 * @method getManagerListOfTeam
 * @param {array} empArr Array of employee which consists of all the employees
 * @param {number} teamId Id of the team for eg 1, 2, 22, 87 etc
 * @param {number} userRole different type of users have different role id, for eg for now manager is havin role id of 2
 */

export const getManagerListOfTeam = (empArr, teamId, userRole) => {
  return new Promise((resolve, reject) => {
    try {
      let filteredTeamObjArr = empArr.filter((item) => item.team_id == teamId);
      console.log(filteredTeamObjArr);
      let filteredTeamManagersObjArr = filteredTeamObjArr.filter(
        (item) => item.user_role == userRole
      );
      console.log(filteredTeamManagersObjArr);
      let managersOfTeamList = [];
      filteredTeamManagersObjArr.forEach((item) =>
        managersOfTeamList.push(item.full_name)
      );
      console.log(managersOfTeamList);
      resolve(managersOfTeamList);
    } catch (err) {
      console.log("Error while creating list of managers", err);
      reject(err);
    }
  });
};




/**
 * To add employees to a team
 * @memberof OrganizationActions
 * @method addEmployeesToTeam
 */

export const addEmployeesToTeam =(body)=> {
  console.log("final body", body);
  return new Promise((resolve, reject) => {
    FETCH({
      method: "PATCH",
      url: API.ADD_EMPLOYEES_TO_TEAM,
      body: JSON.stringify(body),
      ContentType: "application/json",
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

}




/**
 * To get the list of manager of a particular team
 * @memberof OrganizationActions
 * @method getTeamNameById
 * @param {array} teamList Array of team which consists of all the teams
 * @param {number} teamId Id of the team for eg 1, 2, 22, 87 etc
 */

 export const getTeamNameById = ( teamId, teamList) => {
    try {
      let teamName = teamList?.data?.filter((item)=> item.team_data.id == teamId)[0]?.team_data.team_name;
      if(teamName){
        return(teamName);
      }
      else{
        return("No Team")
      }
      
    } catch (err) {
      console.log("Error while getting teamName by Id of team ", err);
      return("Err")
    }

};





/**
 * To get the list of manager of a particular team
 * @memberof OrganizationActions
 * @method getTeamNameById
 * @param {array} teamList Array of team which consists of all the teams
 * @param {number} teamId Id of the team for eg 1, 2, 22, 87 etc
 */

 export const getTeamColorById = ( teamId, teamList) => {

    try {
      let masterColor = teamList?.data?.filter((item)=> item.team_data.id == teamId)[0]?.team_data.master_color;
      if(masterColor){
        return(masterColor);
      }
      else{
        return({
          bg_color: "#C4C4C4",
          text_color:" #3d4043"
        })
      }
      
    } catch (err) {
      console.log("Error while getting teamColor by Id of team ", err);
      // reject(err);
    }

}


/**
 * To get the list of all teams
 * @memberof OrganizationActions
 * @method getAllTeams
 * @param {array} data Array of team which consists of all the teams
 */


export const getAllTeams = (data) => {
  return dispatch => {
    dispatch({type: OrganizationTypes.GET_TEAMS_LIST_START})

    dispatch(getColorPalette())
    FETCH({
      
      method: 'GET',
      url: `${API.GET_TEAMS_LIST}?user_id=${localStorage.getItem('workspaceId')}`,
    })
    .then(res => {
      let errorMessage;
      if (res.status !== 200 && res.status !== 201 ) {
        errorMessage = 'Something Went Worng!';
      }
      if (errorMessage) throw new Error(errorMessage)
      return res.json();
    })
    .then(resData => {

      dispatch({
        type: OrganizationTypes.GET_TEAMS_LIST_SUCCESS,
        payload: resData.data,
      });

      dispatch(getUsersAllOrganization())
      
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_TEAMS_LIST_FAILURE });
    });
  }
}

export const getCustomEmployees = () => {
  return (dispatch,getState) => {
    dispatch({type: OrganizationTypes.GET_CUSTOM_EMPLOYEES_START})

    // dispatch(getColorPalette());

    let tempUserEmail = localStorage.getItem('userEmail');

    FETCH({
      method: 'GET',
      url: `${API.CHECK_USER_EXISTENCE}?user_email=${tempUserEmail}`,
    })
    .then(res => {
      let errorMessage;
      if (res.status !== 200 && res.status !== 201 ) {
        errorMessage = 'Something Went Worng!';
      }
      if (errorMessage) throw new Error(errorMessage)
      return res.json();
    })
    .then(resData => {

     console.log("CHECK_USER_EXISTENCE resData", resData);

     let userData =resData.data;
     let userWorkspaceId = userData.id
     

  //    There will be total of 4 cases:
  //  1) Individual ( User with no org) : can see his data only

  //  2) Employee : can see his data only

  // 3)  Manager : can see data of his team

  // 4)  Admin : can see everyone's data

      let isUserPartOfOrg = userData?.callyzer_user_mappings.length >0 ? true : false; /// Is user part of any organisation ?

      /// GET EMPLOYEES LIST 
      
      console.log(`getCustomEmployees func => userData: ${userData}, userWorkspaceId: ${userWorkspaceId}, isUserPartOfOrg: ${isUserPartOfOrg}`);

      if(isUserPartOfOrg){
        let userOrgData = userData?.callyzer_user_mappings[0];
        // role Id: 1 : admin, 2: manager , 3: employee
        let userRoleInOrg = userOrgData.callyzer_user_role.id; 
        let userTeamId = userOrgData.team_id;
        let userOrgId = userOrgData.org_id;
        dispatch(getOrgEmployeesList(userOrgId));

        let allEmployeeList = getState().organization.orgEmployeesData.data.filter(item=> item.user_status == 2);
        console.log("getCustomEmployees func => getState().organization.employeesListCustom", allEmployeeList);
        console.log("getCustomEmployees func => userOrgData", userOrgData);

        console.log(`getCustomEmployees func => userOrgData: ${userOrgData}, userRoleInOrg: ${userRoleInOrg}, userTeamId: ${userTeamId}, userOrgId: ${userOrgId}`);

        let requiredEmployees;

        switch(userRoleInOrg) {
          case 1 :
             requiredEmployees = allEmployeeList;
            break;
            
          case 2 :  
          if(userTeamId==0){
            requiredEmployees = allEmployeeList.filter((item)=> item.workspace_id == userWorkspaceId);
          }

          else {
            requiredEmployees = allEmployeeList.filter((item)=> item.team_id == userTeamId);
          }

          break;
  
          
          case 3 :
           requiredEmployees = allEmployeeList.filter((item)=> item.workspace_id == userWorkspaceId);

            break;
          
        }

        console.log("getCustomEmployees func => requiredEmployees ", requiredEmployees);
        dispatch({
          type: OrganizationTypes.GET_CUSTOM_EMPLOYEES_SUCCESS,
          payload: requiredEmployees,
        });
      }
      else {

        dispatch({
          type: OrganizationTypes.GET_CUSTOM_EMPLOYEES_SUCCESS,
          payload: [
            {
              whatsapp_no: userData.mobile
            }
          ],
        });

      }
    

    //  dispatch({
    //   type: OrganizationTypes.GET_CUSTOM_EMPLOYEES_SUCCESS,
    //   payload: resData,
    // });
      
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_CUSTOM_EMPLOYEES_FAILURE });
    });



  
  }
}

// export const getCustomEmployeesPromise = () => {
//  return new Promise((resolve, reject) => {
    

//   // dispatch(getColorPalette());

//   let tempUserEmail = localStorage.getItem('userEmail');

//   FETCH({
//     method: 'GET',
//     url: `${API.CHECK_USER_EXISTENCE}?user_email=${tempUserEmail}`,
//   })
//   .then(res => {
//     let errorMessage;
//     if (res.status !== 200 && res.status !== 201 ) {
//       errorMessage = 'Something Went Worng!';
//     }
//     if (errorMessage) throw new Error(errorMessage)
//     return res.json();
//   })
//   .then(resData => {

//    console.log("CHECK_USER_EXISTENCE resData", resData);

//    let userData =resData.data;
//    let userWorkspaceId = userData.id
   

// //    There will be total of 4 cases:
// //  1) Individual ( User with no org) : can see his data only

// //  2) Employee : can see his data only

// // 3)  Manager : can see data of his team

// // 4)  Admin : can see everyone's data

//     let isUserPartOfOrg = userData.callyzer_user_mappings.length >0 ? true : false; /// Is user part of any organisation ?

//     /// GET EMPLOYEES LIST 
    
//     console.log(`getCustomEmployees func => userData: ${userData}, userWorkspaceId: ${userWorkspaceId}, isUserPartOfOrg: ${isUserPartOfOrg}`);

//     if(isUserPartOfOrg){
//       let userOrgData = userData.callyzer_user_mappings[0];
//       // role Id: 1 : admin, 2: manager , 3: employee
//       let userRoleInOrg = userOrgData.callyzer_user_role.id; 
//       let userTeamId = userOrgData.team_id;
//       let userOrgId = userOrgData.org_id;
//       getOrgEmployeesList(userOrgId);

//       let allEmployeeList = getState().organization.orgEmployeesData.data.filter(item=> item.user_status == 2);
//       console.log("getCustomEmployees func => getState().organization.employeesListCustom", allEmployeeList);
//       console.log("getCustomEmployees func => userOrgData", userOrgData);

//       console.log(`getCustomEmployees func => userOrgData: ${userOrgData}, userRoleInOrg: ${userRoleInOrg}, userTeamId: ${userTeamId}, userOrgId: ${userOrgId}`);

//       let requiredEmployees;

//       switch(userRoleInOrg) {
//         case 1 :
//            requiredEmployees = allEmployeeList;
//           break;

//         case 2 :  
//         if(userTeamId==0){
//           requiredEmployees = allEmployeeList.filter((item)=> item.workspace_id == userWorkspaceId);
//         }

//         else {
//           requiredEmployees = allEmployeeList.filter((item)=> item.team_id == userTeamId);
//         }

//         break;

        
//         case 3 :
//          requiredEmployees = allEmployeeList.filter((item)=> item.workspace_id == userWorkspaceId);

//           break;
        
//       }

//       console.log("getCustomEmployees func => requiredEmployees ", requiredEmployees);
//       resolve(
//         requiredEmployees
//       )
//     }
//     else {

//       resolve(
//         [
//           {
//             whatsapp_no: userData.mobile
//           }
//         ]
//       )

      

//     }
  


    
//   })
//   .catch(err => {
//     console.log(err);

//   });




// })
// }

export const getFilteredCallLogs = (data) => {
  return (dispatch,getState) => {
    dispatch({type: OrganizationTypes.GET_CALL_LOGS_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_FILTERED_CALL_LOGS}?user_id=${localStorage.getItem('workspaceId')}&start_date=${getState().layout.filterDate.startDate}&end_date=${getState().layout.filterDate.endDate}${data.page ? `&page_index=${data.page}`: ''}${data.employees ? `&employes=${data.employees}`: ''}${data.callType ? `&call_types=${data.callType}`: ''}`,
    })
    .then(res => {
      let errorMessage;
      if (res.status !== 200 && res.status !== 201 ) {
        errorMessage = 'Something Went Worng!';
      }
      if (errorMessage) throw new Error(errorMessage)
      return res.json();
    })
    .then(resData => {

      dispatch({
        type: OrganizationTypes.GET_CALL_LOGS_SUCCESS,
        payload: resData.data,
        totals: resData.overall_data,
        page: data.page
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_CALL_LOGS_FAILURE });
    });
  }
}

export const getFilteredEmployeeCallLogs = (data) => {
  return (dispatch,getState) => {
    dispatch({type: OrganizationTypes.GET_EMPLOYEE_CALL_LOGS_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_FILTERED_CALL_LOGS}?user_id=${localStorage.getItem('workspaceId')}&start_date=${getState().layout.filterDate.startDate}&end_date=${getState().layout.filterDate.endDate}${data.page ? `&page_index=${data.page}`: ''}${data.employees ? `&employes=${data.employees}`: ''}${data.callType ? `&call_types=${data.callType}`: ''}`,
    })
    .then(res => {
      let errorMessage;
      if (res.status !== 200 && res.status !== 201 ) {
        errorMessage = 'Something Went Worng!';
      }
      if (errorMessage) throw new Error(errorMessage)
      return res.json();
    })
    .then(resData => {

      dispatch({
        type: OrganizationTypes.GET_EMPLOYEE_CALL_LOGS_SUCCESS,
        payload: resData.data,
        totals: resData.overall_data,
        page: data.page
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_EMPLOYEE_CALL_LOGS_FAILURE });
    });
  }
}




export const getOrgEmployeesListBrief =(orgId)=> {
  return dispatch => {
    dispatch({type: OrganizationTypes.GET_ORG_EMPLOYEES_LIST_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_ORG_EMPLOYEES}?org_id=${orgId}`,
    })
    .then(res => {
      let errorMessage;
      if (res.status !== 200 && res.status !== 201 ) {
        errorMessage = 'Something Went Worng!';
      }
      if (errorMessage) throw new Error(errorMessage)
      return res.json();
    })
    .then(resData => {
      console.log("resData", resData);
      dispatch({
        type: OrganizationTypes.GET_ORG_EMPLOYEES_LIST_SUCCESS,
        payload: resData
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_ORG_EMPLOYEES_LIST_FAILURE });
    });
  }
}

/// To get user's subscribed plan info

export const getUserPlanList =(workspaceId)=> {
  return dispatch => {
    dispatch({type: OrganizationTypes.GET_USER_PLAN_LIST_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_USER_PLAN_LIST}?workspace_id=${workspaceId}`,
    })
    .then(res => {
      
      return res.json();
    })
    .then(resData => {
      console.log("resData", resData);
      dispatch({
        type: OrganizationTypes.GET_USER_PLAN_LIST_SUCCESS,
        payload: resData
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_USER_PLAN_LIST_FAILURE });
    });
  }

}

/// To get user's credit history

export const getUserCreditHistory =(workspaceId)=> {
  return dispatch => {
    dispatch({type: OrganizationTypes.GET_USER_CREDIT_HISTORY_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_USER_CREDIT_HISTORY}?workspace_id=${workspaceId}`,
    })
    .then(res => {
      
      return res.json();
    })
    .then(resData => {
      console.log("resData", resData);
      dispatch({
        type: OrganizationTypes.GET_USER_CREDIT_HISTORY_SUCCESS,
        payload: resData
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_USER_CREDIT_HISTORY_FAILURE });
    });
  }
}

export const getUsersAllOrganization =()=> {
  return (dispatch,getState) => {
    dispatch({type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_START})
    
    FETCH({
      method: 'GET',
      url: `${API.GET_ALL_ORGANIZATION_LIST}?workspace_id=${getState().auth.userExistenceData.data?.id}}`,
    })
    .then(res => {
      
      return res.json();
    })
    .then(resData => {
      console.log("all organizations", resData);
      let personalWorkspace = {
        callyzer_organization: {
          org_id: null,
          org_name: "Personal"
        } ,
        default_org: null,
        joined_at: null,
        mapping_id: null,
        org_id: null,
        role_id: null,
        team_id: null,
        workspace_id: null
      }

      dispatch({
        type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_SUCCESS,
        payload: resData?.length ? [...resData,personalWorkspace]: resData
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_FAILURE });
    });
  }
}

export const setUserActiveOrganization =(orgId)=> {
  return (dispatch,getState) => {
    dispatch({type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_START})
    
    FETCH({
      method: 'PATCH',
      url: `${API.SET_ACTIVE_ORGANIZATION}?workspace_id=${getState().auth.userExistenceData.data.id}&org_id=${orgId}`,
    })
    .then(res => {
      
      return res.json();
    })
    .then(resData => {
      console.log("set active org", resData)
      // dispatch({
      //   type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_SUCCESS,
      //   payload: resData?.length ? [...resData,personalWorkspace]: resData
      // });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_FAILURE });
    });
  }
}



////  TO toggle user sync in workspace

export const changeUserSync = (syncStatus, userWorkspaceId) => {
  return new Promise((resolve, reject) => {
    FETCH({
      method: "POST",
      url: API.CHANGE_USER_SYNC_STATUS + `?workspace_id=${userWorkspaceId}&org_id=${localStorage.getItem("currentOrgId")}&sync_status=${syncStatus}`,
      ContentType: "application/json"
    })
   
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};



export const getTeamListById =(orgId,teamId)=> {

  return new Promise((resolve, reject)=> {

    FETCH({
      method: "GET",
      url: API.GET_EMPLOYEES + `?org_id=${orgId}&workspace_id=${localStorage.getItem("workspaceId")}&status=2&type=teams`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        let allTeamsData = resData.data;
        console.log("allTeamsData", allTeamsData);
        let requiredTeamData = [];
        allTeamsData.forEach((employee)=> {
          if(employee?.callyzer_orgamnization_team?.id == teamId){
            requiredTeamData.push(employee);
          }
        })
        console.log("requiredTeamData in action after updating",requiredTeamData );
        resolve(requiredTeamData);
      })
      .catch((err) => {
        console.log(err);
        
      });
  })

}
/**
 * 
 * @returns list of integration and employee workspace ids.
 */
export const getEmployeesIntegrations =(orgId)=> {
  return (dispatch,getState) => {
    console.log("org Integrations called")
    console.log("org Integrations orgid", orgId)
    dispatch({type: OrganizationTypes.FETCH_USER_INTEGRATIONS})

    if(orgId) {
      FETCH({
        method: 'GET',
        url: `${API.FETCH_USER_INTEGRATIONS}?org_id=${orgId}`,
      })
      .then(res => res.json())
      .then(resData => {
        console.log("org Integrations", resData)
        dispatch({type: OrganizationTypes.FETCH_USER_INTEGRATIONS_SUCCESS, payload: resData})
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: OrganizationTypes.FETCH_USER_INTEGRATIONS_FAILURE });
      });
    }   
   
  }
}


export const getQuickRepliesAnalytics = () => {
  return (dispatch, getState) => {
    dispatch({ type: OrganizationTypes.GET_ORG_QUICK_REPLIES_ANALYTICS_START });
    
    let startDate = getState().layout.filterDate.startDate.split(" ")[0];
    let endDate = getState().layout.filterDate.endDate.split(" ")[0];


    FETCH({
      method: "GET",
      url: API.QUICK_REPLIES_ANALYTICS + '?org_id=' + localStorage.getItem('currentOrgId') + `&start_date=${startDate}&end_date=${endDate}`
    })
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: OrganizationTypes.GET_ORG_QUICK_REPLIES_ANALYTICS_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      dispatch({ type: OrganizationTypes.GET_ORG_QUICK_REPLIES_ANALYTICS_FAILURE });
    })
  }
}


export const getOrgConversationAnalytics = (workspaceId, labels) => {
  return (dispatch, getState) => {
    dispatch({
      type: OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_START
    });

    let startDate = getState().layout.filterDate.startDate.split(" ")[0];
    let endDate = getState().layout.filterDate.endDate.split(" ")[0];

    const activeEmployee = getState().layout.activeEmployee;

    if (startDate === endDate  ) {
      const newDate = subDays(new Date(startDate), 1);
      startDate = format(new Date(newDate), "yyyy-MM-dd")
    }

    let config;

    if (labels && labels.length > 0) {
      config = {
        method: "post",
        url: `${API.GET_CONVERSATION_ANALYTICS}?workspace_id=${workspaceId}&start_date=${startDate}&end_date=${endDate}`,
        body: JSON.stringify({
          "label_id": labels
        }),
        ContentType: "application/json"
      }
    } else {
      config = {
        method: "GET",
        url: `${API.GET_CONVERSATION_ANALYTICS}?workspace_id=${workspaceId}&start_date=${startDate}&end_date=${endDate}`
      }
    }

    FETCH(config)
    .then(res => res.json())
    .then(res => {
      if (res.result) {
        if (activeEmployee !== "All" && activeEmployee.length > 0) {
          console.log(activeEmployee)
          const filteredData = res.data.filter(data => {
            return activeEmployee.includes(data.workspace_id)
          });

            dispatch({
              type: OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_SUCCESS,
              payload: filteredData
            })
          } else {
            dispatch({
              type: OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_SUCCESS,
              payload: res.data
            })
          }
        } else {
          dispatch({
            type: OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_SUCCESS,
            payload: []
          })
        }
      })
      .catch(error => {
        dispatch({
          type: OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_FAILURE,
          message: error.message
        })
      })
  }
}


export const getOrgSchedulerAnalytics = () => {
  return (dispatch, getState) => {
    dispatch({ type: OrganizationTypes.GET_ORG_SCHEDULER_ANALYTICS_START });

    let startDate = getState().layout.filterDate.startDate.split(" ")[0];
    let endDate = getState().layout.filterDate.endDate.split(" ")[0];

    // if (startDate === endDate ) {
    //   const newDate = subDays(new Date(startDate), 1);
    //   startDate = format(new Date(newDate), "yyyy-MM-dd")
    // }


    FETCH({
      method: "GET",
      url: API.GET_SCHEDULER_ANALYTICS + '?org_id=' + localStorage.getItem('currentOrgId') + `&start_date=${startDate}&end_date=${endDate}`
    })
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: OrganizationTypes.GET_ORG_SCHEDULER_ANALYTICS_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      dispatch({ type: OrganizationTypes.GET_ORG_SCHEDULER_ANALYTICS_FAILURE });
    })
  }
}


export const getOrgEmployeesIntegrationsData = () => {
  return (dispatch, getState) => {
    dispatch({ type: OrganizationTypes.GET_INTEGRATIONS_LAST_SYNC_START });



    FETCH({
      method: "GET",
      url: API.GET_INTEGRATIONS_LAST_SYNC + '?org_id=' + localStorage.getItem('currentOrgId')
    })
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: OrganizationTypes.GET_INTEGRATIONS_LAST_SYNC_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      dispatch({ type: OrganizationTypes.GET_INTEGRATIONS_LAST_SYNC_FAILURE });
    })
  }
}


export const saveDontShowUpgrade = (status) => {
  return (dispatch, getState) => {
   
    dispatch({
      type: OrganizationTypes.SAVE_DONT_SHOW_UPGRADE,
      payload: status ?? true
    })


  }
}

export const getAllUserProfileData = () => {
  return async dispatch => {
    try {
      let res = await fetch(
        `${APP_URL_PREFIX}getalluserprofiledata?workspace_id=${localStorage.getItem("workspaceId")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.ok) {
        let response = await res.json();
        // console.log("getAllNameColumnValues response", response);

        // if(response?.data?.length){
        //   let profileData = response.data;
        //   let contactsArr = [];
        //   profileData.forEach(item=> {
        //     let contactNumber =item?.chat_id?.split("@")[0]
        //     contactsArr.push(contactNumber)
        //   })
        // }
        dispatch({
          type: OrganizationTypes.GET_ALL_USER_PROFILE_DATA,
          payload: response
        })
      } else {
        dispatch({
          type: OrganizationTypes.GET_ALL_USER_PROFILE_DATA,
          payload: null
        })
      }
    } catch (e) {
      console.error("failed to delete tag", e);
      dispatch({
        type: OrganizationTypes.GET_ALL_USER_PROFILE_DATA,
        payload: null
      })
    }
  }
};

export const toggleAddEmployeeModal = () => {
  return dispatch => {
    dispatch({
      type: OrganizationTypes.TOGGLE_ADD_EMPLOYEE_MODAL
    })
  }
}