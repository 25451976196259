import React from 'react';
import { Modal } from '@material-ui/core';
import { Box } from '@mui/material';
import { selectUserExistenceData } from '../../../redux/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import leftImg from '../../../assets/images/animated-left.png';
import rightImg from '../../../assets/images/animated-right.png';
import arrow from '../../../assets/images/arrow-tranparent.gif';

import styles from './styles.module.scss';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  bgcolor: "#000000bb",
  height: "100%",
  boxShadow: 24,
};

const SwitchUser = ({openPopup, handleClose, fetchedUserExistenceData}) => {
  return (
    <Modal
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="trial-ended-popup-modal"
      style={{height: "100vh", zIndex: 999999}}
    >
    
      <Box className="trial-ended-popup-container d-flex" sx={style}>
        <div className={styles.container}>
          <div className={styles.images_container}>
            <img src={leftImg} alt="Left Img" />
            <img src={arrow} alt="Arrow Img" className={styles.arrowImg} />
            <img src={rightImg} alt="Right Img" />
          </div>
          {fetchedUserExistenceData.data?.callyzer_user_mappings[0]?.is_default_org === 1 ? (
            <h6>Please wait.. We are switching you to Individual account.</h6>
          ) : (
            <h6>Please wait.. We are switching you to Teams account.</h6>
          )}
        </div>
      </Box>
  </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  fetchedUserExistenceData: selectUserExistenceData
})

export default connect(mapStateToProps)(SwitchUser)