import {
    createSelector
} from 'reselect';
import { addUpTime, sumUpTime, sumUpTimeInFormat, sumUpTimeRaw, toSeconds } from '../../util/helper';
import { selectedEmployee, selectedTeam } from '../layout/layout.selector';
// import { selectQuickRepliesAnalytics } from '../quickreplies/quickreplies.selector';

const organization = state => state.organization;

export const selectOrgEmployeesIntegrationsData = createSelector(
    [organization],
    organization => organization.orgEmployeesIntegrations
)


export const selectOrgQuickRepliesAnalytics = createSelector(
    [organization],
    organization => organization.quickRepliesAnalytics
)

export const selectOrgConversationsAnalytics = createSelector(
    [organization],
    organization => organization.conversationAnalytics
)
export const selectOrgSchedulerAnalytics = createSelector(
    [organization],
    organization => organization.orgSchedulerAnalytics
)


export const selectTeamsListData = createSelector(
    [organization],
    organization=> organization.teamsList
)

export const selectOrganizationData = createSelector(
    [organization],
    organization=> organization.organizationData
) 

export const selectCreatedOrganizationData = createSelector(
    [organization],
    organization=> organization.createdOrganizationData
) 

export const selectCreatedTeamData = createSelector(
    [organization],
    organization=> organization.createdTeamData
)

export const selectColorPaletteData = createSelector(
    [organization],
    organization=> organization.colorPaletteData
)

export const selectOrgTeamsData = createSelector(
    [organization],
    organization=> organization.orgTeamsData
)

export const selectupdatedTeamDetailsData = createSelector(
    [organization],
    organization=> organization.updatedTeamsData
)

export const selectUserRolesData = createSelector(
    [organization],
    organization=> organization.userRolesData
)

export const selectCustomEmployeesList = createSelector(
    [organization],
    organization=> organization.employeesListCustom
)

export const selectaddedEmployeesData = createSelector(
    [organization],
    organization=> organization.addedEmployeesData
)

export const selectUserIntegrations = createSelector(
    [organization],
    organization => organization.userIntegrations
)

const setIntegrations = (args,types) => {
   
    const keys = Object.keys(args);
    const combinedIntegrations = [...Object.values(args)]

    const employeeIntegrations = {}
    combinedIntegrations.forEach((array,idx) => {
        for(const employeeId of array) {
            if(!employeeIntegrations.hasOwnProperty(employeeId)) {
                employeeIntegrations[employeeId] = []
            }
            employeeIntegrations[employeeId].push(types[keys[idx]])
        }
    })

    // console.log("combinedIntegrations", employeeIntegrations)
    return employeeIntegrations;
}

export const selectOrgEmployeesData = createSelector(
    [organization,selectUserIntegrations, selectColorPaletteData, selectOrgQuickRepliesAnalytics, selectOrgSchedulerAnalytics, selectOrgEmployeesIntegrationsData ],
    (organization, userIntegrations, colorPaletteData, quickRepliesAnalyticsData, orgSchedulerAnalyticsData, orgEmployeesIntegrations  )=> 
    {
        // console.log("selectOrgEmployeesData userIntegrations",userIntegrations);
        console.log("selectOrgEmployeesData quickRepliesAnalyticsData",quickRepliesAnalyticsData);
        console.log("selectOrgEmployeesData orgEmployeesIntegrations",orgEmployeesIntegrations);
        // console.log("selectOrgEmployeesData conversationAnalytics",conversationAnalytics);
        if(organization?.orgEmployeesData?.data?.length) {
            let tempData = organization.orgEmployeesData.data;
            const combinedIntegrations = setIntegrations(userIntegrations,organization.integrationTypes);
        // console.log("selectOrgEmployeesData combinedIntegrations",combinedIntegrations);


            let tempSyncListData =organization.orgEmployeesData.sync_list;
            let tempMappingListData = organization.orgEmployeesData.mapping_details;

            let tempFinalData = [];
            console.log("selectOrgEmployeesData tempData", tempData);
            console.log("selectOrgEmployeesData colorPaletteData", colorPaletteData);

            const getColorDataById =(id)=> {
                const colorData = colorPaletteData?.color_data;
                if(colorData?.length){
                    const foundItem = colorData.find(item=> item.id == id);
                    
                    return foundItem ? {
                        bg_color : foundItem.bg_color,
                        text_color : foundItem.text_color
                    } : {}
                }
                else {
                    return {}
                }
            }

            tempData.forEach((item)=> {
                let tempItem = item;
                let uniqueSyncItems;

                
              /// To manipulate integrations data and add in employee data

              let employeeIntegrationsData = null;
              if(orgEmployeesIntegrations?.data?.length){
                employeeIntegrationsData  = orgEmployeesIntegrations.data.find(orgEmployeeIntegrationsItem=> orgEmployeeIntegrationsItem.workspace_id == item.workspace_id )?.data;
               
              }

              /// To manipulate qr data and add in employee data
                    const order = [];
                    quickRepliesAnalyticsData?.data?.forEach(qrData => {
                      let count = 0;
                      qrData.quick_reply_updated_counts.forEach(qrCount => {
                        if (qrCount.user_id == item.workspace_id) {
                          count += qrCount.count;
                        }
                        else {
                            count = 0;
                        }
                      });
                      order.push(count);
                    });
                    let total = 0;
                    order.forEach(count => {
                      total += count;
                    });

                   const quickReplyData = {
                            total : total ?? 0,
                            order : order ?? [],
                        }
                        
                    
                        /// Scheduler data manipulation

                    let userSchedulerData = null;

                    const orgSchedulerData = orgSchedulerAnalyticsData?.data

                    if(orgSchedulerData?.length){
                        userSchedulerData= orgSchedulerData.find(schedulerData=> schedulerData.workspace_id == item.workspace_id)
                        // console.log("selectOrgEmployeesData userSchedulerData", userSchedulerData);
                    }


              

                const teamColorData = getColorDataById(tempItem?.callyzer_orgamnization_team?.team_color);
                if(teamColorData && Object.keys(teamColorData)?.length){
                    tempItem.callyzer_orgamnization_team = {
                        ...tempItem.callyzer_orgamnization_team,
                        ...teamColorData
                    }
                }
                let tempMappingItem = tempMappingListData.filter((innerItem)=> innerItem.user_id ==item.workspace_id )[0];
                let tempSyncItem = tempSyncListData.filter(innerItem => item.workspace_id === innerItem[0].workspace_id)[0];
                function filteredSyncItem(syncArr) {
                    let call = false;
                    let whatsapp = false;
                    let conversation = false
                    let newArr = [];

                    syncArr.forEach(data => {
                        if (data.sync_type === "call" && call === false) {
                            call = true;
                            newArr.push(data);
                        }
                        if (data.sync_type === "whatsapp" && whatsapp === false) {
                            whatsapp = true;
                            newArr.push(data);
                        }
                        if (data.sync_type === "conversationAnalytics" && conversation === false) {
                            conversation = true;
                            newArr.push(data);
                        }
                    })
                    return newArr;
                }
                if (tempSyncItem) {
                    uniqueSyncItems = (filteredSyncItem(tempSyncItem))
                }
                tempItem = {
                    ...tempMappingItem,
                    sync_data: uniqueSyncItems,
                    integrations: combinedIntegrations[item.workspace_id],
                    quick_reply_data : quickReplyData,
                    scheduler_data : {
                        created_schedulers : userSchedulerData?.schedulers ?? 0
                    },
                    integrations_last_sync : employeeIntegrationsData,
                    ...item,
                }
                tempFinalData.push(tempItem);
            })
            let finalData = {
                data: tempFinalData
            };
            console.log("selectOrgEmployeesData tempFinalData",tempFinalData);
            return finalData;

        } else {
            return organization.orgEmployeesData;
        }
         

    }

)
export const selectOrgInvitedEmployeesData = createSelector(
    [organization],
    organization=> organization.orgInvitedEmployeesData
)

export const selectUpdatedOrganizationData = createSelector(
    [organization],
    organization=> organization.updatedOrganizationData
)

export const selectRemovedUserData = createSelector(
    [organization],
    organization=> organization.removedUserData
)

export const selectUpdatedUserData = createSelector(
    [organization],
    organization=> organization.updatedUserData
)



export const selectCurrentActiveSeats = createSelector(
    [organization],
    organization=> organization.currentActiveSeats
)

export const selectCurrentSubscribedSeats = createSelector(
    [organization],
    organization=> organization.currentSubscribedSeats
)

export const selectUserPlanList = createSelector(
    [organization],
    organization=> organization.userPlanList
)

export const selectUserCreditHistory = createSelector(
    [organization],
    organization=> organization.userCreditHistory
)

export const selectGroupedTeamList = createSelector(
    [selectTeamsListData],
    teamList=> {
        let teamMap = new Map();
        
        if(teamList && teamList.length) {
            teamList.forEach(team => {

                let teamName = "No Team"
    
               if(team.callyzer_orgamnization_team) {
                    teamName = team.callyzer_orgamnization_team.team_name
               }
    
               if(!teamMap.has(teamName)) {
                if(team.whatzapp_basic_info) {
                    teamMap.set(teamName, {
                        teamId  : team.team_id,
                        data: [team.whatzapp_basic_info]
                    })
                } else {
                    teamMap.set([])
                }
               } else {
                let mapItem = teamMap.get(teamName)
                if(team.whatzapp_basic_info) {
                    mapItem.data.push(team.whatzapp_basic_info)
                }
               }
            })
        }
        console.log("initialParentList", teamMap)
        
        return teamMap;
    }
)

export const selectOrganizationEmployeesList = createSelector(
    [organization],
    organization=> organization.organizationEmployeesList
)

export const selectedEmployeeFilteredCallLogs = createSelector(
    [organization,selectedEmployee],
    (organization,selectedEmployee) => {
        let filterList = []
        if(selectedEmployee === "All") {
            filterList = organization.filteredCallLogs
        }else {
            selectedEmployee.forEach(it => {
                filterList = filterList.concat( organization.filteredCallLogs.filter(item =>  item.user_id === it))
            })

            console.log("filtered calls",filterList,organization.filteredCallLogs)
        }

        return filterList;
    }
)

export const selectFilteredCallLogs = createSelector( 
    [selectedEmployeeFilteredCallLogs,selectTeamsListData,selectColorPaletteData,selectedTeam],
    (selectedEmployeeFilteredCallLogs,teamsList,colorPaletteData,selectedTeam) => {
    let list = selectedEmployeeFilteredCallLogs.map(it => {
        let findItem = teamsList.find(item => item.user_id === it.user_id)
        return {
            ...it,
            team_id: findItem ? findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.id ? findItem.callyzer_orgamnization_team.id : null : null : null,
            team_name: findItem ? findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA" : "NA",
            team_color: findItem ? findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_color ? getColorFromColorPallete(findItem.callyzer_orgamnization_team.team_color,colorPaletteData.color_data) : "#BCC7F8" : "#BCC7F8" : "#BCC7F8",
            name: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name  : findItem.callyzer_user_role ? findItem.callyzer_user_role.role_name === "admin" ? "Admin" : "NA" : "NA" : "NA"  : "NA",
            mobile: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA" : "NA" ,
        }
    })

    list = list.filter(it => selectedTeam.id !== "All" ? (it.team_id === selectedTeam.id) : it)
    return list
}
)


export const selectedEmployeeDetails = createSelector(
    [selectTeamsListData, (_,props) => props.employeeId],
    (teamsList,id) => {
        let findItem = teamsList.find(item => item.user_id === parseInt(id))
        return {
            team_name: findItem ? findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA" : "NA",
            name: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name  : findItem.callyzer_user_role ? findItem.callyzer_user_role.role_name === "admin" ? "Admin" : "NA" : "NA" : "NA"  : "NA",
            mobile: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA": "NA" ,
        }
    }
)

export const selectFilteredEmployeeCallLogs = createSelector(
    [organization,selectTeamsListData],
    (organization,teamsList)=> organization.filteredEmployeeCallLogs.map(it => {
        let findItem = teamsList.find(item => item.user_id === it.user_id)
        return {
            ...it,
            team_name: findItem ? findItem.callyzer_orgamnization_team ? findItem.callyzer_orgamnization_team.team_name ? findItem.callyzer_orgamnization_team.team_name : "NA" : "NA" : "NA",
            name: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.name ? findItem.whatzapp_basic_info.name  : findItem.callyzer_user_role ? findItem.callyzer_user_role.role_name === "admin" ? "Admin" : "NA" : "NA" : "NA"  : "NA",
            mobile: findItem ? findItem.whatzapp_basic_info ? findItem.whatzapp_basic_info.mobile ? `${findItem.whatzapp_basic_info.mobile}` : "NA" : "NA": "NA" ,
        }
    })
)

export const selectEmployeeCallLogsTotals = createSelector(
    [organization],
    organization => organization.totalsOfEmployeeCallLogs
)


     /**
 * call type: 
 * 1 -> incoming calls
 * 2 -> outgoing calls
 * 3 -> missed calls
 * 5 -> rejected calls
 */



const getColorFromColorPallete = (colorId,list) => {
    let findItem = list.find(item => item.id === colorId )
    if (findItem) {
        return findItem
    } else {
        return "#BCC7F8"
    }
}
export const selectUserOrgList = createSelector(
    [organization],
    organization => organization.userOrgList
)

export const selectDontShowUpgrade = createSelector(
    [organization],
    organization => organization.dontShowUpgrade
)

export const selectUserProfileData = createSelector(
    [organization],
    organization => organization.userProfileData
)

export const selectToggleAddEmployeeModal = createSelector(
    [organization],
    organization => organization.openAddEmployeeModal
)