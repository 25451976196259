import React from 'react'
import { useState } from 'react';
import { Input, InputGroup } from "rsuite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Container,
  Row,
} from "reactstrap";
import { useEffect } from 'react';
import showToast from '../../../../../components/Toasts/ShowToast';
import { checkForCrmiKeyInAuth } from '../../../../../util/helper';

/**
 * Style for our popup modal
 */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  // height: "50vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

const styles = {
  marginBottom: 10,
};

const CustomInputGroup = ({ onChange, placeholder, ...props }) => (
  <InputGroup {...props} onChange={onChange} style={styles}>
    <Input placeholder={placeholder} />
    {/* <InputGroup.Addon>
      <SearchIcon />
    </InputGroup.Addon> */}
  </InputGroup>
);



  /**
   * 
   * @returns Google Sheet Modal where we select the spreadsheet and subsheet 
   */

  const FreshdeskModal = ({open, handleClose, setFreshdeskConnected, checkIfFreshdeskIntegrated, sendMessageToChromeExtension}) => {
    
  const [openPopup, setOpenPopup] = React.useState(open);

  useEffect(()=> {
    setOpenPopup(open)
  }, [open])

    const [domainName, setDomainName] = useState("eazybe6111");
    const [apiKey, setApiKey] = useState("QZ16zJrLRRWmTEcMsPK");
    const onChangeDomainName=(e)=>setDomainName(e.target.value)
    const onChangeApiKey=(e)=>setApiKey(e.target.value)
    const handleOpenPopup = () => {
      setOpenPopup(true);
    };
    const handleClosePopup = () => {
      setOpenPopup(false);
    };

    


    const onClickContinueBtn = async ()=> {

      if(!validateFreshdeskURL(domainName)){
        showToast("Domain should not be empty")
      }
      else
       if(! (await checkFreshdeskAPIKey())) {
        showToast("Wrong Api Key or domain")
      
      }
      else {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let body = {
        workspace_id : localStorage.getItem(
          "workspaceId"
        ),
        domain : domainName,
        api_key : apiKey,
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body : JSON.stringify(body)

      };
        fetch(
            `https://eazybe.com/api/v1/whatzapp/freshdesk/createauth`, requestOptions
          )
            .then((res) => res.json())
            .then((response) => {
            //   console.log("response : ", response);
              if (response?.result) {
                checkIfFreshdeskIntegrated();
                sendMessageToChromeExtension(true, 500);
                showToast("Linked Freshdesk successfully");
                window.gtag("event", "Freshdeskintegrated");
                window.gtagAW("event", "Freshdeskintegrated");
                setFreshdeskConnected(true);
                checkForCrmiKeyInAuth();
                handleClose();

              } else {
                showToast("Please check the value you have entered")
              }
            });

          }

     
    }


    function validateFreshdeskURL(url) {
      if(url){
        return true;
      }
      return false;
      // // Check if the URL starts with "https://"
      // // if (!url.startsWith("https://")) {
      // //   return false;
      // // }
    
      // // Check if the URL ends with ".freshdesk.com/"
      // if (!url.endsWith(".freshdesk.com/")) {
      //   return false;
      // }
    
      // // Extract the subdomain part between "https://" and ".freshdesk.com/"
      // var subdomain = url.substring(8, url.indexOf(".freshdesk.com/"));
      
      // // Check if the subdomain is not empty
      // if (subdomain.length === 0) {
      //   return false;
      // }
    
      // // If all conditions are satisfied, return true
      // return true;
    }
  

    function checkFreshdeskAPIKey() {
      return new Promise((resolve) => {
        const url = `https://eazybe.com/api/v1/whatzapp/freshdesk/tickets?domain=${"https://"+domainName+ ".freshdesk.com/"}&api_key=${apiKey}`;
       const headers ={
          'Content-Type': 'application/json',
        }
        fetch( url, headers )
          .then(response => {
            if (response.ok) {
              resolve(true);  // API key is valid
            } else {
              resolve(false);  // API key is invalid
            }
          })
          .catch(() => {
            resolve(false);  // Error occurred
          });
      });
    }

    return (
      <Modal
        // hideBackdrop
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="google-sheet-modal"
      >
        <Box className="modal-container" sx={{ ...style }}>
          {/* <Row className="top">
            <input className="search-files-input" placeholder="Search..." />
          </Row> */}

          <Container className="files-container d-flex-col">
          <p className="mt-1 select-txt mb-1">Freshdesk Domain Name</p>
            <CustomInputGroup
              value={domainName}
              onChange={onChangeDomainName}
              size="sm"
              // placeholder="https://testaccountoneeee.freshdesk.com/"
              placeholder="domain"
            />
            {/* <span style={{fontSize : "10px"}}>Eg Domain : https://testaccountoneeee.freshdesk.com/</span> */}
          <p className="mt-1 select-txt mb-1">Freshdesk API Key</p>
            <CustomInputGroup
              value={apiKey}
              onChange={onChangeApiKey}
              size="sm"
              placeholder="Api key"
            />
          </Container>

          <Row className="btns-container d-flex">
            <button className="cancel-btn" onClick={handleClosePopup}>Cancel</button>
            <button className="sync-btn" onClick={onClickContinueBtn}>Continue</button>
          </Row>

        </Box>
      </Modal>
      // </Backdrop>
    );
  };

export default FreshdeskModal