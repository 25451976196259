/* eslint-disable */ // WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_appsync_graphqlEndpoint:
    "https://rho7wuofyja5nihh3dvrlimtbm.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-q2zpjtuwsra67amuf4xlgmv6je",
};
export default awsmobile;
