import React, { useEffect } from "react";
import "./salesforcenotconnected.scss";
import salesforceLogoSvg from "../../../../../assets/icons/salesforce-logo.svg";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { useState } from "react";
import ConfirmationModal from "../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal";
import showToast from "../../../../../components/Toasts/ShowToast";
import {
  deleteAuthSalesforce,
  fetchSalesforceContactRecordFieldsById,
  fetchSalesforceContactRecordTypes,
  fetchSalesforceUserSettings,
  saveSalesforceUserSettings,
} from "../../../../../redux/integrations/integrations.action";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import MuiMultiSelect from "../../../../../components/eazybe-components/CustomSelect/MuiMultiSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getUserRoleId } from "../../../../../util/helper";
import EazybeLoader from "../../../../../components/loader";

const SalesforceNotConnected = ({
  onClickConnect,
  salesforceConnected,
  setSalesforceConnected,
  sendMessageToChromeExtension,
  userRoleId,
  setSaleforceSectionLoader,
  salesforceSectionLoader,
}) => {
  const [unlinkModalActive, setUnlinkModalActive] = useState(false);
  const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
  const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);
  const [contactRecordTypes, setContactRecordTypes] = useState([]);
  const [contactCreationEnabled, setContactCreationEnabled] = useState(true);
  const handleChangeContactCreationEnabled = (e) =>
    setContactCreationEnabled(e.target.checked);
  const [salesforceUserSettings, setSalesforceUserSettings] = useState(null);

  useEffect(() => {
    if (userRoleId == 1) {
      intitializeSalesforceContactTypes();
    }
  }, [userRoleId]);

  useEffect(() => {
    console.log("contactRecordTypes changed", contactRecordTypes);
  }, [contactRecordTypes]);

  const { t } = useTranslation();

  let featuresArr = [
    "Create New Contacts",
    "Link Existing Contacts",
    "Update Contact Information",
    "Create Tasks",
    "Create Notes",
  ];

  // const fieldData = [
  //   {
  //     id: "record1",
  //     name: "Record 1",
  //     fields: [{
  //       id: "r1f1",
  //       name: "Field1",
  //     }],
  //     selectedField: [],
  //     isSelected : true,
  //   },
  //   {
  //     id: "record2",
  //     name: "Record 2",
  //     fields: [{
  //       id: "r2f1",
  //       name: "Field1",
  //     }],
  //     selectedField: [],
  //     isSelected : true,
  //   },
  //   {
  //     id: "record3",
  //     name: "Record 3",
  //     fields: [{
  //       id: "r3f1",
  //       name: "Field1",
  //     }],
  //     selectedField: [],
  //     isSelected : true,
  //   },
  // ]

  const goBackToWhatsapp = () => {
    if (sendMessageToChromeExtension) {
      sendMessageToChromeExtension();
    } else {
      window.open("https://web.whatsapp.com/");
    }
  };

  const onClickUnlinkSalesforce = () => {
    setUnlinkModalActiveTrue();
  };

  const unlinkSalesforce = async () => {
    const delteAuthRes = await deleteAuthSalesforce();

    if (delteAuthRes?.status) {
      // sendMessageToChromeExtension(true, 500);
      showToast("Unlinked Salesforce successfully");
      setSalesforceConnected(false);
      setUnlinkModalActiveFalse();
    } else {
      showToast("Something went wrong");
      setUnlinkModalActiveFalse();
    }
  };

  const handleSubmit = () => {
    console.log("handleSubmit contactRecordTypes", {
      contactRecordTypes,
      contactCreationEnabled,
    });
    const finalPayload = {
      contactRecordTypes: contactRecordTypes
          .map((item) => {
              if (item.isSelected) {
                  return {
                      id: item.id,
                      isSelected: true,
                      name: item.name,
                      selectedField: item.selectedField,
                  };
              }
          })
          .filter((item) => item), // Remove undefined items
      contactCreationEnabled,
  };
    console.log("handleSubmit finalPayload", {
      finalPayload
    });
    saveSalesforceUserSettings(finalPayload);
    showToast("Settings saved successfully");

  };

  /**
   * @function intitializeSalesforceContactTypes
   * @description To fetch recordTypes and fields of those recordTypes and then
   * connvert them to the required format
   * @returns void
   */

  const intitializeSalesforceContactTypes = async () => {
    try {
      setSaleforceSectionLoader(true);
      const tempContactRecordTypes = (await fetchSalesforceContactRecordTypes())
        ?.data?.records;
      console.log(
        "intitializeSalesforceContactTypes tempContactRecordTypes",
        tempContactRecordTypes
      );

      if (tempContactRecordTypes?.length) {
        const tempSalesforceUserSettingsData =
          await fetchSalesforceUserSettings();
          console.log(
            "intitializeSalesforceContactTypes tempSalesforceUserSettingsData",
            tempSalesforceUserSettingsData
          );
        if (tempSalesforceUserSettingsData) {
          setSalesforceUserSettings(tempSalesforceUserSettingsData);
          setContactCreationEnabled(
            tempSalesforceUserSettingsData?.contactCreationEnabled ?? true
          );
          const tempArr = await Promise.all(
            tempContactRecordTypes.map(async (item) => {
              const tempFieldsData =
                (await fetchSalesforceContactRecordFieldsById(item.Id))?.data
                  ?.objectInfos?.User?.fields ?? [];
              console.log(
                "intitializeSalesforceContactTypes tempFieldsData",
                tempFieldsData
              );
              const finalFieldsData = Object.values(tempFieldsData).map(
                (innerItem) => {
                  return {
                    id: innerItem.apiName,
                    name: innerItem.label,
                  };
                }
              );
              return {
                id: item.Id,
                name: item.Name,
                developerName: item.DeveloperName,
                fields: finalFieldsData,
                selectedField:
                  tempSalesforceUserSettingsData?.contactRecordTypes?.find(
                    (userSettingItem) => userSettingItem?.id === item.Id
                  )?.selectedField ?? [],
                isSelected: tempSalesforceUserSettingsData?.contactRecordTypes?.find(
                  (userSettingItem) => userSettingItem?.id === item.Id
                ) ? true : false,
              };
            })
          );

          console.log("intitializeSalesforceContactTypes tempArr", tempArr);
          setContactRecordTypes(tempArr);
          setSaleforceSectionLoader(false);
        } else {
          const tempArr = await Promise.all(
            tempContactRecordTypes.map(async (item) => {
              const tempFieldsData =
                (await fetchSalesforceContactRecordFieldsById(item.Id))?.data
                  .objectInfos?.User?.fields ?? [];
              console.log(
                "intitializeSalesforceContactTypes tempFieldsData",
                tempFieldsData
              );
              const finalFieldsData = Object.values(tempFieldsData).map(
                (innerItem) => {
                  return {
                    id: innerItem.apiName,
                    name: innerItem.label,
                  };
                }
              );
              return {
                id: item.Id,
                name: item.Name,
                developerName: item.DeveloperName,
                fields: finalFieldsData,
                selectedField: [],
                isSelected: false,
              };
            })
          );

          console.log("intitializeSalesforceContactTypes tempArr", tempArr);
          setContactRecordTypes(tempArr);
          setSaleforceSectionLoader(false);

        }
      } else {
        setContactRecordTypes([]);
        setSaleforceSectionLoader(false);

      }
    } catch (error) {
      setSaleforceSectionLoader(false);
      console.error("Error initializing Salesforce contact types:", error);
    }
  };

  return (
    <>
      <div className="salesforce-not-connected-con d-flex d-flex-col">
        <div className="header-con d-flex">
          <img src={salesforceLogoSvg} className="hubspot-icon-big" />
          <div className="connection-status d-flex d-flex-col">
            <div className="name-con d-flex">
              <h3>Salesforce</h3>

              {salesforceConnected ? (
                <>
                  <div className="d-flex align-items-center">
                    <DoneIcon htmlColor="green" fontSize="24px" />
                    <p className="ml-0-5">{t("Connected.message")}</p>
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center">
                  <ClearIcon htmlColor="red" fontSize="24px" />
                  <p className="ml-0-5">{t("Not_Connected.message")}</p>
                </div>
              )}
            </div>
            <p>Link Contacts, Create Contacts, Create Tasks</p>
          </div>

          {salesforceConnected ? (
            <div>
              <button
                onClick={goBackToWhatsapp}
                className="connect-salesforce-btn"
              >
                Go back to WhatsApp
              </button>
              <button
                onClick={onClickUnlinkSalesforce}
                className="unlink-salesforce-btn"
              >
                Unlink
              </button>
            </div>
          ) : (
            <button onClick={onClickConnect} className="connect-salesforce-btn">
              Connect to Salesforce
            </button>
          )}

          {/* <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Hubspot</button> */}
        </div>
        <div className="salesforce-features-section">
          <div className={salesforceConnected ? "info-con right-border-light-gray-2 d-flex-col" : "info-con d-flex-col"}>
            <h5>{t("Features.message")}</h5>
            <div className="features">
              {featuresArr.map((feature) => (
                <div className="feature-item d-flex">
                  <img className="feature-img" src={salesforceLogoSvg} />
                  <p className="feature-text">{feature}</p>
                </div>
              ))}
            </div>
            {}
          </div>
          {/* maaz salesforce mobile field mapping */}
          {
            salesforceConnected && (salesforceSectionLoader ?  <EazybeLoader style={{height : "inherit"}}/> : userRoleId == 1 ?  (
              <Box
                width={"100%"}
                sx={{
                  mt: "21px",
                  pl: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  height : "55vh",
                  overflow : "auto"
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactCreationEnabled}
                      onChange={handleChangeContactCreationEnabled}
                    />
                  }
                  label="Allow contact creation from Eazybe in Salesforce"
                />
                <Typography
                  paragraph
                  gutterBottom
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Select Type of Record
                </Typography>
                <MuiMultiSelect
                  options={contactRecordTypes}
                  setOptions={setContactRecordTypes}
                  visibleField="name"
                  label="Select Type of Record"
                  labelText="Select Type of Record"
                />
                <Button
                  sx={{ width: "100px", marginLeft: "200px" }}
                  variant="text"
                  onClick={handleSubmit}
                  size="small"
                >
                  <u>Save</u>
                </Button>
              </Box>
            )  : null

            )
          }
        </div>
      </div>
      <ConfirmationModal
        open={unlinkModalActive}
        handleClose={setUnlinkModalActiveFalse}
        onClickOk={unlinkSalesforce}
        onClickCancel={setUnlinkModalActiveFalse}
        title="Unlink Saleforce"
        message={t("Are_you_sure_you_want_to_unlink.message") + " Salesforce"}
        okText={t("Unlink.message")}
      />
    </>
  );
};

export default SalesforceNotConnected;


