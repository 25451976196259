import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



 const showToast = (message, toastType) => {
   console.log(toastType);
    localStorage.setItem("active_toast", message);
    localStorage.setItem("toast_type", toastType );
    const rootItem = document.getElementById('root');
    const msg = "Testiingggg"
      const event = document.createEvent("Event");
       event.initEvent("show_toast", true, true); 
       rootItem.dispatchEvent(event);
  }

export default showToast