import React from 'react';

const JSONPreview = ({ data }) => {
  const jsonString = JSON.stringify(data, null, 2);

  return (
    <div id="eazybe-json-previewer" className="eazybe-json-previewer">
    <pre>{jsonString}</pre>
  </div>
  );
};

export default JSONPreview;
