import React,{useState, useEffect} from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import {toggleLeftmenu,updateSelectedEmployee,updateFilterDates} from '../../redux/layout/layout.action';
import { selectChangeInSideBar,selectFilterDate,selectedEmployee, selectCurrentNavigation } from '../../redux/layout/layout.selector';
import {selectEmployeeFilterList} from '../../redux/dashboard/dashboard.selector';
import { getCallsOverViewAws, setOrganizationId } from "../../redux/dashboard/dashboard.action";
import './header.scss';
import { getAllTeams, getUsersAllOrganization } from "../../redux/organization/organization.action";
import { selectTeamsListData, selectUserCreditHistory } from "../../redux/organization/organization.selector";
import ProfileMenu from "./ProfileMenu";
import MyAccountDropdown from "../MyAccountDropdown/MyAccountDropdown";
import TrialEndedStickyTopComp from "../Popups/TrialEndedStickyTopComp/TrialEndedStickyTopComp";
import { selectUserExistenceData } from "../../redux/auth/auth.selector";
import SwitchUser from "../Popups/SwitchUser/SwitchUser";


const Header = props => {
  const [search, setsearch] = useState(false);
  const [teams,setTeams] = useState([]);
  const [fullEmpList, setFullEmpList] = useState([{"id": "All" ,"name": "All"}]);
  const [isOpen, setIsOpen] = useState(false);

  let location= useLocation();


  /**
   * set organisation id received in the url as ?dashboard_id=
   */
   const setOrganisationIdFromUrl = () => {
    let query = window.location.search.split('=');
    if (query.length > 1 && query[0] === '?organization_id')  {
      console.log('location', query[1]);
      props.setOrganisationId(query[1])
    }
  }

useEffect(() => {
/**
* set organisation id received in the url as ?dashboard_id=
*/
setOrganisationIdFromUrl()
 /**
      * to get all the teams of organization
      */
  props.getTeamList()
  props.getOverViewData()
  
},[])


  useEffect(() => {
    if(props.teamsList?.length) {
      props.getOverViewData()
    } 
  },[props.filterDate,props.teamsList,props.activeEmployee])
  

  useEffect(() => {
      if(props.teamsList?.length) {
        let list = []
        props.teamsList.forEach((teams) => {
          if (!list.find(it => it.id === teams.team_id)) {
              list.push({id: teams.team_id, name: teams.callyzer_orgamnization_team?.team_name ?? "NA"})
          } 
        })
        setTeams(list)
      }
  },[props.teamsList])
    
      /**
       * to filter the dashboard on the basis of active employee
       */
      const selectedEmployee = (e,v) => {
        console.log(v)
       if (v && v.id) {
        props.updateSelectedEmployee(v.id)
       }
      }

      const selectedTeam = (e,v) => {
       if (v && v.id) {
        console.log("selectedTeam",v.name,v.id)
       }
      }

      /**
       * to filter the dashboard data on the basis of the dates.
       */
      const changeFilterDates = () => {
        const startDate = document.querySelector('#startDateFilter');
        const endDate = document.querySelector('#endDateFilter');
        props.updateFilterDates(startDate.value,endDate.value);
      }

      const [showHeader, setShowHeader] = useState(true);

      useEffect(()=> {
        if(location.pathname == "/conversations"){
          setShowHeader(false);
        }
        else {
  
          setShowHeader(true);
        }
      }, [])

    return (
      <React.Fragment>
          <header id="page-topbar" 
          className="display-none"
          >
            <TrialEndedStickyTopComp 
            openPopup={props.openPopup} 
            handleClose={props.handleClose} content={props.content} 
            userPlanDetails = {props.userPlanDetails}
            />
            <SwitchUser openPopup={isOpen} />
            <div className="navbar-header">
              <div className="d-flex ht-100">
    
                <div className="navbar-brand-box d-lg-none d-md-block">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={'logo'} alt="" height="22" />
                    </span>
                  </Link>
    
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={'logoLightSvg'} alt="" height="22" />
                    </span>
                  </Link>
                </div>
    
           

                <div className="header-active-section ht-100">
                    {
                      props.currentNavigation && props.currentNavigation?.section?.length > 0 ? (
                       <div className="info ht-100">
                        <h5 className={props?.currentNavigation?.main === "Team Inbox" && "team-inbox-header"}>
                          {props?.currentNavigation?.main}
                        </h5>
                        <div className="section_title">
                          {props.currentNavigation.section?.map(section => (
                            <Link to={section.path}>
                              <h6 className={section.path === props.currentNavigation.path ? "activeLink" : ""}>{section.title}</h6>
                            </Link>
                          ))}
                        </div>
  
                       </div>
                      ) : (
                        <h5 className={props?.currentNavigation?.main === "Team Inbox" && "team-inbox-header"}>
                          {props?.currentNavigation?.main}
                        </h5>
                      )
                    }
                </div>
    
              </div>

              <div className="d-flex align-item-center">
                {props.userExistence.data?.callyzer_user_mappings[0]?.is_default_org ? localStorage.getItem("currentOrgName") : props.userExistence.data.name}
                <MyAccountDropdown setIsOpen={setIsOpen} />
              
              {/* <ProfileMenu /> */}
              </div>
            </div>

          </header>
        </React.Fragment>
     
    )
}

const mapStateToProps = createStructuredSelector({
  sideBar: selectChangeInSideBar,
  filterDate: selectFilterDate,
  activeEmployee: selectedEmployee, // selected active employee from central storage
  teamsList: selectTeamsListData,
  currentNavigation : selectCurrentNavigation,
  userExistence: selectUserExistenceData,
  userPlanDetails : selectUserCreditHistory
})

const mapDispatchToProps = dispatch => ({
  toggleLeftmenu : (isOpen) => dispatch(toggleLeftmenu(isOpen)),
  updateSelectedEmployee: (employee) => dispatch(updateSelectedEmployee(employee)),
  updateFilterDates: (startDate,endDate) => dispatch(updateFilterDates(startDate,endDate)),

    setOrganisationId: (id) => dispatch(setOrganizationId(id)),
  
  getTeamList: () => dispatch(getAllTeams()),
  getOverViewData: () => dispatch(getCallsOverViewAws()),
  getAllOrganizationsList: () => dispatch(getUsersAllOrganization()),
  
})

export default connect(mapStateToProps,mapDispatchToProps)(Header);