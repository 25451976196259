/*global chrome*/
import { lightFormat } from "date-fns";

let accessToken = null;

export const getUserAccessToken =()=> {
  return localStorage.getItem("accessToken");
  // return accessToken;
}

export const setUserAccessToken =(data)=> {
  localStorage.setItem("accessToken", data);
  return new Promise((resolve, reject)=> {
    console.log("setUserAccessToken", {data});
    localStorage.setItem("accessToken", data);
    // accessToken = data;
    resolve(true);
  })
}

export const getExtensionIdToSendMessageToFromLocal =()=> {
  return localStorage.getItem("extensionIdToSendMessageTo");
}

export const setExtensionIdToSendMessageToInLocal =(data)=> {
    console.log("setExtensionIdToSendMessageToInLocal", {data});
    localStorage.setItem("extensionIdToSendMessageTo", data);
    return true;
}


/**
 * this function is used to convert time to seconds.
 * @param {*} time time in h:m:s format
 * @returns  time in seconds.
 */
export function toSeconds(time) {
    if (time === "0 sec" || time === "0" || !time) return 0;

    var timeList = time.split(":")

    if (!timeList)
        timeList = "0"

    var hour = 0;
    var minutes = 0;
    var seconds = 0;

    if (timeList.length === 3) {
        hour = Number(timeList[0]?.split(" ")[0])
        minutes = Number(timeList[1]?.split(" ")[0]);
        seconds = Number(timeList[2]?.split(" ")[0]);
    } else if (timeList.length === 2) {
        minutes = Number(timeList[0]?.split(" ")[0]);
        seconds = Number(timeList[1]?.split(" ")[1]);
    } else {
        seconds = Number(timeList[0]?.split(" ")[0]);
    }

    let value = seconds + (minutes * 60) + (hour * 60 * 60);

    return value
}

function sum(a, b) {
    return a + b;
}

/**
 * To check if input is valid url
 */

export const checkIfValidURL = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * To check if input is valid email
 */

export const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};
/**
 * To check if a given string contains number
 * @param {*} myString 
 * @returns 
 */

export const hasNumber = (myString) => {
    return /\d/.test(myString);
}

/**
* To check if input is number
*/

export const checkIfNumber = (input) => {
    if (isNaN(input)) {
        return false;
    }
    return true;
}

/**
 * function is used to convert seconds to a h:m:s format.
 * @param {*} d  time in seconds
 * @returns  time in h m s format
 */
export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

/**
 * used to find the userid with the highest call duration 
 * @param {*} list  of {call_duration, user_id}
 * @returns userid of the highest call duration 
 */
export function findHighestCallDurationUser(list) {
    let maxId = 0;
    let max = 0;
    if (list.length) {
        list.forEach(it => {
            let current = toSeconds(it.call_duration)
            if (current > max) {
                max = current
                maxId = it.user_id
            }
        })
    }

    return maxId;


}

export function findTopCaller(list) {
    let maxId = 0;
    let max = 0;
    if (list.length) {
        list.forEach(it => {
            let current = it.outgoing_count
            if (current > max) {
                max = current
                maxId = it.user_id
            }
        })
    }

    return maxId;


}

/**
  * function is used to convert seconds to a h:m:s format.
  * @param {*} d  time in seconds
  * @returns  time in h:m:s format
  */
export function secondsToHmsInFormat(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h : "00";
    var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
    var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";

    let duration = "";

    if (h === 0 && m === 0) {
        duration = `${sDisplay} Seconds`;
    } else if (h === 0) {
        duration = `${mDisplay} Min : ${sDisplay} Sec`
    } else {
        duration = `${hDisplay} H : ${mDisplay} M : ${sDisplay} S`
    }

    return duration;
}

/**
 * 
 * @param {*} list of call logs
 * @returns sums up all the time in h:ms:s format of whole array
 */
export const sumUpTime = (list) => {
    if (list.length > 0) {
        var totalTime = list.map(item => {
            return toSeconds(item.call_duration)
        }).reduce(sum);
        return totalTime ? secondsToHms(totalTime) : "0s";
    } else {
        return "-"
    }
}

export const sumUpTimeRaw = (list) => {
    if (list.length > 0) {
        var totalTime = list.map(item => {
            return toSeconds(item.call_duration)
        }).reduce(sum);
        return totalTime ?? "-";
    } else {
        return "-"
    }
}

/**
 * 
 * @param {*} list of call logs
 * @returns sums up all the time in h:ms:s format of whole array
 */
export const sumUpTimeInFormat = (list) => {
    if (list.length > 0) {
        var totalTime = list.map(item => {
            return toSeconds(item.call_duration)
        }).reduce(sum);
        return secondsToHmsInFormat(totalTime);
    } else {
        return "-"
    }
}


/**
 * 
 * @param {*} list of call logs
 * @returns avg all the time in h:ms:s format of whole array
 */
export const avgTime = (list) => {
    if (list.length > 1) {
        var totalTime = list.map(item => {
            return toSeconds(item.call_duration)
        }).reduce(sum);
        return secondsToHms(totalTime / list.length);
    } else {
        return ""
    }
}


/**
 * 
 * @returns full current date with time
 * example 9:07.06PM Wednesday 8th of December, 2021
 */
export const fullCurrentDateAndTime = () => {
    var objToday = new Date(),
        weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'),
        dayOfWeek = weekday[objToday.getDay()],
        domEnder = function () { var a = objToday; if (/1/.test(parseInt((a + "").charAt(0)))) return "th"; a = parseInt((a + "").charAt(1)); return 1 == a ? "st" : 2 == a ? "nd" : 3 == a ? "rd" : "th" }(),
        dayOfMonth = today + (objToday.getDate() < 10) ? '0' + objToday.getDate() + domEnder : objToday.getDate() + domEnder,
        months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
        curMonth = months[objToday.getMonth()],
        curYear = objToday.getFullYear(),
        curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours()),
        curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes(),
        curSeconds = objToday.getSeconds() < 10 ? "0" + objToday.getSeconds() : objToday.getSeconds(),
        curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

    var today = curHour + ":" + curMinute + "." + curSeconds + curMeridiem + " " + dayOfWeek + " " + dayOfMonth + " of " + curMonth + ", " + curYear;

    return today;
}

/**
 * 
 * @returns current date in yyyy-mm-dd format
 */
export const currentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
}


/**
 * 
 * @param {time} time to be formatted
 * @returns returns timme with h:m:s
 */
export const formatTime = (time) => {
    let timeList = time.split(":");
    let formattedTime, h, m, s, hDisplay, mDisplay, sDisplay;
    switch (timeList.length) {
        case 0:
            s = timeList[0]?.split(" ")[0]
            sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "00s";
            formattedTime = `${sDisplay}`
            break;

        case 1:
            m = timeList[0]?.split(" ")[0]
            s = timeList[1]?.split(" ")[0]
            mDisplay = m > 0 ? m + (m === 1 ? "m" : "m ") : "00m";
            sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "00s";
            formattedTime = `${mDisplay}  ${sDisplay}`
            break;

        case 2:
            h = timeList[0]?.split(" ")[0]
            m = timeList[1]?.split(" ")[0]
            s = timeList[2]?.split(" ")[0]
            hDisplay = h > 0 ? h + (h === 1 ? "h" : "h ") : "00h";
            mDisplay = m > 0 ? m + (m === 1 ? "m" : "m ") : "00m";
            sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "00s";
            formattedTime = `${hDisplay}  ${mDisplay}  ${sDisplay}`
            break;

        default:
            formattedTime = time;
            break;
    }

    return formattedTime;
}

export const DateTypes = {
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    THIS_WEEK: "This Week",
    LAST_WEEK: "Last Week",
    THIS_MONTH: "This Month",
    LAST_MONTH: "Last Month",
    LAST_6_MONTHS: "Last 6 Months",
    THIS_YEAR: "This Year",
    LAST_YEAR: "Last Year"
}

export const setDateToFirst = (date) => {
    date.setDate(1)
    return date
}


/**
 * 
 * @param {*} list 
 * @returns map on the basis of date and messages
 */
export const createMessagesMapAccToDate = (list) => {
    let map = new Map()

    if (list.length) {
        // creating the map for grouping the values on the basis of the date
        list.forEach(message => {
            let formattedDate = lightFormat(new Date(message.Datetime), "yyyy-MM-dd")
            if (map.has(formattedDate)) {
                let element = map.get(formattedDate)
                element.total = element.total + 1;
            } else {
                map.set(formattedDate, { ...message, total: 1 })
            }
        })
    }

    return map;
}

/**
 * 
 * @param {*} list 
 * @returns map on the basis of date and user and their messages.
 */
export const createUserMapAccToDate = (list) => {
    let map = new Map()

    if (list.length) {

        // creating the map for grouping the values on the basis of the date and user 
        list.forEach(message => {
            let formattedDate = lightFormat(new Date(message.Datetime), "yyyy-MM-dd")
            if (map.has(formattedDate)) {
                let element = map.get(formattedDate)
                addMessageToUserIfExists(message, element)
            } else {
                map.set(formattedDate, addMessageToUserIfExists(message, {}))
            }
        })

    }

    return map;
}

/**
 * 
 * @param {*} list 
 * @returns map on the basis of time and user and their messages.
 */
export const createUserMapAccToTime = (list) => {
    let map = new Map()

    if (list.length) {

        // creating the map for grouping the values on the basis of the date and user 
        list.forEach(message => {
            let formattedDate = lightFormat(new Date(message.Datetime), "HH")
            if (map.has(formattedDate)) {
                let element = map.get(formattedDate)
                element.push(message)
            } else {
                map.set(formattedDate, [message])
            }
        })

    }

    return map;
}


const addMessageToUserIfExists = (message, obj) => {
    if (obj[message.Chatid]) {
        obj[message.Chatid].messages.push(message)
        obj[message.Chatid] = {
            ...obj[message.Chatid],
            lastDirection: message.Direction
        }
    } else {
        return {
            [message.Chatid]: {
                ...message,
                messages: [message],
                lastDirection: message.Direction
            }
        }
    }
}

/**
 * 
 * @param {*} list 
 * @returns map created for different users with their first message,
 * so as to get the first chat and when the chat was started.
 */
export const createUserMap = (list) => {
    let map = new Map()

    if (list.length) {
        // creating map of user with their first message to get the date when the chatter was first created or when the chat was started.
        let userMap = new Map();
        list.forEach(message => {
            let chat = message.Chatid
            if (!userMap.has(chat)) {
                userMap.set(chat, {
                    ...message
                })
            }
        })

        if (userMap.size) {
            let array = Array.from(userMap.values())

            array.forEach(message => {
                let formattedDate = lightFormat(new Date(message.Datetime), "yyyy-MM-dd")

                if (map.has(formattedDate)) {
                    let element = map.get(formattedDate)
                    element.push(message)
                } else {
                    map.set(formattedDate, [message])
                }
            })
        }
    }

    return map;
}

export const secondsToHours = (seconds) => {
    return (seconds / (60 * 60)).toFixed(2)
}

// for trying to call api with multiple retries with dynamic arguments.
export const fetch_retries = (retiresCount = 3, callback, ...args) => {
   return new Promise(async (resolve, reject) => {
    try{

        const callCallBack = () => {
            return new Promise((resolve, reject) => {
            if(!callback && typeof callback !== "function") {
                reject(`Invalid callback`)
                return;
            } else {
                callback(...args).then(
                    res =>{
                        resolve(res)
                    }
                ).catch((err) => {
                    if(retiresCount !== 0) {
                     fetch_retries(retiresCount - 1, callback,...args).then(
                        res =>  resolve(res)
                     )
                    } else {
                    reject(`Failed to fetch in retries ${err}`)
                    }
                })
            }
        })
        }

        if(!retiresCount && typeof retiresCount !== "number") {
            reject(`Invalid retries ${retiresCount}`)
            return;
        }

       let res =  await callCallBack();
       resolve(res)

    } catch(err){
        console.error("Failed in retries", err)
        reject(`Failed in retries ${err}`)
    }
   })
}

/**
 * To generate random string based on length
 * @param {*} length 
 * @returns 
 */
export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => characters[byte % characters.length]).join('');
  };
  

  export const sendDataToWhatsapp =(data)=> {
//     var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");

// var raw = JSON.stringify(data);

// var requestOptions = {
// method: 'POST',
// headers: myHeaders,
// body: raw,
// redirect: 'follow'
// };

// fetch("https://eazybe.com/api/v1/whatzapp/sendpayloadtowhatsapp?mobile=919958731859", requestOptions)
// .then(response => response.text())
// .then(result => console.log(result))
// .catch(error => console.log('error', error));
  }


  export function isStartDateOneDayBeforeEndDate(startDate, endDate) {
    // Get the current date and time
    const currentDate = new Date();
  
    // Parse the provided "startDate" and "endDate" strings into Date objects
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  
    // Calculate yesterday's date by subtracting one day from the current date
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
  
    // Check if "startDate" is one day before "endDate" and if "endDate" is today's date
    return (
      startDate.toDateString() === yesterday.toDateString() &&
      endDate.toDateString() === currentDate.toDateString()
    );
  }

  export const onClickContactUsHandler = (data, time = 10, id="fpnlneiiaeclcckbcoldjhcpaofgdmfl", key) => {
    return new Promise((resolve, reject) => {
        const text = data?.text
          if (chrome?.runtime) {
            chrome.runtime.sendMessage(
                id ,
              { key :"OPEN_SUPPORT_CHAT", data: data },
              (response) => {
                console.log("sendMessageToChromeExtension response : ", response);            
                if(!response){
                    window.open(`https://api.whatsapp.com/send/?phone=918527312522&text=${JSON.stringify(text)}&type=phone_number&app_absent=1`, "_blank")
                }
                 resolve(response);
                
              }
            );
          }
          else {
            window.open(" https://wa.me/918527312522", "_blank")
             resolve(false)
          }
    })
 
  };

  ///To check if a given date string is older than n years

  export function isDateOlderThanYears(dateString, years) {
   try {
    if(!dateString || !years) return false; 
   // Parse the input date string into a Date object
   const inputDate = new Date(dateString);

   // Calculate the cutoff date by subtracting the specified number of years
   const cutoffDate = new Date();
   cutoffDate.setFullYear(cutoffDate.getFullYear() - years);
 
   // Compare the input date with the cutoff date
   return inputDate.getTime() < cutoffDate.getTime();
   }
   catch(err){
    return false;
   }
  }

  export function urlToImgFile(url, fileName) {
    return new Promise((resolve, reject) => {
      if (url) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
  
        xhr.onload = function () {
          if (xhr.status === 200) {
            var blob = xhr.response;
            var file = new File([blob], fileName, { type: blob.type });
            resolve(file);
          } else {
            resolve(null);
          }
        };
  
        xhr.onerror = function () {
          resolve(null);
        };
  
        xhr.send();
      } else {
        resolve(null);
      }
    });
  }

  
  
  export const getLoggedInUserWorkspaceId =()=> {
    const tempWorkspaceId = localStorage.getItem('workspaceId');
    return tempWorkspaceId;
  }
  
  export const getLoggedInUserOrgId =()=> {
    const userOrgId = localStorage.getItem('currentOrgId');
    return userOrgId;
  }
  export const getUserRoleId =()=> {
    const userRoleId = localStorage.getItem('userRoleId');
    return userRoleId;
  }


export const closeSideNav =()=> {
    console.log("CloseSideNav clicked")
}


// Function to extract the date, month, and year from a date string in "YYYY-MM-DD" format
// If an error occurs, it catches the error and returns an empty string

export const extractDateMonthYear=(dateString)=> {
    try {
      const dateParts = dateString.split('-');
      if (dateParts.length !== 3) {
        throw new Error('Invalid date format');
      }
  
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const day = parseInt(dateParts[2], 10);
  
      if (isNaN(year) || isNaN(month) || isNaN(day)) {
        throw new Error('Invalid date components');
      }
  
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error:', error);
      return '';
    }
  }
  
  
/**
 * Get raw user plan id
 * @param {*} planId
 * @returns
 */

export const getUserPlanIdUsingUserPlanId = (planId) => {
    try {
      if (planId) {
        if (planId < 1000) {
          while (planId >= 12) {
            planId -= 10;
          }
          return planId;
        } else {
          planId -= 1000;
          while (planId >= 12) {
            planId -= 10;
          }
          return planId;
        }
      } else {
        return 1;
      }
    } catch (err) {
      console.error("Erro while getting user plan id", err);
      return 1;
    }
  };
  

  /**
 * To check users final plan id, irrespective of region
 * @returns
 */

export const getUserActualPlanId = (planId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const userActivePlanId =planId;
        const finalPlanId = getUserPlanIdUsingUserPlanId(userActivePlanId);
        resolve(finalPlanId);
      } catch (err) {
        console.log("Error while getting user actual plan id: ", err);
        resolve(1);
      }
    });
  };


  export const checkForPlanName = (planName, planData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const currentPlanIdOfUser =await  getUserActualPlanId(planData.plan_id)
        const proPlanIds = [3, 7, 8, 9];
        const teamsPlanIds = [10, 11];

        const userEmail = localStorage.getItem("userEmail");
        const isNotLoggedInUser = userEmail?.endsWith("eazybe");
  
        const plan_id = currentPlanIdOfUser;
        const is_trial = planData?.is_trial;
        const is_teams_trial = (teamsPlanIds.includes(currentPlanIdOfUser) && is_trial == 1);

        console.log("checkForPlanName", {
            plan_id,
            is_trial,
            is_teams_trial,
            isNotLoggedInUser
        })
  
        switch (planName) {
          case "Plus":
          case "Pro":
            resolve(proPlanIds.includes(currentPlanIdOfUser));
            break;
          case "TeamsTrial":
            resolve(is_teams_trial);
            break;
          case "Teams":
            resolve(teamsPlanIds.includes(currentPlanIdOfUser));
            break;
          case "Free":
            resolve(currentPlanIdOfUser == 1);
            break;
          case "NotLoggedIn":
            resolve(isNotLoggedInUser);
          default:
            resolve(false);
            break;
        }
      } catch (err) {
        console.log("Error while checking plan name", err);
        resolve(false);
      }
    });
  };

  export const getPlanNameUsingPlanId = (planId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const currentPlanIdOfUser =await  getUserActualPlanId(parseInt(planId))
        const proPlanIds = [3, 7, 8, 9];
        const teamsPlanIds = [10, 11];

        console.log("getPlanNameUsingPlanId", {
            currentPlanIdOfUser
        })
  
            if(proPlanIds.includes(currentPlanIdOfUser)){
                 resolve("Pro Plus")
            }
            else if(teamsPlanIds.includes(currentPlanIdOfUser)){
                resolve("Teams")
            }
            else {
                resolve("Free Plan")
            }
          
      } catch (err) {
        console.log("Error while checking plan name", err);
        resolve("Free Plan");
      }
    });
  };


export const getMessageFromLanguage =(inputString)=> {
    try {
        // Return original string if input is null or undefined
        if (!inputString) {
          return "";
        }
    
        // Split the string by underscore and join with space
        const processedString = inputString.split('_').join(' ');
    
        // Capitalize the first letter of each word
        const finalString = processedString.replace(/\b\w/g, (char) => char.toUpperCase());
    
        return finalString;
      } catch (error) {
        // Return original string in case of any error
        return inputString;
      }
}


export const getTimeAgoTextFromDate = (timestamp) => {
    try {
      if (!timestamp) {
        return null; // Handle undefined input
      }
  
      const currentDate = new Date();
      const inputDate = new Date(timestamp);
      const timeDiff = currentDate - inputDate;
  
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
  
      if (days < 2) {
        // Within the last 2 days
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        if (inputDate.getDate() === currentDate.getDate()) {
          // Today
          const formattedTime = inputDate.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          return `Today, ${formattedTime}`;
        } else if (inputDate.getDate() === yesterday.getDate()) {
          // Yesterday
          const formattedTime = inputDate.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          return `Yesterday, ${formattedTime}`;
        }
      }
  
      // For timestamps older than 2 days
      if (days > 0) {
        const formattedTime = inputDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        return `${days} ${days === 1 ? 'day' : 'days'} ago, ${formattedTime}`;
      }
  
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30.44); // Average days in a month
      const years = Math.floor(days / 365);
  
      if (years > 0) {
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
      } else if (months > 0) {
        return `${months} ${months === 1 ? 'month' : 'months'} ago`;
      } else if (weeks > 0) {
        return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
      } else if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? 'min' : 'mins'} ago`;
      } else {
        return `${seconds} ${seconds === 1 ? 'sec' : 'secs'} ago`;
      }
    } catch (error) {
      return null; // Handle any errors
    }
  };

  /**
 * To check if time is n hours old
 */

export function isDateTimeNHoursOld(dateTime, n) {
    if (dateTime) {
      const twelveHoursAgo = new Date().getTime() - n * 60 * 60 * 1000; // n hours ago in milliseconds
      const dateTimeMillis = new Date(dateTime).getTime(); // given dateTime in milliseconds
  
      return dateTimeMillis < twelveHoursAgo;
    }
    return true;
  }
  /**
 * Check for Crmi call event if needed
 */

export const checkForCrmiKeyInAuth = async () => {
  console.log("checkForCrmiKeyInAuth called");
  try {
    let shouldHitCrm = localStorage.getItem("shouldHitCrm");
    shouldHitCrm = shouldHitCrm ? JSON.parse(shouldHitCrm) : false;
    //  isCeoOrManager = true;   /// remove this 
    // const userAuthData = await getAuthDataFromLocal(true);
    // console.log("CheckForActivationKeyInAuth userAuthData", userAuthData);
    const userWorkspaceId =  getLoggedInUserWorkspaceId();
    // const isActivationDone = userAuthData?.activation;
    const URL = `https://eazybe.com/updatecrmikey?workspaceId=${userWorkspaceId}&crmi=1`;
    const urlToOpen = `https://eazybe.com/crmi?workspaceId=${userWorkspaceId}`;
    // if (!isActivationDone && isCeoOrManager) {
    // if (shouldHitCrm) {

    window.open(urlToOpen, '_blank');
      
      localStorage.setItem("shouldHitCrm", false);
     

      // setTimeout(() => {
      //   sendMessageToBackgroundFromComponent(BACKGROUNDTYPES.GET_USER_DATA);
      // }, 5000);
    // }
  } catch (err) {
    console.log("Error while checking for crmi key", err);
  }
};


export function getFormattedDate(dateString) {
  try{
    if (!dateString) {
      return null; // Handle undefined input
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const currentDate = new Date(dateString);
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  let hours = currentDate.getHours();
  const amPM = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  const minutes = currentDate.getMinutes();

  const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes.toString().padStart(2, '0')} ${amPM}`;
  return formattedDate;
  }
  catch (err) {
    return null;
  }
}

 /**
* @function getDayDifference
* @description Calculates the difference in days between two dates.
* If there's any time left on the current day, it returns 1.
* If the end date has passed the start date, it returns negative days.
* @param {Date|string} startDate - The starting date. Can be a Date object or a date string.
* @param {Date|string} endDate - The ending date. Can be a Date object or a date string.
* @returns {number} The number of days between the two dates. 
*                   Positive if endDate is in the future, 
*                   1 if there's any time left on the current day,
*                   Negative if endDate has passed startDate.
*/
export function getDayDifference(startDate, endDate) {
 // Parse dates if they are strings
 const start = startDate instanceof Date ? startDate : new Date(startDate);
 const end = endDate instanceof Date ? endDate : new Date(endDate);

 // Set both dates to the start of their respective days in UTC
 const startUtc = Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
 const endUtc = Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());

 // Calculate the difference in days
 const millisecondsPerDay = 24 * 60 * 60 * 1000;
 let daysDifference = Math.floor((endUtc - startUtc) / millisecondsPerDay);

 // Check if there's any time left on the current day
 if (daysDifference === 0 && end > start) {
   return 1;
 }

 // If end date has passed start date, but not a full day has passed
 if (daysDifference === 0 && end < start) {
   return -1;
 }

 return daysDifference;
}